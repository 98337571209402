import {useFormik} from "formik";
import {Chama} from "../db/models";
import {
    AppBar, Box, Button, ButtonProps, DialogActions, DialogContent, IconButton, TextField, Toolbar
} from "@mui/material";
import React, {useState} from "react";
import * as yup from "yup";
import {addChama} from "../db/service";
import {AppDialog} from "../budget/AppDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";


const validationSchema = yup.object({
    name: yup.string().required("Name is required")
});

const Root = styled("div")(({theme}) => ({
    padding: theme.spacing(1)
}));

export function AddChama(props: {
    onClose?: (dateSaved: boolean) => void,
    chamaCode?: string,
    title: string,
    ButtonProps?: ButtonProps
}) {
    const initdata: Chama = {name: "", code: ""};
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const doClose = (dataSaved?: boolean) => {
        setOpenDialog(false);
        if (props.onClose) {
            props.onClose(dataSaved || false);
        }
    };
    const onOpen = () => {
        setOpenDialog(true);
    };
    const formik = useFormik({
        initialValues: initdata,
        validationSchema: validationSchema,
        onSubmit: values => {
            addChama(values).then(res => {
                formik.resetForm();
                addChama(res).then(rs => {
                    doClose(true);
                });
            });
        }
    });
    return (
        <Root>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={0.5}>
                <Button startIcon={<AddCircleIcon/>} size={"small"} onClick={onOpen} {...props.ButtonProps}>
                    {props.title}
                </Button>
            </Stack>
            <AppDialog open={openDialog} onClose={() => doClose(false)}>
                <AppBar sx={{position: "relative"}}>
                    <Toolbar>
                        {props.chamaCode ? "Edit" : "Add"} Chama
                        <Box sx={{flexGrow: 1}}/>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => doClose(false)}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent dividers>
                        <TextField variant={"outlined"}
                                   autoFocus
                                   style={{flexGrow: 1}}
                                   margin="normal"
                                   id="name"
                                   name="name" fullWidth
                                   label="Chama name"
                                   type="text"
                                   value={formik.values.name}
                                   onChange={formik.handleChange}
                                   error={formik.touched.name && Boolean(formik.errors.name)}
                                   helperText={formik.touched.name && formik.errors.name}/>
                    </DialogContent>

                    <DialogActions>
                        <Button variant={"outlined"} onClick={() => doClose(false)} color={"secondary"}>
                            Close
                        </Button>
                        < Button type="submit" color="primary" variant={"outlined"}>
                            {initdata.id ? "Update" : "Save"}
                        </Button>
                    </DialogActions>
                </form>
            </AppDialog>
        </Root>);
}