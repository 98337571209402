import {styled} from "@mui/material/styles";
import {Radio} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {TabPanel} from "@mui/lab";

const StyledCheckbox = styled(Checkbox)(({theme}) => ({
    padding: "inherit"
}));
const StyledRadiobox = styled(Radio)(({theme}) => ({
    padding: "inherit"
}));
const AppTabPanel = styled(TabPanel)(({theme}) => ({
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    display: "flex",
    flexDirection: "column"
}));

export {StyledCheckbox, StyledRadiobox, AppTabPanel};