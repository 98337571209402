import {Card, CardContent, Divider, Grid, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ALL, CategoryStats} from "../../db/models";
import {BudgetItemFilter} from "../../state/slices/budgetItemSlice";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {setLoading} from "../../state/slices/sharedSlice";
import {getBudgetItemCategories} from "../../db/service";
import log from "loglevel";
import {fromUnixTime} from "date-fns";
import MatLinker from "../../components/MatLinker";
import {ROUTES} from "../../ROUTES";
import {PaymentStatusChip} from "../PaymentStatusChip";
import capitalize from "@mui/material/utils/capitalize";
import {SyncPending} from "../../components/SyncPending";
import {currency} from "../../Helpers";
import NotFound from "../../components/NotFound";

export function ExpenseCategories(props: { loadData?: boolean, onLoadingDone?: () => void }) {
    const theme = useTheme();
    const {loadData} = props;
    const [categories, setCategories] = useState<CategoryStats[]>([]);
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const dispatch = useAppDispatch();


    function loadDataCats() {

        dispatch(setLoading(true));
        getBudgetItemCategories(user.email, filter).then(recs => {
            setCategories(recs);
            dispatch(setLoading(false));
            if (props?.onLoadingDone) {
                props.onLoadingDone();
            }
        });
    }

    useEffect(() => {
        loadDataCats();
        const {startDate, endDate, ...rest} = filter;
        log.info(`Filtering cats for dates [${fromUnixTime(startDate)} to ${fromUnixTime(endDate)}] and `, rest);
    }, [filter]);
    useEffect(() => {
        loadDataCats();
        const {startDate, endDate, ...rest} = filter;
        log.info(`Filtering cats for dates [${fromUnixTime(startDate)} to ${fromUnixTime(endDate)}] and `, rest);
    }, [loadData]);


    return <Card>
        <CardContent>
            {categories.length > 0 ?
                categories.map((cat, itemsIdx) =>
                    <div key={itemsIdx}>
                        <Grid container gap={1}>
                            <Grid item flexGrow={1}>
                                <MatLinker to={ROUTES.CATEGORY_BUDGET_ITEMS.replace(":name", cat.name)}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <PaymentStatusChip data={cat}
                                                           getStatus={(data: CategoryStats) => data.paymentStatusCode}/>
                                        <Typography variant="body2" display={"block"}>
                                            {capitalize(cat.name)}
                                        </Typography>
                                    </div>
                                    <Typography variant="caption">
                                        Items {cat.itemCount}
                                    </Typography>
                                    <SyncPending synced={cat.itemsPendingSync > 0}/>
                                </MatLinker>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant={"caption"} display={"block"}>
                                    Amount: {currency(cat.amount, false)}
                                </Typography>
                                <Typography variant={"caption"} display={"block"}>
                                    Paid: {currency(cat.paid, false)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {categories.length > (itemsIdx + 1) && (<Divider/>)}
                    </div>
                ) :
                <NotFound style={{padding: theme.spacing(4)}}
                          message={`No ${capitalize(filter.budgetCode === ALL ? "" : filter.budgetCode)} ${capitalize(
                              filter.budgetItemType)} Data`}/>
            }
        </CardContent>
    </Card>;
}