import {BudgetItem, BudgetItemPayment, SYNC_PENDING_STATUS} from "../../db/models";
import {deleteBudgetItemPayment, fetchBudgetItemPayments, postBudgetItem} from "../../db/service";
import {currency, DISPLAY_DATE_FORMAT} from "../../Helpers";
import {format, fromUnixTime} from "date-fns";
import capitalize from "@mui/material/utils/capitalize";
import {AppTable} from "../AppTable";
import {Grid, Typography} from "@mui/material";
import {AssociateWith} from "../../associations/AssociateWith";
import {DeleteButton} from "../../components/DeleteButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import PaymentSourceUsageDialog from "./PaymentSourceUsageDialog";

export function PaymentsTable(props: {
    budgetItem: BudgetItem,
    reload?: boolean,
    onClose?: (dataSaved: boolean) => void
}) {
    const {onClose, budgetItem} = props;

    const onDelete = () => {
        budgetItem.isSynced = SYNC_PENDING_STATUS;
        postBudgetItem(budgetItem)
            .then(it => {
                if (onClose) {
                    onClose(true);
                }
            });
    };

    const sourceName = (it?: BudgetItem) => {
        if (!it) {
            return "";
        }
        const amt = currency(it.amount, false);
        return `[${it.itemTypeCode}]${capitalize(it.name)}-(${amt})`;
    };
    return <>
        <AppTable PaperProps={{sx: {m: 0}}} reload={props.reload}
                  dataRow={(it: BudgetItemPayment, index: number) => {
                      return <Grid container>
                          <Grid item xs={4}>
                              {index === 0 && <Typography variant={"caption"} display={"block"}>Paid on</Typography>}
                              <Typography variant={"caption"}>
                                  {format(fromUnixTime(it.datePaid), DISPLAY_DATE_FORMAT)}
                              </Typography>
                          </Grid>
                          <Grid item xs={4}>
                              {index === 0 && <Typography variant={"caption"} display={"block"}>Amount</Typography>}
                              <Typography variant={"caption"}>{currency(it.amount, false)}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                              {index === 0 && <Typography variant={"caption"} display={"block"}>Source</Typography>}
                              {/*<Typography variant={"caption"}>{sourceName(it.paymentSource)}</Typography>*/}
                              {it.paymentSourceCode && (<PaymentSourceUsageDialog
                                  ButtonProps={{
                                      variant: "text",
                                      sx: {
                                          display: "contents",
                                          fontWeight: "normal"
                                      },
                                      size: "small"
                                  }}
                                  budgetItem={budgetItem}
                                  title={sourceName(it.paymentSource)}/>)}
                          </Grid>
                      </Grid>;
                  }
                  }
                  tableHeader={<Typography variant={"h6"}>Payments</Typography>}
                  dataLoader={() => fetchBudgetItemPayments(props.budgetItem.code)}
                  getValue={it => `${it.id}`}
                  rowOptions={(item: BudgetItemPayment) => [
                      <AssociateWith onClose={onClose} payment={item} budgetItem={budgetItem}/>,
                      <DeleteButton ButtonProps={{
                          size: "small",
                          variant: "text"
                      }}
                                    deleteCallback={(e) => deleteBudgetItemPayment(item)
                                        .then(rs => {
                                            onDelete();
                                        })}>
                          <DeleteIcon fontSize="small"/> Delete
                      </DeleteButton>
                  ]}/>
    </>;
}