export class ROUTES {
    static BUDGET_ITEMs = `/budgetitems`;
    static CATEGORY_BUDGET_ITEMS = `${ROUTES.BUDGET_ITEMs}/category/:name`;
    static EDIT_BUDGET_ITEM = `${ROUTES.BUDGET_ITEMs}/:id`;
    static ACCOUNT = `/myaccount`;
    static USERS = `/myconnections`;
    static BUDGETs = `/mybudgets`;
    static CHAMA = `/chamas`;
    static LOGIN = `/login`;
}
