import {BudgetItem} from "../../db/models";
import React, {ReactNode} from "react";
import InfiniteLoader from "react-window-infinite-loader";
import {FixedSizeList, ListChildComponentProps} from "react-window";

type BudgetItemsInfiniteLoaderProps = {
    dataList: BudgetItem[],
    hasMoreItems: boolean,
    children: (budgetItem: BudgetItem, index: number) => ReactNode,
    loadMoreItems: (start: number, end: number) => Promise<void>
};

export function BudgetItemsInfiniteLoader(props: BudgetItemsInfiniteLoaderProps) {
    const {dataList, loadMoreItems, hasMoreItems, children} = props;
    const isItemLoaded = (index: number) => dataList.length > index;
    const itemCount = hasMoreItems ? dataList.length + 1 : dataList.length;

    return <>
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={!hasMoreItems ? () => {
            } : loadMoreItems}>
            {({onItemsRendered, ref}) => (
                <FixedSizeList
                    height={Math.min(560, dataList.length * 80)}
                    itemCount={itemCount}
                    itemSize={80}
                    overscanCount={0}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    width={"100%"}>

                    {(props: ListChildComponentProps) => {
                        const budgetItem = dataList[props.index];
                        const style = {...props.style};
                        if (!budgetItem) {
                            return <></>;
                        }

                        return <div style={style}>
                            {children(budgetItem, props.index)}
                        </div>;
                    }}
                </FixedSizeList>
            )}
        </InfiniteLoader>

    </>
}