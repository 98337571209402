import React, { useState } from "react";
import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { TabContext } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { BudgetItemFilter, doBudgetItemFilter } from "../state/slices/budgetItemSlice";
import { BasicStats } from "./basicstats/BasicStats";
import { ExpenseCategories } from "./item/ExpenseCategories";
import { TabContextToolbar } from "./TabContextToolbar";
import { AppTabPanel } from "./StyledComponents";
import { BudgetItemsList } from "./item/BudgetItemsList";
import { ALL } from "../db/models";


/**
 * Display content in tab
 * @param props
 * @constructor
 */
export function TabbedDisplay(props: {}) {
    const [loadData, setLoadingDone] = useState<boolean>(false);
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [viewType, setViewType] = useState<string>("list")


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        dispatch(doBudgetItemFilter({ ...filter, budgetItemType: newValue }));
    };

    function a11yProps(index: number) {
        return {
            id: `tab-${index}`,
            "aria-controls": `tabpanel-${index}`
        };
    }

    let index = 0;

    function getKey(title: string) {
        return title.toLowerCase().replace(" ", "_");
    }

    const onLoadingDone = () => {
        setLoadingDone(false);

    };
    const doClose = (saved: boolean) => {
        setLoadingDone(saved);
    };

    const onViewTypeChange = (newAlignment: string) => {
        setViewType(newAlignment);
    }

    return (
        <>
            <BasicStats />
            {filter.user !== ALL && (
                <Typography variant={"body2"}>
                    Results for Payer: {filter.user}
                </Typography>)}
            <TabContext value={filter.budgetItemType}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <AppBar position="static" color={"transparent"}>
                        <Tabs value={filter.budgetItemType} onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth">
                            {/*{tabHeader.get(getKey("expense"))}*/}
                            {/*{tabHeader.get(getKey("debt"))}*/}
                            {/*{tabHeader.get(getKey("lending"))}*/}
                            <Tab label={"Expense"} value={`expense`} {...a11yProps(index)} />
                            <Tab label={"Loan"} value={`debt`} {...a11yProps(index)} />
                            <Tab label={"Lending"} value={`lending`} {...a11yProps(index)} />
                            <Tab label={"Funds"} value={`funds`} {...a11yProps(index)} />
                        </Tabs>
                    </AppBar>
                </Box>

                <TabContextToolbar onClose={doClose} onViewTypeChange={onViewTypeChange} />

                <AppTabPanel value={getKey("expense")} dir={theme.direction}>
                    {"category" === viewType && <ExpenseCategories loadData={loadData} onLoadingDone={onLoadingDone} />}
                    {"list" === viewType && <BudgetItemsList loadData={loadData} onLoadingDone={onLoadingDone} />}
                </AppTabPanel>
                <AppTabPanel
                    value={getKey("debt")} dir={theme.direction}>
                    <BudgetItemsList showCarriedForward loadData={loadData} onLoadingDone={onLoadingDone} />
                </AppTabPanel>
                <AppTabPanel
                    value={getKey("lending")} dir={theme.direction}>
                    <BudgetItemsList showCarriedForward loadData={loadData} onLoadingDone={onLoadingDone} />
                </AppTabPanel>
                <AppTabPanel
                    value={getKey("funds")} dir={theme.direction}>
                    <BudgetItemsList showCarriedForward loadData={loadData} onLoadingDone={onLoadingDone} />
                </AppTabPanel>
            </TabContext>
        </>
    );
}