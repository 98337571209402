import {styled} from "@mui/material/styles";
import {Button, ButtonProps, Dialog, DialogProps, Paper} from "@mui/material";
import React, {ReactNode, useState} from "react";

const PaperDialog = styled(Paper)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        width: "100vh!important",
        maxWidth: "calc(100%)!important",
        maxHeight: "98vh",
        margin: theme.spacing(0),
        verticalAlign: "top"
    }
}));
const StyledDialog = styled(Dialog)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        margin: 0,
        padding: 0
    }
}));

export function AppDialog(props: DialogProps) {
    return <StyledDialog scroll={"body"} PaperComponent={PaperDialog} {...props}/>;
}


interface LazyLaunchDialogProps {
    dialogProps?: DialogProps;
    buttonProps?: ButtonProps,
    buttonTitle: string,
    content: (closable: ClosableProps) => ReactNode
}

export interface ClosableProps {
    onClose: () => void;
}

export function LazyLaunchDialog(props: LazyLaunchDialogProps) {
    const [open, setOpen] = useState<boolean>(false);
    const onClose = () => {
        setOpen(false);
    };
    return <>
        <Button size={"small"} onClick={() => setOpen(true)} {...props.buttonProps}>
            {props.buttonTitle}
        </Button>

        <AppDialog {...props.dialogProps} onClose={() => () => setOpen(false)} open={open}>
            {props.content({onClose})}
        </AppDialog>
    </>;
}