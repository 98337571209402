import React from "react";
import {Switch} from "react-router-dom";
import MonthlyData from "./MonthlyData";
import {ROUTES} from "../ROUTES";
import {BudgetItemsPage} from "./item/BudgetItemsPage";
import {PrivateRoute} from "../components/PrivateRoute";

export default function BudgetLandingPage() {
    return (
        <Switch>
            <PrivateRoute path={ROUTES.CATEGORY_BUDGET_ITEMS}>
                <BudgetItemsPage/>
            </PrivateRoute>
            <PrivateRoute path={ROUTES.BUDGET_ITEMs}>
                <MonthlyData/>
            </PrivateRoute>
        </Switch>
    );
}
