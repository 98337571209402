import {BudgetItem} from "../../db/models";
import React, {ChangeEvent, ReactNode} from "react";
import {Box, Chip, Divider, Grid, Typography} from "@mui/material";
import {StyledCheckbox} from "../StyledComponents";
import {format, fromUnixTime} from "date-fns";
import {currency, DISPLAY_DATE_FORMAT} from "../../Helpers";
import {SyncPending} from "../../components/SyncPending";
import capitalize from "@mui/material/utils/capitalize";
import {PaymentStatusChip} from "../PaymentStatusChip";
import {sumBudgetItemPaidAmount} from "../../db/service";

export function BudgetItemRow(props: {
    budgetItem: BudgetItem, position: number, total: number,
    onItemChecked?: (item: BudgetItem, isChecked: boolean) => void,
    actions?: (item: BudgetItem) => ReactNode,
    isSelected?: boolean,
    selectable?: boolean,
    multiSelectable?: boolean,
    categoryCode?: string
}) {
    const {
        selectable,
        isSelected,
        budgetItem,
        position,
        total,
        onItemChecked,
        actions
    } = props;
    const [checked, setChecked] = React.useState(isSelected || false);
    const [openPay, setOpenPay] = React.useState(false);
    const openItemClicked = (item: BudgetItem) => {
        if (onItemChecked) {
            onItemChecked(item, false);
        }
    };
    const onItemSelected = (event: ChangeEvent<HTMLInputElement>) => {
        if (onItemChecked) {
            onItemChecked(budgetItem, event.target.checked);
        }

        setChecked(event.target.checked);
    };
    if (!budgetItem) {
        return <Grid container>
            <Grid item xs={12} gap={1} rowGap={1}>
                <Typography variant={"body2"}>
                    loading ...
                </Typography>
            </Grid>
        </Grid>;
    }
    if (budgetItem.itemTypeCode === "header") {
        return <Chip label={budgetItem.name} variant={"outlined"} color="secondary"/>;
    }

    return <>
        <Box sx={{display: "grid", gridTemplateColumns: "1fr 1fr auto"}}>
            <Box sx={{cursor: "pointer"}} onClick={(e: any) => setOpenPay(true)}>
                {selectable && (<StyledCheckbox size={"small"} checked={checked} onChange={onItemSelected}/>)}
                <small>
                    <Typography variant={"caption"}>
                        {format(fromUnixTime(budgetItem.date), DISPLAY_DATE_FORMAT)}
                        <SyncPending synced={!budgetItem.isSynced}/>
                    </Typography>
                </small>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Typography variant={"subtitle2"} display={"block"}>
                        {capitalize(budgetItem.name)}
                        <PaymentStatusChip ChipProps={{sx: {height: "12px", width: "12px"}}}
                                           data={budgetItem}
                                           getStatus={(data: BudgetItem) => data.paymentStatusCode}/>
                    </Typography>
                    
                    <Typography variant="caption" display={"block"}>{budgetItem.tag}</Typography>
                </div>
            </Box>
            <Box display={"flex"} flexDirection={"column"}
                 onClick={(e: any) => setOpenPay(true)} sx={{cursor: "pointer"}}>
                <Typography variant="caption">
                    Amount: <span>{currency(budgetItem.amount, false)}</span>
                </Typography>
                <Typography variant="caption">
                    Paid: <span>{currency(sumBudgetItemPaidAmount(budgetItem), false)}</span>
                </Typography>
                <Typography variant="caption">
                    Debt: <span>{currency(budgetItem.amount - sumBudgetItemPaidAmount(budgetItem), false)}</span>
                </Typography>
            </Box>
            {actions && (<Box>
                {actions(budgetItem)}
            </Box>)}
        </Box>
        {position < (total + 1) && (<Divider/>)}
    </>;
}