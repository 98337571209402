import {BudgetItem, SYNC_PENDING_STATUS, User} from "../../db/models";
import {useAppSelector} from "../../state/hooks";
import React, {useEffect, useState} from "react";
import log from "loglevel";
import {postBudgetItem, sumBudgetItemPaidAmount} from "../../db/service";
import {
    AppBar, Box, Button, DialogActions, DialogContent, Grid, IconButton, Paper, TextField, Toolbar, Typography, useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {getUnixTime} from "date-fns";
import capitalize from "@mui/material/utils/capitalize";
import {currency} from "../../Helpers";
import DatePicker from "@mui/lab/DatePicker";

function EditableBudgetItem(props: {
    onDelete?: (item: BudgetItem) => void,
    data: BudgetItem,
    onUpdate?: (data: BudgetItem) => void
}) {
    const {onUpdate, data} = props;
    const [datePeriod, setDatePeriod] = useState<Date>(data.dateObj || new Date());
    const theme = useTheme();
    const onChange = (data: BudgetItem) => {
        if (onUpdate) {
            onUpdate(data);
        }
    };
    useEffect(() => {
        const updated = {...data};
        updated.dateObj = datePeriod;
        updated.date = getUnixTime(datePeriod);
        onChange(updated);
    }, [datePeriod]);

    return <Paper elevation={3}>
        <Grid container padding={theme.spacing(1)} margin={theme.spacing(1)}>
            <Grid item>
                <Typography variant={"subtitle2"} display={"block"}>{capitalize(data.name)}</Typography>
            </Grid>
            <Grid container spacing={1} sx={{cursor: "pointer"}}>
                <Grid item xs={4}>
                    <Typography variant="caption">
                        Amount: <span>{currency(data.amount, false)}</span>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="caption">
                        Paid: <span>{currency(sumBudgetItemPaidAmount(data), false)}</span>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="caption">
                        Debt: <span>{currency(data.amount - sumBudgetItemPaidAmount(data), false)}</span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <DatePicker value={datePeriod}
                            label={"Budget Month"}
                            inputFormat="dd/MMM/yyyy"
                            renderInput={(params) => (<TextField size={"small"} margin="dense" {...params} />)}
                            onChange={e => {
                                setDatePeriod(e as Date);
                            }}/>
            </Grid>
        </Grid>
    </Paper>;
}

export function CopyBudgetItemsToBudget(props: { data: BudgetItem[], onDone?: (saved: boolean) => void }) {
    let loggedInUser: User = useAppSelector(state => state.sharedSliceReducer.user);

    // const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [budgets, setBudgets] = useState<BudgetItem[]>([]);

    useEffect(() => {
        const copied = props.data.map(item => {
            const it = {...item};
            it.id = undefined;
            it.code = "";
            it.serverId = undefined;
            it.updatedOn = "";
            it.createdOn = "";
            it.assignedTo = loggedInUser.email;
            it.dateObj = new Date();
            it.payments = [];
            return it;
        });

        setBudgets(copied);
    }, []);

    const onDelete = (item: BudgetItem) => {
        const idx = budgets.findIndex(it => it.code === item.code);
        budgets.splice(idx, 1);
        setBudgets(budgets);
    };

    const onUpdate = (item: BudgetItem) => {
        const idx = budgets.findIndex(it => it.name === item.name);
        budgets.splice(idx, 1, item);
        log.info("Content to be copied >>>", item);
        log.info("Content to be copied ", budgets);
        setBudgets([...budgets]);
    };
    const doClose = (saved: boolean) => {
        if (props.onDone) {
            props.onDone(saved);
        }
    };
    const doSave = () => {
        // working with one budget for will change later
        const records = budgets.map(item => {
            item.isSynced = SYNC_PENDING_STATUS;
            return postBudgetItem(item);
        });
        Promise.all(records).then(update => {
            doClose(true);
        });
    };
    return <>
        <AppBar sx={{position: "relative"}}>
            <Toolbar>
                Copying [ {props.data.length} ] item(s)
                <Box sx={{flexGrow: 1}}/>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => doClose(false)}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent dividers style={{margin: 0, padding: 4}}>

            {budgets.map((item: BudgetItem, idx: number) => {
                return <div key={idx}>
                    <EditableBudgetItem data={item} onDelete={onDelete} onUpdate={onUpdate}/>
                </div>;
            })}
        </DialogContent>
        <DialogActions>
            <Button onClick={(e: any) => doClose(false)} color="secondary">
                Close
            </Button>
            <Button type="submit" color="primary" variant={"outlined"} onClick={(e: any) => doSave()}>
                Save
            </Button>
        </DialogActions>
    </>;
}