import React from "react";
import {TabbedDisplay} from "./TabbedDisplay";
import {PeriodFilter} from "./PeriodFilter";

/**
 * Display tab items
 * @constructor
 */
export default function MonthlyData() {

    return (
        <>
            <PeriodFilter showChart/>
            <TabbedDisplay/>
        </>
    );
}