import React, {useEffect} from "react";
import "./App.css";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {Login} from "./components/Login";
import {PrivateRoute} from "./components/PrivateRoute";
import {TopBar} from "./components/TopBar";
import {Box, Button, Grid, IconButton, LinearProgress, Snackbar} from "@mui/material";
import {useAppDispatch, useAppSelector} from "./state/hooks";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {serviceWorkerInstall, serviceWorkerUpdate} from "./state/slices/sharedSlice";
import {UserHome} from "./user/UserHome";
import CloseIcon from "@mui/icons-material/Close";
import log from "loglevel";
import {ChamasPage} from "./chama/ChamasPage";
import {ROUTES} from "./ROUTES";
import {styled} from "@mui/material/styles";
import BudgetLandingPage from "./budget/BudgetLandingPage";
import AccountPage from "./account/AccountPage";
import {BudgetPage} from "./budget/BudgetPage";
import {GoogleOAuthProvider} from "@react-oauth/google";

const Root = styled("div")(({theme}) => ({
    display: "flex",
    // justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
        justifyContent: "center"
    }
}));


function App() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.sharedSliceReducer.loading);
    // const addBudgetItemDialogOpen = useAppSelector(state => state.budgetItemSliceReducer.openDialog);
    const isServiceWorkerUpdated = useAppSelector(state => state.sharedSliceReducer.serviceWorkerUpdated);
    const serviceUpdateRegistration: ServiceWorkerRegistration | null = useAppSelector(
        state => state.sharedSliceReducer.serviceWorkerData);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    // log.setLevel( log.levels.TRACE);
    log.info(`Environment [ ${process.env.NODE_ENV} ] with Logging Level [ ${log.getLevel()} ]`);
    log.info("Welcome to Budget Tracker");


    useEffect(() => {
        log.debug("******************isServiceWorkerUpdated*****************", isServiceWorkerUpdated);
        setOpenSnackbar(isServiceWorkerUpdated);
        log.debug("*******************isSericeWorkerUpdated****************");
    }, [isServiceWorkerUpdated]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        window.location.reload();
    };
    const updateServiceWorker = () => {
        const registrationWaiting = serviceUpdateRegistration?.waiting;
        log.info("*********************************** Service worker waiting " + registrationWaiting);
        if (registrationWaiting) {
            registrationWaiting.postMessage({type: "SKIP_WAITING"});
            registrationWaiting.addEventListener("statechange", (e: any) => {
                log.info("*********************************** Service worker statechange event", e);
                if (e?.target?.state === "activated") {
                    handleCloseSnackbar();
                    // window.location.reload();
                }
            });
        }
    };
    // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
        onSuccess: (registration: ServiceWorkerRegistration) => {
            dispatch(serviceWorkerInstall(registration));
        },
        onUpdate: (registration: ServiceWorkerRegistration) => {
            log.debug("**************** UPDATING ****************");
            dispatch(serviceWorkerUpdate(registration));
        }
    });

    const snackbarAction = (
        <>
            <Button color="secondary" size="small" variant={"outlined"} onClick={updateServiceWorker}>
                Update
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small"/>
            </IconButton>
        </>
    );

    return (
        <Root>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_KEY ?? ""}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Router basename={process.env.PUBLIC_URL}>
                        <Grid container sm={8} md={6} lg={4} xs={12} item={true}>
                            <TopBar/>
                            <Box component="main" sx={{flexGrow: 1}}>
                                {loading && <LinearProgress color="secondary"/>}
                                <Switch>
                                    <PrivateRoute path={ROUTES.BUDGETs}>
                                        <BudgetPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path={ROUTES.CHAMA}>
                                        <ChamasPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path={ROUTES.USERS}>
                                        <UserHome/>
                                    </PrivateRoute>
                                    <PrivateRoute path={ROUTES.ACCOUNT}>
                                        <AccountPage/>
                                    </PrivateRoute>
                                    <Route path={ROUTES.BUDGET_ITEMs}>
                                        <BudgetLandingPage/>
                                    </Route>
                                    <Route path="/login">
                                        <Login/>
                                    </Route>
                                    {/* Not Found */}
                                    <Route exact path="/">
                                        <Redirect to={ROUTES.BUDGET_ITEMs}/>
                                    </Route>
                                </Switch>

                                {/*{addBudgetItemDialogOpen ? <AddBudgetItem/> : <></>}*/}

                                <Snackbar
                                    open={openSnackbar}
                                    onClose={handleCloseSnackbar}
                                    message="A new app version is available"
                                    action={snackbarAction}/>
                            </Box>
                        </Grid>
                    </Router>
                </LocalizationProvider>
            </GoogleOAuthProvider>
        </Root>
    );
}

export default App;
