import {useEffect, useState} from "react";
import {SyncTracker} from "../db/models";
import {Grid, Paper, Typography} from "@mui/material";
import {format, fromUnixTime, parseISO} from "date-fns";
import {getTrackerInfo} from "../db/service";
import NotFound from "../components/NotFound";
import {DISPLAY_DATE_TIME_FORMAT} from "../Helpers";
import {AppTable} from "../budget/AppTable";

const LastUpdateInfo = () => {
    const [updateInfo, setUpdateInfo] = useState<SyncTracker[]>();
    useEffect(() => {
        getTrackerInfo()
            .then(data => {
                setUpdateInfo(data);
            });
    }, []);
    return <Paper>
        {!updateInfo && (<NotFound>No Sync status found</NotFound>)}
        {updateInfo && (<>
                <Typography variant={"subtitle1"}>Data synching information</Typography>
                <AppTable dataLoader={getTrackerInfo} getValue={(it) => it.recordType} hideOptions
                          tableHeader={<Grid container>
                              <Grid item flex={6}>
                                  <Typography variant={"subtitle2"}> Data item</Typography>
                              </Grid>
                              <Grid item flex={6}>
                                  <Typography variant={"subtitle2"}> Sync date</Typography>
                              </Grid>
                          </Grid>}
                          dataRow={(tracked, idx) => <Grid container key={idx}>
                              <Grid item flex={6}>
                                  <Typography variant={"body2"}>
                                      {tracked.recordType}
                                  </Typography>
                              </Grid>
                              <Grid item flex={6}>
                                  <Typography variant={"body2"}>
                                      {format(parseISO(tracked.lastSyncTime), DISPLAY_DATE_TIME_FORMAT)}
                                  </Typography>
                              </Grid>
                          </Grid>
                          }/>
            </>
        )}
    </Paper>;
};

const AccountPage = () => {
    return <>
        <LastUpdateInfo/>
    </>;
};

export default AccountPage;