import Dexie from "dexie";
import {
    Budget, BudgetItem, BudgetItemPayment, BudgetItemType, BudgetMember, Category, Chama, ChamaContribution, DebtItem,
    PaymentStatus, RequestLog, SyncTracker, User, UserConnection
} from "./models";

// const db = new Dexie('homerecord');
// db.version(1).stores({
//     budget: `id`,
//     budget_item: `id, budget_id`,
//     user: `id`,
//     budget_member: `id,budget_id,user_id`
// });


class HomeRecord extends Dexie {
    public budget: Dexie.Table<Budget, number>;
    public budgetItem: Dexie.Table<BudgetItem, number>;
    public budgetItemPayment: Dexie.Table<BudgetItemPayment, number>;
    public user: Dexie.Table<User, number>;
    // public budgetMember: Dexie.Table<BudgetMember, number>;
    public budgetItemType: Dexie.Table<BudgetItemType, number>;
    public paymentStatus: Dexie.Table<PaymentStatus, number>;
    public debtItem: Dexie.Table<DebtItem, number>;
    public syncTracker: Dexie.Table<SyncTracker, number>;
    public category: Dexie.Table<Category, number>;
    public userConnection: Dexie.Table<UserConnection, number>;
    public chama: Dexie.Table<Chama, number>;
    public chamaContribution: Dexie.Table<ChamaContribution, number>;
    public requestLog: Dexie.Table<RequestLog, number>;

    public constructor() {
        super("homefinance");
        this.version(39)
            .stores({
                budget: "++id,code,synced,ownerEmail",
                budget_item: "++id,date,itemTypeCode,synced,isSynced,serverId,code,assignedTo,budgetCode,deleted",
                budget_member: "++id,budgetCode,userEmail,synced",
                user: "++id,email,is_logged_in",
                sync_tracker: null,
                last_sync_tracker: "++id,recordType,lastSyncTime",
                budget_item_type: "++id,name,code",
                category: "++id,code,serverId",
                payment_status: "++id,code",
                chama: "++id",
                request_log: "++id",
                chama_contribution: "++id,chamaId,date,budgetItemId",
                debt_item: "++id,budgetItemId,debtId,budgetItemCode,debtCode",
                budget_item_payment: "++id,budgetItemCode,isSynced,amount,datePaid,paymentSourceCode",
                user_connection: "++id,mainUserEmail,userEmail,categoryCode"
            });
        this.budget = this.table("budget");
        this.budgetItem = this.table("budget_item");
        // this.budgetMember = this.table("budget_member");
        this.user = this.table("user");
        this.budgetItemType = this.table("budget_item_type");
        this.paymentStatus = this.table("payment_status");
        this.debtItem = this.table("debt_item");
        this.syncTracker = this.table("last_sync_tracker");
        this.category = this.table("category");
        this.chama = this.table("chama");
        this.chamaContribution = this.table("chama_contribution");
        this.userConnection = this.table("user_connection");
        this.requestLog = this.table("request_log");
        this.budgetItemPayment = this.table("budget_item_payment");


        this.budgetItemType.bulkPut([
            {
                id: 1,
                name: "Expense",
                code: "expense"
            },
            {
                id: 2,
                name: "Loan",
                code: "debt"
            },
            {
                id: 3,
                name: "Lending",
                code: "lending"
            },
            {
                id: 4,
                name: "Funds",
                code: "funds"
            }
        ]);
        this.paymentStatus.bulkPut([
            {
                id: 1,
                name: "Paid",
                code: "paid"
            },
            {
                id: 2,
                name: "Partial",
                code: "partial"
            },
            {
                id: 3,
                name: "Pending",
                code: "pending"
            }
        ]);
    }
}

const database = new HomeRecord();

export default database;