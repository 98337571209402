import {
    ALL, BasicStatistics, Budget, BudgetItem, BudgetItemPayment, BudgetItemType, BUDGETYPE, Category, CategoryStats,
    Chama, ChamaContribution, DebtItem, IS_DELETED, RequestLog, SYNC_PENDING_STATUS, SyncTracker, Trackable,
    UNCATEGORIZED, User, YearData
} from "./models";
import {
    addMinutes,
    endOfMonth, format, formatISO, formatRFC3339, fromUnixTime, getYear, isAfter, isBefore, subMinutes, subMonths
} from "date-fns";
import database from "./database";
import log from "loglevel";
import { Httpclient } from "../api/httpclient";
import { BudgetItemFilter } from "../state/slices/budgetItemSlice";
import { v4 as uuidv4 } from "uuid";
import slugify from "slugify";
import { Collection, PromiseExtended } from "dexie";
import { readVBase64 } from "../Helpers";

log.setLevel(0);

export async function checkIsOnline() {
    return Httpclient.checkServerStatus();
}

export function prepareData<T extends Trackable>(data: T) {
    const date = new Date();
    const dateStr = formatISO(date);
    data.updatedOn = dateStr;
    if (!data.createdOn) {
        data.createdOn = dateStr;
    }
    return data;
}


export function deleteBudgetItemPayment(data: BudgetItemPayment): Promise<void> {
    if (data.id) {
        return database.budgetItemPayment.delete(data.id);
    }
    return Promise.resolve();
}

export async function fetchBudgetItemPayments(code: string): Promise<BudgetItemPayment[]> {
    log.info("FEtching payments for " + code);
    const rs = await database.budgetItemPayment.where("budgetItemCode").equalsIgnoreCase(code)
        .sortBy("datePaid");
    return Promise.all(rs.map(async it => {
        if (it.paymentSourceCode) {
            it.paymentSource = await database.budgetItem.where("code").equalsIgnoreCase(it.paymentSourceCode).first();
        }
        return it;
    }));
}

export async function postBudgetItemPayment(data: BudgetItemPayment): Promise<BudgetItemPayment> {
    const saveData = prepareData(data);
    saveData.isSynced = SYNC_PENDING_STATUS;
    saveData.paymentSource = undefined;
    return database.budgetItemPayment.put(saveData, data.id)
        .then(id => {
            saveData.id = id;
            return saveData;
        });
}

export async function fetchAllPaymentSources(code: string): Promise<BudgetItem[]> {
    return database.budgetItem.where("code").equals(code).sortBy("name");
}

export async function fetchAllItemsPaidBySource(code: string): Promise<BudgetItemPayment[]> {
    const rs = await database.budgetItemPayment.where("paymentSourceCode").equals(code).sortBy("date");
    return Promise.all(rs.map(async payment => {
        payment.budgetItem = await database.budgetItem.where("code").equalsIgnoreCase(payment.budgetItemCode).first();
        return payment;
    }));
}

export async function postBudgetItem(data: BudgetItem): Promise<BudgetItem> {

    const saveData = prepareData(data);
    if (!saveData.code || saveData.code.trim().length < 1) {
        saveData.code = uuidv4();
    }
    saveData.ownerCode = "";
    const id = await database.budgetItem.put(saveData, saveData.id);
    return new Promise(resolve => {
        saveData.id = id;
        resolve(saveData);
    });
}

export function deleteBudgetItem(data: BudgetItem): Promise<number> {
    if (!data.id) {
        return Promise.reject("Cannot delete budget item that has not been saved");
    }
    if (!Boolean(data.serverId)) {
        return database.budgetItem.delete(data.id).then(() => {
            return 1;
        });
    }
    return database.budgetItem.update(data.id, {
        deleted: IS_DELETED,
        updatedOn: formatISO(new Date()),
        synced: SYNC_PENDING_STATUS
    });
}

export function getBudgetTypes(): Promise<BudgetItemType[]> {
    return database.budgetItemType.toArray();
}

export function getCategories(): Promise<Category[]> {

    return database.category.toArray();
}

export async function fetchYearlyStats(year: number): Promise<YearData> {
    const stats: Map<string, BasicStatistics> = new Map<string, BasicStatistics>();
    await database.budgetItem.each((value) => {
        const date = fromUnixTime(value.date);
        if (getYear(date) === year) {
            const month = format(date, "MMMM");
            let monthStat: BasicStatistics | undefined = stats.get(month);
            if (!monthStat) {
                monthStat = getDefaultMonthStats();
            }
            monthStat = getMonthStats(monthStat, date, value);
            stats.set(month, monthStat);
        }
    });

    const monthlyStats: Budget[] = [];
    stats.forEach(((value, key) => {
        // monthlyStats.push({
        //     id: -1,
        //     year: year,
        //     isShared: false,
        //     name: key,
        //     stats: value
        // });
    }));

    const yeardata: YearData = {
        yearName: year + "",
        stats: monthlyStats
    };


    return new Promise(resolve => {
        resolve(yeardata);
    });
}

function getMonthStats(monthStat: BasicStatistics, date: Date, value: BudgetItem): BasicStatistics {
    const amount = Number(value.amount);
    const paid = sumBudgetItemPaidAmount(value);
    if (value.itemTypeId === 1) {
        monthStat.expensesTotal += amount;
        monthStat.expensesClearedTotal += paid;
    } else if (value.itemTypeId === 2) {
        monthStat.debtsTotal += amount;
        monthStat.debtsClearedTotal += paid;
    } else {
        monthStat.lendingsTotal += amount;
        monthStat.lendingsClearedTotal += paid;
    }

    return monthStat;
}


function getDefaultMonthStats(): BasicStatistics {
    return {
        expensesTotal: 0,
        lendingsTotal: 0,
        debtsTotal: 0,
        debtsClearedTotal: 0,
        expensesClearedTotal: 0,
        lendingsClearedTotal: 0
    } as BasicStatistics;
}

export async function doServerlogin(tokenInfo: User): Promise<Response> {
    let data: { [key: string]: any } = { ...tokenInfo };
    delete data["id"];
    return Httpclient.postUnAuthenticated(Httpclient.LOGIN_URL, {
        body: JSON.stringify(data)
    });
}

export async function login(user: User): Promise<User> {
    await database.user.clear();
    user.is_logged_in = 1;
    let readValue = readVBase64(user.access_token, "exp");
    user.access_token_exp_at = readValue ? parseInt(readValue) : 0;
    user.id = await database.user.put(prepareData(user), user.id);
    log.info("Saving user");
    return new Promise(resolve => resolve(user));
}

export async function logout() {
    await database.user.clear();
}

export async function loadUserInfo(): Promise<User | undefined> {
    let user = await database.user.where("is_logged_in").equals(1).first();
    if (!user || !user.access_token) {
        user = undefined;
    }
    if (Httpclient.isTokenValid(user)) {
        user = await database.user.where("is_logged_in").equals(1).first();
    } else {
        const refreshd = await Httpclient.doRefresh();
        log.info("Token has been refresh", refreshd);
    }
    return new Promise<User | undefined>(resolve => resolve(user));
}

export function getUserCode(user: User | string | undefined) {
    let name = "";

    if (!user) {
        return "";
    }
    if (typeof user === "object") {
        name = `${user.first_name} ${user.last_name}`;
    } else {
        name = user;
    }
    const parts: string[] = name.split(" ");
    let code = parts[0].charAt(0).toUpperCase();
    if (parts.length > 1) {
        code += parts[1].charAt(0).toUpperCase();
    }
    return code;
}

export function stringToColor(string: string | undefined) {
    string = string ?? "";
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export async function getAttachedDebts(budgetItemCode: string): Promise<BudgetItem[]> {

    const debts = await database.debtItem.where("budgetItemCode").equals(budgetItemCode).toArray();
    const debtIds = debts.map(it => it.debtCode);
    return addBudgetItemPayments(database.budgetItem.where("code").anyOf(debtIds).toArray());
}

export function isPaymentSource(name: string): boolean {
    name = name.toLowerCase();
    return BUDGETYPE.FUNDS === name || BUDGETYPE.DEBT === name;
}

export async function getBudgetItemByType(budgetItemType: string): Promise<BudgetItem[]> {
    return addBudgetItemPayments(database.budgetItem.where("itemTypeCode").equalsIgnoreCase(budgetItemType)
        .sortBy("date"));
}

export async function fetchBudgetItem(code: string): Promise<BudgetItem> {
    return database.budgetItem.where("code").equals(code)
        .first()
        .then(it => {
            if (!it) {
                return Promise.reject(`unknown ${code}`);
            }
            return doPaymentPopulate(it);
        });
}

async function budgetItemWithRelated(res: Array<BudgetItem>): Promise<BudgetItem[]> {
    log.info(`Populate budgetItem With Related Data for ${res.length} items `);
    const paymentStatus: Map<string, string> = new Map<string, string>();
    await database.paymentStatus.each(val => {
        paymentStatus.set(val.code.toLowerCase(), val.code.toLowerCase());
    });
    const userMap: Map<string, string> = new Map<string, string>();
    const userEmails = res.filter(item => item.assignedTo)
        .map(item => item.assignedTo);
    await database.user.where("email").anyOf(userEmails)
        .each(user => {
            userMap.set(user.email, `${user.first_name} ${user.last_name}`);
        });

    log.info("Fetched related Users status ", userMap);

    return Promise.all(res.map(async (record: BudgetItem) => {
        if (record.assignedTo) {
            record.ownerCode = record.assignedTo.substr(0, record.assignedTo.indexOf("@"));
        }
        const name = userMap.get(record.assignedTo);
        if (name && name.length > 0) {
            record.ownerCode = getUserCode(name);
        }
        record.paid = record.paid === undefined ? 0 : record.paid;
        const paid = sumBudgetItemPaidAmount(record);
        if (record.amount === paid) {
            record.paymentStatusCode = paymentStatus.get("paid") as string;
        } else if (record.amount !== paid && paid > 0) {
            record.paymentStatusCode = paymentStatus.get("partial") as string;
        } else {
            record.paymentStatusCode = paymentStatus.get("pending") as string;
        }

        record.itemTypeCode = record.itemTypeCode || BUDGETYPE.EXPENSE.toLowerCase();
        return record;
    }));
}


export async function attacheBudgetItemToDebt(debtItems: DebtItem[], budgetItemCode: string): Promise<number[]> {
    await database.debtItem.where("budgetItemCode").equals(budgetItemCode).delete();
    return database.debtItem.bulkAdd(debtItems, { allKeys: true });
}

export async function getBudgetItemByCategory(loggedInUser: string, category: string, filter: BudgetItemFilter,
    startIndex: number, pageSize: number): Promise<BudgetItem[]> {
    const budgets: string[] = (await fetchBudgets(loggedInUser)).map(b => b.code);

    const customFilter = (rs: Collection<BudgetItem, number>) => {
        return rs.filter(item => {
            let canview = category === ALL;
            if (!category || category === UNCATEGORIZED) {
                canview = !item.tag || item.tag.length <= 0;
            }
            canview = canview || category === item.tag;

            return canview;
        });
    };

    const rs = await getFilteredBudgetItems(filter, pageSize, startIndex, budgets, customFilter);

    return budgetItemWithRelated(rs);
}


export async function searchBudgetItem(loggedInUser: string, searchTerm: string,
    filter: BudgetItemFilter, categoryCode?: string): Promise<BudgetItem[]> {
    const budgets: string[] = (await fetchBudgets(loggedInUser)).map(b => b.code);

    const regex = new RegExp(`${searchTerm}`, "gi");
    let rs = database.budgetItem
        .filter(item => regex.test(item.name))
        .filter(item => {
            let canview = !categoryCode || ALL === categoryCode;
            if (categoryCode === UNCATEGORIZED) {
                canview = !item.tag || item.tag.length <= 0;
            }
            return canview || categoryCode === item.tag;
        }).offset(0).limit(100);
    rs = doLimitByPermission(rs, filter, budgets);
    const results = await addBudgetItemPayments(rs.filter(it => !it.deleted).toArray());
    return budgetItemWithRelated(results);
}

async function doPaymentPopulate(record: BudgetItem, filter?: BudgetItemFilter): Promise<BudgetItem> {
    if (record.paid > 0) {
        // retain for backwoard compatibility
        return Promise.resolve(record);
    }
    let rs = database.budgetItemPayment.where("budgetItemCode").equalsIgnoreCase(record.code);
    if (filter) {
        rs = rs.and(it => {
            return it.datePaid >= filter.startDate && it.datePaid <= filter.endDate;
        });
    }
    return rs.toArray()
        .then(payments => {
            record.payments = payments;
            return record;
        }, error => {
            log.error(error);
            return record;
        });
}

export function sumBudgetItemPaidAmount(budgetItem: BudgetItem) {
    const sum = budgetItem.payments?.map(it => it.amount)
        .reduce((sum, current) => {
            return Number(sum) + Number(current);
        }, 0);
    return sum || budgetItem.paid;
}

export async function addBudgetItemPayments(rs: Promise<BudgetItem[]>, filter?: BudgetItemFilter) {
    const x = await rs;
    return Promise.all(x.map(it => {
        return doPaymentPopulate(it, filter);
    }));
}


function doLimitByPermission(rs: Collection<BudgetItem, number>, filter: BudgetItemFilter, budgets: string[]) {
    const type = filter.budgetItemType;

    return rs
        .and(it => {
            return it.deleted !== IS_DELETED;
        })
        .and(item => {
            let canView = true;
            if (filter.budgetCode && filter.budgetCode !== ALL) {
                canView = filter.budgetCode === item.budgetCode;
            }
            canView = canView && budgets.includes(item.budgetCode ? item.budgetCode : "");
            return canView;
        })
        .and(item => {
            let canView;
            if (!item.itemTypeCode) {
                item.itemTypeCode = "expense";
            }
            canView = item.itemTypeCode === type;
            return canView;
        })
        .and(item => {
            let canView = false;
            if (!filter.user || filter.user === ALL || filter.user.length < 1) {
                canView = true;
            }
            canView = canView || Boolean(filter.user ? item.assignedTo === filter.user : true);
            return canView;
        });
}

function intenalFilterBudgetItems(
    filter: BudgetItemFilter, pageSize: number, offset: number, budgets: string[],
    doCustomFilter: (rs: Collection<BudgetItem, number>) => Collection<BudgetItem, number>
): PromiseExtended<Collection<BudgetItem, number>> {
    log.info(`*****Filter offset ${offset} to pageSize ${pageSize} between ${fromUnixTime(
        filter.startDate)} and ${fromUnixTime(filter.endDate)}`);
    return database.budgetItem
        .where("date")
        .between(filter.startDate, filter.endDate, true, true)
        .and(it => it.itemTypeCode === BUDGETYPE.FUNDS)
        .and(it => {
            return it.deleted !== IS_DELETED;
        })
        .toArray()
        .then(funds => {
            return funds.map(it => it.code);
        }, error => {
            log.error(error);
            return [];
        })
        .then(fundsCodes => {

            const paidBySource = database.budgetItemPayment.where("paymentSourceCode")
                .anyOfIgnoreCase(fundsCodes)
                .toArray(records => {
                    return records.map(it => it.budgetItemCode);
                });
            const currentPeriod = database.budgetItemPayment.where("datePaid")
                .between(filter.startDate, filter.endDate, true, true)
                .toArray(records => {
                    return records.map(it => it.budgetItemCode);
                });
            return Promise.all([paidBySource, currentPeriod])
                .then(payments => {
                    return payments[0].concat(payments[1]);
                }, error => {
                    log.error(error);
                    return [];
                });
        })
        .then(async budgetItemCodes => {
            let rs = database.budgetItem.where("date")
                .between(filter.startDate, filter.endDate, true, true)
                .and(it => {
                    return it.deleted !== IS_DELETED;
                });
            if (budgetItemCodes && budgetItemCodes.length > 0) {
                rs = rs.or("code").anyOfIgnoreCase(budgetItemCodes);
            }
            rs = doLimitByPermission(rs, filter, budgets);
            if (doCustomFilter) {
                rs = doCustomFilter(rs);
            }
            return rs.distinct()
            .offset(offset).limit(pageSize);
        });
}

async function getFilteredBudgetItems(filter: BudgetItemFilter, pageSize: number, offset: number, budgets: string[],
    doCustomFilter: (rs: Collection<BudgetItem, number>) => Collection<BudgetItem, number>): Promise<BudgetItem[]> {

    const rs = await intenalFilterBudgetItems(filter, pageSize, offset, budgets, doCustomFilter);

    return addBudgetItemPayments(rs.filter(it => !it.deleted).toArray());

}


export async function fetchCarriedForwardBudgetItems(userEmail: string, filter: BudgetItemFilter): Promise<BudgetItem[]> {
    userEmail = userEmail || filter.user;
    const type = filter.budgetItemType;
    const budgets: string[] = (await fetchBudgets(userEmail)).map(b => b.code);
    log.info(
        `Fetching CarriedForward upto ${filter.startDate} <> ${fromUnixTime(
            filter.startDate)} from budgets ${budgets} n user ${userEmail}`,
        budgets);
    const payments: string[] = (await database.budgetItemPayment
        .where("datePaid")
        .between(filter.startDate, filter.endDate)
        .toArray()).map(it => it.budgetItemCode);
    const rs: BudgetItem[] = [];
    await database.transaction("r", [database.budgetItem, database.budgetItemPayment], trans => {
        return database.budgetItem.where("date").belowOrEqual(filter.startDate)
            .and(item => {
                return !payments.includes(item.code);
            })
            .and(item => {
                return item.itemTypeCode === type;
            })
            .and(item => {
                if (filter.budgetCode &&
                    filter.budgetCode.length > 0 &&
                    filter.budgetCode !== ALL) {
                    return budgets.includes(item.budgetCode) && filter.budgetCode === item.budgetCode;
                }
                return budgets.includes(item.budgetCode);
            })
            .each(async item => {
                item = await doPaymentPopulate(item);
                if (sumBudgetItemPaidAmount(item) < item.amount) {
                    rs.push(item);
                }
            });
    });

    return budgetItemWithRelated(rs);
}


function getPendingExpenses(filter: BudgetItemFilter): Collection<BudgetItem, number> {
    const prevMonthLastDay = endOfMonth(subMonths(fromUnixTime(filter.startDate), 1));
    return database.budgetItem.where("date").below(prevMonthLastDay)
        .and(item => {
            return item.itemTypeCode === "expense";
        })
        .and(item => {
            if (!filter.user || filter.user === ALL || filter.user.length < 1) {
                return true;
            }
            return filter.user ? item.assignedTo === filter.user : true;
        });
}

export async function getBudgetItemCategories(loggedInUser: string, filter: BudgetItemFilter): Promise<CategoryStats[]> {

    const budgets: string[] = (await fetchBudgets(loggedInUser)).map(b => b.code);
    const paymentStatus: Map<string, string> = new Map<string, string>();
    await database.paymentStatus.each(val => {
        paymentStatus.set(val.code.toLowerCase(), val.code.toLowerCase());
    });

    const cats: Map<string, CategoryStats> = new Map<string, CategoryStats>();
    const budgetsItemProm = (await intenalFilterBudgetItems(filter, 10000, 0, budgets, rs => rs));
    await database.transaction("r", [database.budgetItem, database.budgetItemPayment], trans => {
        return budgetsItemProm.each(async rec => {
            rec = await doPaymentPopulate(rec);
            const identity = rec.tag || UNCATEGORIZED;
            let stats: CategoryStats = cats.get(identity) ?? {
                name: identity,
                paid: 0,
                amount: 0,
                itemsPendingSync: 0,
                itemCount: 0,
                paymentStatusCode: ""
            } as CategoryStats;

            stats.itemCount += 1;
            stats.paid += sumBudgetItemPaidAmount(rec);
            stats.amount += Number(rec.amount);
            if (rec.isSynced === SYNC_PENDING_STATUS) {
                stats.itemsPendingSync = +1;
            }

            if (stats.amount === stats.paid) {
                stats.paymentStatusCode = paymentStatus.get("paid") as string;
            } else if (stats.amount !== stats.paid && stats.paid > 0) {
                stats.paymentStatusCode = paymentStatus.get("partial") as string;
            } else {
                stats.paymentStatusCode = paymentStatus.get("pending") as string;
            }

            cats.set(identity, stats);
        }).then(err => {
            log.info(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Fetching category success", err);
        }).catch(err => {
            log.error(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Fetching category errors", err);
        });
    });

    return Promise.resolve(Array.from(cats.values()));
}

export async function getCurriedForwardDebts(filter: BudgetItemFilter): Promise<BudgetItem[]> {
    return getPendingExpenses(filter).toArray();
}

export async function getTrackerInfo(): Promise<SyncTracker[]> {
    return database.syncTracker.toArray();
}

export async function getBasicStats(loggedInUser: string, filter: BudgetItemFilter): Promise<BasicStatistics> {
    const budgets: string[] = (await fetchBudgets(loggedInUser)).map(b => b.code);

    const basicStat: BasicStatistics = {
        cfDebtPaid: 0,
        cfExpensePaid: 0,
        cfLendingPaid: 0,
        lendingsClearedTotal: 0,
        debtsClearedTotal: 0,
        debtsTotal: 0,
        lendingsTotal: 0,
        expensesTotal: 0,
        expensesClearedTotal: 0
    };

    const populatClearedTotals = (typeCode: string | undefined, value: number) => {
        if (typeCode) {
            if (typeCode === BUDGETYPE.DEBT) {
                basicStat.debtsClearedTotal += value;
            } else if (typeCode === BUDGETYPE.EXPENSE) {
                basicStat.expensesClearedTotal += value;
            } else if (typeCode === BUDGETYPE.LEND) {
                basicStat.lendingsClearedTotal += value;
            }
        }
    };

    const populateOverallTotals = (itemTypeCode: string, amount: number) => {
        if (itemTypeCode === BUDGETYPE.DEBT) {
            basicStat.debtsTotal += amount;
        } else if (itemTypeCode === BUDGETYPE.EXPENSE) {
            basicStat.expensesTotal += amount;
        } else if (itemTypeCode === BUDGETYPE.LEND) {
            basicStat.lendingsTotal += amount;
        }
    };

    const funds = new Set<string>();
    const paidBudgetItemAmount = new Map<string, number>();
    // get all items for the period
    const filteredDataByDate = database.budgetItem.where("date").between(filter.startDate, filter.endDate,
        true, true);
    await filterBudgetItems(filteredDataByDate, filter, budgets).each(it => {
        if (it.itemTypeCode === BUDGETYPE.FUNDS) {
            funds.add(it.code);
        }
        populateOverallTotals(it.itemTypeCode, it.amount);
    });
    // get stats for funds
    const paymentSources = new Set();
    await database.budgetItemPayment.where("paymentSourceCode").anyOfIgnoreCase(Array.from(funds.values()))
        .each(it => {
            paymentSources.add(it.budgetItemCode);
            let amount = paidBudgetItemAmount.get(it.budgetItemCode) || 0;
            paidBudgetItemAmount.set(it.budgetItemCode, amount + it.amount);
        });

    // get items paid in the period
    await database.budgetItemPayment.where("datePaid")
        .between(filter.startDate, filter.endDate, true, true)
        .each(it => {
            let amount = paidBudgetItemAmount.get(it.budgetItemCode) || 0;
            paidBudgetItemAmount.set(it.budgetItemCode, amount + it.amount);
        });


    if (paidBudgetItemAmount.size > 0) {
        let collection = database.budgetItem.where("code").anyOfIgnoreCase(Array.from(paidBudgetItemAmount.keys()));
        await filterBudgetItems(collection, filter, budgets).each(it => {
            let budgetAmount = paidBudgetItemAmount.get(it.code) || 0;

            if (paymentSources.has(it.code)) {
                if (isAfter(addMinutes(it.date, 1), filter.startDate) &&
                    isBefore(subMinutes(it.date, 1), filter.endDate)) {
                    populateOverallTotals(it.itemTypeCode, budgetAmount);
                }
                paymentSources.delete(it.code);
            }

            populatClearedTotals(it.itemTypeCode, budgetAmount);
        });
    }

    return Promise.resolve(basicStat);
}


export async function getChamas(userEmail: string): Promise<Chama[]> {
    return database.chama.toArray();
}

export async function addChamaContribution(contributions: ChamaContribution[]): Promise<number[]> {
    const ids: number[] = contributions.map(item => item.chamaId);
    await database.chamaContribution.where("budgetItemId").anyOf(ids).delete();
    return database.chamaContribution.bulkAdd(contributions, { allKeys: true });
}

export function makeSlugCode(name: string) {
    return name.toLowerCase().trim().replace(" ", "-");
}

export function addChama(chama: Chama): Promise<Chama> {
    const saveData = prepareData(chama);
    saveData.code = slugify(saveData.name);
    return database.chama.put(saveData, saveData.id)
        .then(id => {
            saveData.id = id;
            return saveData;
        });
}

export async function getChamaContributions(chamaCode: number): Promise<ChamaContribution[]> {
    return database.chamaContribution.where("chamaCode").equals(chamaCode).toArray();

}

export async function getChamaByBudgetItem(budgetItemId: number): Promise<Chama[]> {
    const conns = await database.chamaContribution.where("budgetItemId").equals(budgetItemId)
        .toArray();
    return database.chama.where("id").anyOf(conns.map(con => con.chamaId)).toArray();
}

export function sumArray(items: number[]) {
    return items.reduce((a, b) => a + b, 0);
}

// ****************** Categories
export async function addCategory(name: string): Promise<Category> {
    let cat = {
        name: name,
        code: slugify(name, { lower: true })
    } as Category;
    cat = prepareData(cat);

    return database.category.where("code").equals(cat.code).first()
        .then(val => {
            if (val) {
                return Promise.reject("Category already exists");
            }
            return database.category.add(cat).then(id => {
                cat.id = id;
                return cat;
            });
        });
}

// ****************** USERS
export function fetchUsers(includeLoggedUser?: boolean): Promise<User[]> {
    let users: Promise<User[]>;
    if (includeLoggedUser) {
        log.info("Fetching all users");
        users = database.user.toArray();
    } else {
        log.info("Fetching all users except logged in user");
        users = getLoggedInUser()
            .then(user => {
                return database.user.where("email").notEqual(user?.email || "").toArray();
            });
    }
    return users.then(items => {
        return items.map(item => {
            item.userCode = getUserCode(item.name);
            return item;
        });
    });
}

export function saveUser(data: User): Promise<User> {
    data = prepareData(data);
    return database.user.where("email").equals(data.email).first()
        .then(user => {
            if (user) {
                data = { ...user, ...data };
                data.id = user.id;
            }
            if (!data.is_logged_in) {
                data.is_logged_in = 0;
            }
            return database.user.put(data, data.id)
                .then(id => {
                    data.id = id;
                    return data;
                });
        });

}


export async function getLoggedInUser(): Promise<User | undefined> {
    return database.user.where("is_logged_in").equals(1).first();
}


export async function fetchBudgetItems(lastFetchTimeStamp: string, maxFetchedID?: string): Promise<Response> {
    log.info(`>>> fetchBudgetItems from server fromDAte ${lastFetchTimeStamp} and maxFetchedID ${maxFetchedID}`);
    const params = new URLSearchParams();
    params.set("from_expense_date", lastFetchTimeStamp);
    if (maxFetchedID) {
        params.set("max_fetched_id", `${maxFetchedID}`);
    }
    return Httpclient.get(`${Httpclient.EXPENSES_URL}?${params.toString()}`);
}

export async function fetchCategories(lastFetchTimeStamp: string): Promise<Response> {
    log.info("Fetching from server");
    const params = new URLSearchParams();
    params.set("from_update_date", lastFetchTimeStamp);
    return Httpclient.get(`${Httpclient.TAGS_URL}?${params.toString()}`);
}

export async function fetchUserConnections(lastFetchTimeStamp: string): Promise<Response> {
    log.info("fetchUserConnections from server");
    const params = new URLSearchParams();
    params.set("from_expense_date", lastFetchTimeStamp);
    return Httpclient.get(`${Httpclient.BUDGET_MEMBER_URL}?${params.toString()}`);
}

export async function fetchBudgetsFromServer(lastFetchTimeStamp: string): Promise<Response> {
    log.info("fetchUserConnections from server");
    const params = new URLSearchParams();
    params.set("from_update_date", lastFetchTimeStamp);
    return Httpclient.get(`${Httpclient.BUDGET_URL}?${params.toString()}`);
}

export async function addSyncTrack(date: Date, recordType: string): Promise<SyncTracker> {
    const lastSysnc = await database.syncTracker.where({ "recordType": recordType }).last();
    let saveData = prepareData({ lastSyncTime: formatRFC3339(date), recordType: recordType } as SyncTracker);
    if (lastSysnc) {
        lastSysnc.lastSyncTime = saveData.lastSyncTime;
        saveData = lastSysnc;
    }
    log.info(`Updating Sync data for [${recordType}] is [${date}]`);
    return database.syncTracker
        .put(saveData, saveData.id)
        .then(id => {
            saveData.id = id;
            return saveData;
        });
}

export async function fetchBudgets(userEmail: string): Promise<Budget[]> {
    // const codes = (await database.budgetMember.where("userEmail").equals(userEmail)
    //                              .toArray()).map(m => m.budgetCode);
    // log.info(`User with email ${userEmail} can access budgets ${codes}`, codes);
    return database.budget.filter(it => {
        return it.members?.includes(userEmail) || false;
    }).toArray();
}

//
// export async function fetchBudgetMembers(userEmail: string): Promise<User[]> {
//     const codes = (await database.budgetMember.where("userEmail").equals(userEmail)
//                                  .toArray()).map(m => m.budgetCode);
//     log.info(`User with email ${userEmail} can access budgets ${codes}`, codes);
//     const rs = await database.budgetMember.where("budgetCode").anyOf(codes).toArray();
//
//     return database.user.where("email").anyOf(rs.map(it => it.userEmail)).toArray();
// }

export function saveBudget(loggedInUserEmail: string, budget: Budget, users?: string[]): Promise<Budget> {
    if (!budget.id || !budget.code) {
        budget.code = slugify(budget.name, { lower: true });
    }
    const saveData = prepareData(budget);
    // let hasLoggedInUser = false;
    // const members: BudgetMember[] = !users ? [] : users.map(email => {
    //     if (loggedInUserEmail === email) {
    //         hasLoggedInUser = true;
    //     }
    //     const c = {
    //         userEmail: email,
    //         budgetCode: saveData.code,
    //         synced: SYNC_PENDING_STATUS
    //     } as BudgetMember;
    //     return prepareData(c);
    // });
    //
    // if (!hasLoggedInUser) {
    //     const member = {
    //         userEmail: loggedInUserEmail,
    //         budgetCode: saveData.code,
    //         synced: SYNC_PENDING_STATUS
    //     } as BudgetMember;
    //     members.push(member);
    // }
    saveData.synced = SYNC_PENDING_STATUS;
    saveData.members = [loggedInUserEmail];
    return database.budget.put(saveData, saveData.id)
        .then(id => {
            saveData.id = id;
            return saveData;
        });
}

// ****************** Logs

export async function getRequestLogs(): Promise<RequestLog[]> {
    return database.requestLog.reverse().toArray();
}

function filterBudgetItems(collection: Collection<BudgetItem, number>, filter: BudgetItemFilter, budgets: string[]) {
    return collection
        .and(item => {
            if (filter.budgetCode && filter.budgetCode.length > 0 && filter.budgetCode !== ALL) {
                return budgets.includes(
                    item.budgetCode) && filter.budgetCode === item.budgetCode;
            }
            return budgets.includes(item.budgetCode);
        })
        .and(item => {
            if (!filter.user || filter.user === ALL || filter.user.length < 1) {
                return true;
            }
            return filter.user ? item.assignedTo === filter.user : true;
        })
        .and(item => !item.deleted);
}

