import VerticalBar, {VerticalChartData} from "./VerticalBar";
import React, {useEffect, useState} from "react";
import {getBudgetItemCategories} from "../../db/service";
import {useAppSelector} from "../../state/hooks";
import {BudgetItemFilter} from "../../state/slices/budgetItemSlice";
import log from "loglevel";
import {fromUnixTime} from "date-fns";
import {Paper, Typography} from "@mui/material";

export function ExpenseCategoryCharts(props: {}) {
    const [chartData, setChartData] = useState<VerticalChartData>({amount: [], paid: [], labels: []});
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);


    useEffect(() => {
        loadDataCats();
        const {startDate, endDate, ...rest} = filter;
        log.info(`Showing charts for dates [${fromUnixTime(startDate)} to ${fromUnixTime(endDate)}] and `, rest);
    }, [filter]);

    function loadDataCats() {
        getBudgetItemCategories(user.email, filter).then(recs => {
            recs.forEach(item => {
                if (!chartData.labels.includes(item.name)) {
                    chartData.labels.push(item.name);
                    chartData.paid.push(item.paid);
                    chartData.amount.push(item.amount);
                }
            });
            setChartData({...chartData} as VerticalChartData);
        });
    }

    return <>
        <Paper elevation={3}>
            <Typography variant={"h6"}>Expense Chart</Typography>
        </Paper>
        {chartData.labels.length > 0 && (<VerticalBar data={chartData} title={""}/>)}
    </>;
}