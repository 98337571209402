import {AppBar, Box, DialogContent, IconButton, Toolbar} from "@mui/material";
import React from "react";
import {ClosableProps} from "../AppDialog";
import CloseIcon from "@mui/icons-material/Close";
import {ExpenseCategoryCharts} from "./ExpenseCategoryCharts";
import {PeriodFilter} from "../PeriodFilter";
import MonthlyStatsPieChart from "./MonthlyStatsPieChart";

export function ChartPage(props: ClosableProps) {
    const onClose = (e: any) => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return <>
        <AppBar sx={{position: "relative"}}>
            <Toolbar>
                Expense bar chart
                <Box sx={{flexGrow: 1}}/>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
        <PeriodFilter/>
        <DialogContent sx={{padding: 1}}>
            <MonthlyStatsPieChart/>
            <ExpenseCategoryCharts/>
        </DialogContent>
    </>;
}