import {Pie} from "react-chartjs-2";
import {BasicStatistics} from "../../db/models";
import {useEffect, useState} from "react";
import {getBasicStats} from "../../db/service";
import {useAppSelector} from "../../state/hooks";
import {BudgetItemFilter} from "../../state/slices/budgetItemSlice";
import {Paper, Typography} from "@mui/material";


function MonthlyStatsPieChart(props: {}) {
    const [chartData, setChartData] = useState<BasicStatistics>({
        cfDebtPaid: 0,
        cfExpensePaid: 0,
        cfLendingPaid: 0,
        debtsClearedTotal: 0,
        expensesClearedTotal: 0,
        lendingsClearedTotal: 0,
        expensesTotal: 0, debtsTotal: 0,
        lendingsTotal: 0
    });
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);

    function loadData() {
        getBasicStats(user.email, filter).then(res => {
            setChartData(res);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [filter]);

    const data = {
        labels: ["Expense", "Loan", "Lending"],
        datasets: [
            {
                label: "Summary",
                data: [chartData.expensesTotal, chartData.debtsTotal, chartData.lendingsTotal],
                borderWidth: 1,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)"
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                ]
            }
        ]
    };
    return (
        <>
            <Paper elevation={3}>
                <Typography variant={"h6"}>Total stats chart</Typography>
            </Paper>
            <Pie data={data} options={{
                plugins: {
                    legend: {
                        position: "right"
                    }
                }
            }}/>
        </>
    );
}


export default MonthlyStatsPieChart;

