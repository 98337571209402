import {Grid, Link as MatLink} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React from "react";

export default function MatLinker(props: { to: string, children: any, startIcon?: React.ReactNode }) {
    return (<MatLink to={props.to} flexGrow={1}
                     underline={"none"}
                     component={RouterLink}>
        <Grid container spacing={1}>
            <Grid item>
                {props.startIcon}
            </Grid>
            <Grid item flexGrow={1}>
                {props.children}
            </Grid>
        </Grid>
    </MatLink>);
}