import React, {ReactNode, useState} from "react";
import {
    AppBar, Box, Button, ButtonProps, DialogActions, DialogContent, IconButton, Toolbar, Typography, useTheme
} from "@mui/material";
import {AppDialog} from "../budget/AppDialog";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
    deleteCallback?: (e: any) => Promise<any>;
    children?: ReactNode;
    ButtonProps?: ButtonProps
}
export type DeleteButtonProps = Props;

export function DeleteButton(props: DeleteButtonProps) {
    const theme = useTheme();
    const {deleteCallback, children, ButtonProps} = props;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    function onDelete() {
        setOpenConfirmDialog(true);
    }

    function onClose() {
        setOpenConfirmDialog(false);
    }

    function doDelete(e: any) {
        if (deleteCallback) {
            deleteCallback(e).then(count => {
                setOpenConfirmDialog(false);
            });
        }
    }

    return (<>
        <Button onClick={onDelete} {...ButtonProps}>{children}</Button>
        <AppDialog open={openConfirmDialog}>
            <AppBar sx={{position: "relative", backgroundColor: theme.palette.error.main}}>
                <Toolbar>
                    Delete item
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Typography variant={"body2"}>
                    Are you sure you want to delete ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
                <Button type="submit" color="primary" variant={"outlined"} onClick={doDelete}>
                    Ok
                </Button>
            </DialogActions>
        </AppDialog>
    </>);
}