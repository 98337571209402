import {BudgetItem} from "../../db/models";
import {postBudgetItem} from "../../db/service";
import log from "loglevel";
import {Button} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import React from "react";

export function SyncBudgetItems(props: { data: BudgetItem[], onDone?: (saved: boolean) => void }) {
    const {data, onDone} = props;

    const onClick = (event: any) => {
        Promise.all(data.map(item => {
            item.isSynced = 0;
            return postBudgetItem(item);
        }))
               .then(updated => {
                   log.info("Update for synce done ", updated);
                   if (onDone) {
                       onDone(true);
                   }
               });
    };

    return <>
        <Button variant={"text"} onClick={onClick} endIcon={<SyncIcon/>} size={"small"}>
            Sync
        </Button>
    </>;
}