import {Budget, User} from "../db/models";
import {useAppSelector} from "../state/hooks";
import React, {useState} from "react";
import {fetchBudgets, saveBudget} from "../db/service";
import {Box, Button, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import {AppDialog} from "./AppDialog";
import {UserList} from "../user/UserHome";

export function BudgetShare(props: { data: Budget, onClose?: () => void }) {
    let loggedInUser: User = useAppSelector(state => state.sharedSliceReducer.user);

    const [openDialog, setOpenDialog] = useState(false);
    const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
    const doClose = (e: any) => {
        setOpenDialog(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    const doOpen = (e: any) => {
        setOpenDialog(true);
    };
    const onItemChecked = (user: User[]) => {
        setCheckedUsers(user.map(it => it.email));
    };

    const doShare = () => {
        saveBudget(loggedInUser.email, props.data, checkedUsers).then(item => {
            doClose(null);
        });
    };

    function fetchBudgetsMember(): Promise<User[]> {
        return fetchBudgets(loggedInUser.email)
            .then(budgets => {
                return budgets.flatMap(it => {
                    return (it.members || []).map(email => {
                        let newVar: User = {
                            access_token: "",
                            email_verified: false,
                            first_name: "",
                            imageUrl: "",
                            is_logged_in: 0,
                            last_name: "",
                            name: "",
                            photo_url: "",
                            provider: "",
                            refresh_token: "",
                            email: email
                        };
                        return newVar;
                    });
                });
            });
    }

    return <>
        <Button variant={"text"} size={"small"} onClick={doOpen}>
            <ShareIcon fontSize="small"/> Share
        </Button>
        <AppDialog open={openDialog} onClose={doClose} fullWidth>
            <DialogTitle id="form-dialog-title">
                <Typography variant={"body2"}>
                    Share budget {props.data.name}
                </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{padding: 0}}>

                <UserList selectable multiSelectable onItemChecked={onItemChecked}
                          getChecked={() => fetchBudgetsMember()}/>
            </DialogContent>
            <DialogActions>
                <Box flexGrow={1}>
                    {checkedUsers.length > 0 && (<Typography variant={"caption"}>
                        Selected {checkedUsers.length}
                    </Typography>)}
                </Box>
                <Button onClick={doClose} color="secondary">
                    Close
                </Button>
                <Button onClick={doShare} color="primary" variant={"outlined"}>
                    share
                </Button>
            </DialogActions>
        </AppDialog>
    </>;
}