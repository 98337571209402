import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {PeriodHeader} from "../PeriodFilter";
import {TabContextToolbar} from "../TabContextToolbar";
import {BudgetItemsList} from "./BudgetItemsList";

export function BudgetItemsPage(props: {}) {
    const {name} = useParams<{ name: string }>();
    const [refresh, setRefresh] = useState<boolean>(false);

    return <>
        <TabContextToolbar categoryCode={name} onClose={status => setRefresh(status)}  enableViewTypeSelector={false}/>
        <PeriodHeader/>
        <BudgetItemsList category={name} loadData={refresh}
                         onLoadingDone={() => {
                             setRefresh(false);
                         }}/>
    </>;
}
