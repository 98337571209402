import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

export function PaymentStatusChip<T>(props: { data: T, getStatus: (data: T) => string, ChipProps?: SvgIconProps }) {
    const {getStatus, data, ChipProps} = props;
    const status = getStatus(data);

    if (status === "paid") {
        return <CheckCircleIcon fontSize={"small"} color={"success"} {...ChipProps}/>;
    } else if (status === "partial") {
        return <PendingIcon fontSize={"small"} color={"info"} {...ChipProps}/>;
    }
    return <ErrorIcon fontSize={"small"} color={"error"} {...ChipProps}/>;

}