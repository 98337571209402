import {BudgetItem, BudgetItemPayment, BUDGETYPE} from "../db/models";
import {
    AppBar, Box, Button, ButtonProps, DialogContent, Divider, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup,
    Toolbar
} from "@mui/material";
import React, {useState} from "react";
import {AssociateToDebts} from "./AssociateToDebts";
import {AppDialog} from "../budget/AppDialog";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";

export function AssociateWith(props: {
    onClose?: (dataSaved: boolean) => void,
    budgetItem: BudgetItem,
    payment: BudgetItemPayment,
    ButtonProps?: ButtonProps
}) {
    const {onClose, ButtonProps, ...rest} = props;
    const [open, setOpen] = useState<boolean>(false);
    const [associateTo, setAssociateTo] = useState<string>("debt");

    const onOpen = (e: any) => {
        setOpen(true);
    };
    const doClose = (dataSaved: boolean) => {
        setOpen(false);
        if (onClose) {
            onClose(dataSaved);
        }
    };

    const toggleAssociate = (event: React.SyntheticEvent, value: any) => {
        setAssociateTo(value);
    };

    return <> <Button variant={"text"} size={"small"} startIcon={<LinkIcon fontSize="small"/>}  {...ButtonProps}
                      onClick={onOpen}>
        Add Source
    </Button>
        <AppDialog aria-labelledby="form-dialog-title" open={open}
                   onClose={(event, reason) => {
                       doClose(false);
                   }}>
            <AppBar sx={{position: "relative"}}>
                <Toolbar>
                    Add a Payment Source
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => doClose(false)}
                        aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider/>
            <DialogContent dividers sx={{pl: 0, pr: 0}}>
                <Grid item xs={12}>
                    <Stack direction={"row"}>
                        <Button variant="text">Payment Source: </Button>
                        <ToggleButtonGroup size={"small"}
                                           color="primary"
                                           value={associateTo}
                                           onChange={toggleAssociate}
                                           exclusive>
                            <ToggleButton value={BUDGETYPE.DEBT}>Loan</ToggleButton>
                            <ToggleButton value={BUDGETYPE.FUNDS}>Funds</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Grid>
                <AssociateToDebts budgetType={associateTo} onClose={doClose} {...rest}/>
            </DialogContent>
        </AppDialog>
    </>;

}