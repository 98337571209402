// ****************** Page CHANGE

import {v4 as uuidv4} from "uuid";

import {
    Budget,
    BudgetItem,
    BudgetItemPayment,
    Category,
    DataUpdateStatus,
    IS_DELETED,
    SYNC_DONE_STATUS,
    SYNC_PENDING_STATUS,
    SyncableItem
} from "../db/models";
import database from "../db/database";
import log from "loglevel";
import {formatRFC3339, fromUnixTime, getUnixTime, isAfter, isBefore} from "date-fns";
import {PaginatedData, SyncedBudget, SyncedBudgetItem, SyncedBudgetItemPayment, SyncedCategory} from "./models";
import {Httpclient} from "./httpclient";
import {
    addBudgetItemPayments,
    addSyncTrack,
    fetchBudgetItems,
    fetchBudgetsFromServer,
    fetchCategories,
    getLoggedInUser,
    prepareData
} from "../db/service";
import {formatInTimeZone, toDate} from 'date-fns-tz'


/**
 * takes in date and converts it to a utc time
 * @param date
 */
export function parseISOUtc(date: Date | string) {
    return toDate(date, {timeZone: 'UTC'});
}

// async function doFetchUserConnections(): Promise<DataUpdateStatus> {
//     const totalItems = await database.budgetMember.count();
//
//     const lastSysnc = await database.syncTracker.where({"recordType": SyncableItem.USER_CONNECTIONS}).last();
//     log.info("Initiating doFetchUserConnections, Last sysnc ", lastSysnc, "count ", totalItems);
//     //const user = await getLoggedInUser();
//     let lastFetchTime: number;
//     let maxDate: Date = new Date(2021, 7, 1, 0, 0, 0);
//     lastFetchTime = getUnixTime(maxDate);
//
//     log.info(`BudgetConnections Fetching from ${lastFetchTime} Current TotalItems in database ${totalItems}`);
//     return fetchUserConnections(lastFetchTime)
//         .then(response => {
//             if (!response.ok) {
//                 return Promise.reject({message: response.statusText, success: false, records: 0});
//             }
//             return response.json();
//         })
//         .then((resp: PaginatedData<SyncedBudgetMember>) => resp.results)
//         .then(connections => {
//             return database.budgetMember.clear().then(rid => connections);
//         })
//         .then(connections => {
//             let users: string[] = [];
//             let userConns: BudgetMember[] = connections.map(conn => {
//                 const dateUpdated = parseISO(conn.date_updated);
//                 if (getUnixTime(dateUpdated) > getUnixTime(maxDate)) {
//                     maxDate = dateUpdated;
//                 }
//                 users.push(conn.userEmail);
//                 return {
//                     budgetCode: conn.budgetCode,
//                     userEmail: conn.userEmail
//                 } as BudgetMember;
//             });
//             return database.budgetMember.bulkPut(userConns, {allKeys: true})
//                            .then(res => {
//                                return database.user.where("email").anyOf(users).toArray().then(existingusers => {
//                                    existingusers.forEach(user => {
//                                        const i = users.indexOf(user.email);
//                                        if (i > -1) {
//                                            users.splice(i, 1);
//                                        }
//                                    });
//                                    log.info("Add users to user table ", users);
//
//                                    return Promise.all(users.filter(i => i).map(i => {
//                                        return saveUser({email: i, userCode: i, name: i} as User);
//                                    })).then(usersSaved => res);
//                                });
//
//                            })
//                            .then(res => {
//                                return addSyncTrack(maxDate, SyncableItem.USER_CONNECTIONS).then(updated => {
//                                    return res;
//                                });
//                            })
//                            .then(res => {
//                                return Promise.resolve({
//                                    message: `Fetched Users ${JSON.stringify(res[0])} Connections ${JSON.stringify(
//                                        res[1])}`,
//                                    success: false,
//                                    records: connections.length
//                                });
//                            });
//         })
//         .catch(e => Promise.resolve({message: "Budget items update failed", success: false, records: 0}));
// }


async function doFetchBudgets(): Promise<DataUpdateStatus> {
    const totalItems = await database.budget.count();

    const lastSysnc = await database.syncTracker.where({"recordType": SyncableItem.BUDGET}).last();
    log.info("Initiating doFetchBudgets, Last sysnc ", lastSysnc, "count ", totalItems);
    //const user = await getLoggedInUser();
    let lastFetchTime: string;
    let maxDate: Date = new Date(2021, 7, 1, 0, 0, 0);

    if (lastSysnc && totalItems > 0) {
        lastFetchTime = lastSysnc.lastSyncTime;
        maxDate = parseISOUtc(lastFetchTime);
    } else if (totalItems > 0) {
        maxDate = new Date(2021, 7, 1, 0, 0, 0);
        lastFetchTime = formatRFC3339(maxDate);
    } else {
        maxDate = new Date(2018, 0, 1, 0, 0, 0);
        lastFetchTime = formatRFC3339(maxDate);
    }

    log.info(`Budget Fetching from ${lastFetchTime} Current TotalItems in database ${totalItems}`);
    return fetchBudgetsFromServer(lastFetchTime)
        .then(response => {
            if (!response.ok) {
                return Promise.reject({message: response.statusText, success: false, records: 0});
            }
            return response.json();
        })
        .then((resp: PaginatedData<SyncedBudget>) => resp.results)
        .then(serverbudgets => {
            const totalFetched = serverbudgets.length;

            const codes: Map<string, SyncedBudget> = new Map<string, SyncedBudget>(
                serverbudgets.filter(record => record.code)
                    .map(record => [record.code.trim(), record])
            );
            const updateRecords = database.budget.where("code").anyOf(Array.from(codes.keys()))
                .modify((record: Budget) => {
                    const serverRecord: SyncedBudget | undefined = codes.get(record.code);
                    if (serverRecord) {
                        const dateUpdated = parseISOUtc(serverRecord.date_updated);
                        const createdOn = parseISOUtc(serverRecord.date_created);
                        if (getUnixTime(dateUpdated) > getUnixTime(maxDate)) {
                            maxDate = dateUpdated;
                        }
                        record.serverId = serverRecord.id;
                        record.updatedOn = formatRFC3339(dateUpdated);
                        record.createdOn = formatRFC3339(createdOn);
                        codes.delete(record.code);
                    }
                });

            return updateRecords.then(updated => {
                let newBudgets: Budget[] = Array.from(codes.values()).map(budget => {
                    const dateUpdated = parseISOUtc(budget.date_updated);
                    if (getUnixTime(dateUpdated) > getUnixTime(maxDate)) {
                        maxDate = dateUpdated;
                    }
                    return {
                        code: budget.code,
                        serverId: budget.id,
                        name: budget.name,
                        updatedOn: budget.date_updated,
                        createdOn: budget.date_created,
                        members: budget.members
                    } as Budget;
                });
                return database.budget.bulkAdd(newBudgets, {allKeys: true});
            }).then(res => {
                return addSyncTrack(maxDate, SyncableItem.BUDGET);
            }).then(res => {
                let newRecordsCount = Array.from(codes.keys()).length;
                let message = `New Budget [${newRecordsCount}], Updated budget [${totalFetched - newRecordsCount}]`;
                log.info(message)
                return Promise.resolve({
                    message: message,
                    success: false,
                    records: newRecordsCount
                });
            })
        })
        .catch(e => Promise.resolve({message: "Budgets update failed", success: false, records: 0}));
}

async function doFetchCategories(): Promise<DataUpdateStatus> {
    const totalItems = await database.category.count();
    const lastSysnc = await database.syncTracker.where({"recordType": SyncableItem.CATEGORIES}).last();
    log.info("Initiating doFetchCategories, Last sysnc ", lastSysnc, `count ${totalItems}`);
    let lastFetchTime: string;
    let maxDate: Date;
    if (lastSysnc && totalItems > 0) {
        lastFetchTime = lastSysnc.lastSyncTime;
        maxDate = parseISOUtc(lastFetchTime);
    } else if (totalItems > 0) {
        maxDate = new Date(2021, 7, 1, 0, 0, 0);
        lastFetchTime = formatRFC3339(maxDate);
    } else {
        maxDate = new Date(2018, 0, 1, 0, 0, 0);
        lastFetchTime = formatRFC3339(maxDate);
    }

    log.info("Fetching from categries ", lastFetchTime, `count ${totalItems}`);
    return fetchCategories(lastFetchTime)
        .then(response => {
            if (!response.ok) {
                return Promise.reject({message: response.statusText, success: false, records: 0});
            }
            return response.json();
        })
        .then((data: PaginatedData<SyncedCategory>) => {
            if (data.results.length <= 0) {
                return Promise.reject("No items to update");
            }
            return Promise.resolve(data);
        })
        .then((data: PaginatedData<SyncedCategory>) => {
            log.info(`Fetched categries ${data.results.length}`);
            const codes: Map<string, SyncedCategory> = new Map<string, SyncedCategory>(
                data.results.filter(record => record.code).map(record => [record.code.trim(), record])
            );
            const updateRecords = database.category.where("code").anyOf(Array.from(codes.keys()))
                .modify((record: Category) => {
                    const serverRecord: SyncedCategory | undefined = codes.get(record.code);

                    if (serverRecord) {
                        const dateUpdated = parseISOUtc(serverRecord.date_updated);
                        const createdOn = parseISOUtc(serverRecord.date_created);
                        if (getUnixTime(dateUpdated) > getUnixTime(maxDate)) {
                            maxDate = dateUpdated;
                        }
                        record.serverId = serverRecord.id;
                        record.updatedOn = formatRFC3339(dateUpdated);
                        record.createdOn = formatRFC3339(createdOn);
                        codes.delete(record.code);
                    }
                });


            return updateRecords.then(updatedCount => {
                if (codes.size <= 0) {
                    return updatedCount;
                }
                log.info(`>>>>>>>Creating categories ${codes.size}`, codes);
                const newItems = Array.from(codes.values()).map(serverRecord => {
                    const dateUpdated = parseISOUtc(serverRecord.date_updated);
                    const createdOn = parseISOUtc(serverRecord.date_created);
                    if (getUnixTime(dateUpdated) > getUnixTime(maxDate)) {
                        maxDate = dateUpdated;
                    }
                    return {
                        serverId: serverRecord.id,
                        code: serverRecord.code,
                        updatedOn: formatRFC3339(dateUpdated),
                        createdOn: formatRFC3339(createdOn)
                    } as Category;
                });
                const newRecords = database.category.bulkAdd(newItems, {allKeys: true})
                    .then(ids => {
                        log.info(`>>>>>>>Created categories ${ids.length}`, codes);
                        return Promise.resolve(ids.length);
                    });
                return newRecords.then(count => {
                    return Promise.resolve(updatedCount + count);
                });
            });

        })
        .then(saved => {
            log.info(`Categories update are ${saved}`);
            return addSyncTrack(maxDate, SyncableItem.CATEGORIES).then(updated => {
                return saved;
            });
        })
        .then(saved => {
            return Promise.resolve({
                message: "Categories items updated :: " + saved,
                records: saved, success: true
            });
        })

        .catch(e => Promise.resolve({message: "Categories items not updated :: " + e, success: false, records: 0}));
}

async function updateSyncedBudgetItem(syncedBudgetItem: SyncedBudgetItem): Promise<BudgetItem> {
    const updateRecord = await database.budgetItem.where("code")
        .anyOf(syncedBudgetItem.client_code || syncedBudgetItem.code,
            syncedBudgetItem.code).first();

    let expenseDate = parseISOUtc(syncedBudgetItem.expense_date);
    let data: BudgetItem = {
        assignedTo: syncedBudgetItem.assigned_to,
        ownerCode: "",
        code: syncedBudgetItem.code,
        name: syncedBudgetItem.name,
        amount: syncedBudgetItem.price,
        paid: syncedBudgetItem.amount_paid,
        date: getUnixTime(expenseDate),
        serverId: `${syncedBudgetItem.id}`.length < 24 ? "" : `${syncedBudgetItem.id}`,
        itemTypeCode: syncedBudgetItem.item_type_code,
        tag: syncedBudgetItem.tag,
        budgetCode: syncedBudgetItem.budget,
        paymentStatusCode: syncedBudgetItem.status_code,
        isSynced: SYNC_DONE_STATUS,
        createdOn: syncedBudgetItem.date_created
    };

    const dateCreated = parseISOUtc(syncedBudgetItem.date_created);
    if (isBefore(dateCreated, new Date(2021, 4, 1))) {
        data.itemTypeId = 1;
        data.statusId = 1;
        data.paid = data.amount;
    }
    if (updateRecord) {
        const nowDate = formatRFC3339(new Date());
        const appDate = parseISOUtc(updateRecord.updatedOn ? updateRecord.updatedOn : nowDate);
        const serverDate = parseISOUtc(syncedBudgetItem.date_updated ? syncedBudgetItem.date_updated : nowDate);
        if (isBefore(serverDate, appDate)) {
            return updateRecord;
        }
        data.id = updateRecord.id;
    }


    data = prepareData(data);
    return database.budgetItem.put(data, data.id)
        .then(id => {
            data.id = id;
            return data;
        })
        .then(savedRecord => {
            return updateSyncedBudgetItemPayments(savedRecord, syncedBudgetItem)
                .then(ids => savedRecord);
        });
}

function updateSyncedBudgetItemPayments(data: BudgetItem, syncedBudgetItem: SyncedBudgetItem): Promise<number[]> {
    const updatePayments = database.budgetItemPayment
        .where("budgetItemCode")
        .equals(syncedBudgetItem.client_code || syncedBudgetItem.code)
        .delete()
        .then(count => {
            const payments = (syncedBudgetItem.payments || []).map(syncPayment => {
                return prepareData({
                    serverId: syncPayment.id,
                    budgetItemCode: data.code,
                    amount: syncPayment.amount,
                    paymentSourceCode: syncPayment.payment_source,
                    isSynced: SYNC_DONE_STATUS,
                    datePaid: getUnixTime(parseISOUtc(syncPayment.date_paid)),
                    paidByEmail: syncPayment.paid_by
                } as BudgetItemPayment);
            });
            return database.budgetItemPayment
                .bulkPut(payments, {allKeys: true})
                .then(paymentIds => {
                    return paymentIds;
                });
        });
    let updateSources = Promise.resolve(0);
    if (syncedBudgetItem.client_code) {
        updateSources = database.budgetItemPayment.where("paymentSourceCode")
            .equalsIgnoreCase(syncedBudgetItem.client_code)
            .modify({paymentSourceCode: syncedBudgetItem.code});
    }
    return Promise.all([updatePayments, updateSources])
        .then(res => {
            return res[0];
        });
}

function paginatedFetchBudgetItems(lastFetchTime: string, maxDate: Date, maxFetchedID?: string, totalPages?: number): Promise<DataUpdateStatus> {
    log.info(`Fetch After record [${maxFetchedID}] of TotalPages [${totalPages}]`)
    return fetchBudgetItems(lastFetchTime, maxFetchedID)
        .then(response => {
            if (!response.ok) {
                return Promise.reject({message: response.statusText, success: false, records: 0});
            }
            return response.json();
        })
        .then(async (paginatedData: PaginatedData<SyncedBudgetItem>) => {
            log.info(`Fetched ${paginatedData.results.length} Budget Items from server`);
            if (!paginatedData.results || paginatedData.results.length < 1) {
                return Promise.resolve({message: "Nothing to sysnc", success: true, records: 0});
            }
            const updatedItems = paginatedData.results.filter(it => !it.is_deleted)
                .map(async syncedBudgetItem => {
                    return updateSyncedBudgetItem(syncedBudgetItem);
                });
            const deletedItems = paginatedData.results.filter(it => it.is_deleted)
                .map(async syncedBudgetItem => {
                    return database.budgetItemPayment.where("budgetItemCode")
                        .equals(syncedBudgetItem.code)
                        .delete()
                        .then(deletedPayments => {
                            return database.budgetItem.where("code")
                                .equals(syncedBudgetItem.code)
                                .delete();
                        });
                });
            maxDate = paginatedData.results
                // .filter(it=>{
                //     log.info(`SyncBUD{code:${it.code},expense_date:${it.expense_date},date_updated:${it.date_updated}`)
                //     return it;
                // })
                .map(it => parseISOUtc(it.date_updated))
                .reduce((prev: Date, curr: Date) => {

                    // log.info(`##########PREv ${prev} and curr ${curr} isAfter ${isAfter(curr, prev)}`)
                    if (isAfter(curr, prev)) {
                        return curr;
                    }
                    return prev;
                }, maxDate);
            return Promise.all([updatedItems, deletedItems]).then(resp => {
                return addSyncTrack(maxDate, SyncableItem.BUDGET_ITEM)
                    .then(updated => {
                        return resp;
                    });
            })
                .then(resp => {
                    const saved = resp[0];
                    const deleted = resp[1];
                    return Promise.resolve({
                        message: `Budget items updated :: ${saved.length} Deleted :: ${deleted.length}`,
                        success: true,
                        records: saved.length
                    });
                })
                .then(data => {
                    const utcTime = formatInTimeZone(maxDate, 'UTC', "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
                    return paginatedFetchBudgetItems(utcTime, maxDate, paginatedData.page.maxRecordID);
                });

        })
        .catch(e => {
            if (e.name === Httpclient.ABORT_ERROR) {
                log.info(e);
            } else {
                log.error(e);
            }
            return Promise.resolve({message: "Budget items updated :: 0", success: false, records: 0});
        });
}

async function doFetchBudgetItems(): Promise<DataUpdateStatus> {
    const totalItems = await database.budgetItem.count();
    const lastSysnc = await database.syncTracker.where({"recordType": SyncableItem.BUDGET_ITEM}).last();
    log.info("Initiating doFetchBudgetItems, Last sysnc ", lastSysnc, "count ", totalItems);
    let lastFetchTime: string;
    let maxDate: Date;
    if (lastSysnc && totalItems > 0) {
        lastFetchTime = lastSysnc.lastSyncTime;
        maxDate = parseISOUtc(lastFetchTime);
    } else if (totalItems > 0) {
        maxDate = new Date(2021, 7, 1, 0, 0, 0);
        lastFetchTime = formatRFC3339(maxDate);
    } else {
        maxDate = new Date(2018, 0, 1, 0, 0, 0);
        lastFetchTime = formatRFC3339(maxDate);
    }
    log.info(`Budget items Fetching from ${lastFetchTime} Current TotalItems in database ${totalItems}`);
    return paginatedFetchBudgetItems(lastFetchTime, maxDate );

}

async function postBudgetToServer(budgets: Array<Budget>): Promise<Response> {
    log.info("Posting to budget server");

    return Httpclient.post(Httpclient.BUDGET_URL, {
        body: JSON.stringify(budgets.map(it => {
            return {code: it.code, name: it.name, id: it.serverId, members: it.members} as SyncedBudget;
        }))
    });
}

// async function postBudgetMemberToServer(budgets: Array<BudgetMember>): Promise<Response> {
//     log.info("Posting to BudgetMember server");
//
//     return Httpclient.post(Httpclient.BUDGET_MEMBER_URL, {
//         body: JSON.stringify(budgets.map(it => {
//             return {budgetCode: it.budgetCode, userEmail: it.userEmail, id: it.serverId} as SyncedBudgetMember;
//         }))
//     });
// }

function doSyncBudgets(): Promise<DataUpdateStatus> {
    return database.budget.filter(it => it.synced === SYNC_PENDING_STATUS)
        .toArray()
        .then(budgets => {
            if (budgets.length <= 0) {
                return Promise.reject({message: "No budgets to post", success: true, records: 0});
            }
            return postBudgetToServer(budgets);
        })
        .then(response => {
            if (!response.ok) {
                return Promise.reject({message: response.statusText, success: false, records: 0});
            }
            return response.json();
        })
        .then((response: PaginatedData<SyncedBudget>) => response.results)
        .then((data: SyncedBudget[]) => {
            return database.budget.where("code").anyOf(data.map(it => it.code)).delete()
                .then(cleared => {
                    return database.budget.bulkPut(data.map(it => {
                        return {
                            code: it.code,
                            name: it.name,
                            serverId: it.id,
                            members: it.members,
                            synced: SYNC_DONE_STATUS,
                            updatedOn: it.date_updated,
                            createdOn: it.date_created
                        } as Budget;
                    }), {allKeys: true});
                })
                .then(items => {
                    return Promise.resolve({
                        message: `Budget posted successfully ${items.length}`,
                        success: false, records: 0
                    });
                });
        });
}

// function doSyncBudgetMembers(): Promise<DataUpdateStatus> {
//     return database.budgetMember.where("synced").equals(SYNC_PENDING_STATUS).toArray()
//                    .then(budgets => {
//                        if (budgets.length <= 0) {
//                            return Promise.reject({message: "No budgetMember to post", success: true, records: 0});
//                        }
//                        return postBudgetMemberToServer(budgets);
//                    })
//                    .then(response => {
//                        if (!response.ok) {
//                            return Promise.reject({message: response.statusText, success: false, records: 0});
//                        }
//                        return response.json();
//                    })
//                    .then((data: SyncedBudgetMember[]) => {
//                        return database.budgetMember
//                                       .where(data.map(it => {
//                                           return {budgetCode: it.budgetCode, userEmail: it.userEmail};
//                                       }))
//                                       .delete()
//                                       .then(cleared => {
//                                           return database.budgetMember.bulkPut(data.map(it => {
//                                               return {
//                                                   userEmail: it.userEmail, budgetCode: it.budgetCode, serverId:
// it.id, updatedOn: it.date_updated, createdOn: it.date_created, synced: SYNC_DONE_STATUS } as BudgetMember; }),
// {allKeys: true}); }) .then(items => { return Promise.resolve({ message: `Budget posted successfully
// ${items.length}`, success: false, records: 0 }); }); }); }

// ****************** Do Data sysnc

function convertForBackend(items: BudgetItem[], id: number | undefined): SyncedBudgetItem[] {
    const clientCodes = items.map(it => it.code);
    return items
        .filter(it => {
            const unSavedSourceCodes = (it.payments || [])
                .filter(p => p.paymentSourceCode && clientCodes.includes(p.paymentSourceCode))
                .map(p => p.paymentSourceCode);
            return unSavedSourceCodes.length === 0;
        })
        .map(item => {
            const payments = item.payments?.map(it => {
                return {
                    id: `${it.serverId}`.length < 24 ? "" : `${it.serverId}`,
                    paid_by: it.paidByEmail,
                    payment_source: it.paymentSourceCode,
                    amount: it.amount,
                    date_paid: formatRFC3339(fromUnixTime(it.datePaid))
                } as SyncedBudgetItemPayment;
            });
            return {
                id: `${item.serverId}`.length < 24 ? "" : `${item.serverId}`,
                code: item.code ? item.code : uuidv4(),
                tag: item.tag,
                estimate: 0,
                price: item.amount,
                amount_paid: item.paid,
                budget: item.budgetCode,
                name: item.name,
                expense_date: formatRFC3339(fromUnixTime(item.date)),
                date_created: item.createdOn,
                item_type_code: item.itemTypeCode,
                status_code: item.paymentStatusCode,
                assigned_to: item.assignedTo,
                payments: payments
            } as SyncedBudgetItem;
        });
}

async function postToServer(items: Array<BudgetItem>): Promise<Response> {
    log.info(`Posting [${items.length}] BudgetItem server`);
    const user = await getLoggedInUser();

    return Httpclient.post(Httpclient.EXPENSES_URL, {
        body: JSON.stringify(convertForBackend(items, user?.id))
    });
}

async function deleteSyncedItems(items: Array<BudgetItem>): Promise<Response> {
    log.info(`Deleting [${items.length}] BudgetItem from server`);
    const user = await getLoggedInUser();

    return Httpclient.delete(Httpclient.EXPENSES_DELETE_URL, {
        body: JSON.stringify(convertForBackend(items, user?.id))
    });
}

function fetchForSyncBudgetItems() {
    const items = database.budgetItem.filter(it => !it.isSynced || it.isSynced === SYNC_PENDING_STATUS)
        .and(it => {
            return !it.deleted;
        })
        .limit(100)
        .toArray()
        .then(records => {
            if (records.length <= 0) {
                return Promise.reject({message: "No budgetitems to post", success: true, records: 0});
            }
            return Promise.resolve(records);
        });

    return addBudgetItemPayments(items);
}

export function doDeleteBudgetItems(): Promise<DataUpdateStatus> {
    return database.budgetItem.where("deleted").equals(IS_DELETED)
        .and(it => {
            return Boolean(it.serverId);
        })
        .toArray()
        .then(items => {
            if (items.length < 1) {
                return Promise.resolve({message: "", success: true, records: 0});
            }
            return deleteSyncedItems(items)
                .then(response => {
                    if (!response.ok) {
                        return Promise.reject({message: response.statusText, success: false, records: 0});
                    }
                    return response.json();
                })
                .then((data: SyncedBudgetItem[]) => {
                    const toDelete = data.map(it => it.code);
                    const deleteBudgetItem = database.budgetItem.where("code")
                        .anyOf(toDelete)
                        .delete();
                    const deletePayments = database.budgetItemPayment
                        .where("budgetItemCode")
                        .anyOf(toDelete)
                        .delete();
                    return Promise.all([deleteBudgetItem, deletePayments])
                        .then(cleared => {
                            return cleared[0];
                        });
                })
                .then(items => {
                    return Promise.resolve({
                        message: `Budget items deleted successfully ${items}`,
                        success: true, records: 0
                    });
                });
        });
}

export function doSyncBudgetItems(): Promise<DataUpdateStatus> {
    return fetchForSyncBudgetItems()
        .then(items => postToServer(items))
        .then(response => {
            if (!response.ok) {
                return Promise.reject({message: response.statusText, success: false, records: 0});
            }
            return response.json();
        })
        .then((data: PaginatedData<SyncedBudgetItem>) => data.results)
        .then((data: SyncedBudgetItem[]) => {

            if (data.length < 1) {
                return Promise.resolve({message: "No budgetitems to post", success: true, records: 0});
            }
            log.info(`Syncing:: ${data.length} synced`);
            const toDelete: Map<string, string> = new Map(data.filter(it => it.is_deleted)
                .map(item => [item.client_code || item.code, item.id]));
            const toUpdate: Array<SyncedBudgetItem> = data.filter(it => !it.is_deleted).map(item => item);
            return Promise.all(toUpdate.map(it => {
                return updateSyncedBudgetItem(it);
            }))
                // == delete
                .then(updatedItems => {
                    if (toDelete.size < 1) {
                        return {
                            updatedItems: updatedItems.length,
                            deletedItems: 0
                        };
                    }
                    return database.budgetItem.where("code")
                        .anyOf(Array.from(toDelete.keys()))
                        .delete()
                        .then(deletedItems => {
                            return {
                                updatedItems: updatedItems.length,
                                deletedItems: deletedItems
                            };
                        });

                })
                .then(items => {
                    return Promise.resolve({
                        message: `Budget items posted successfully ${items}`,
                        success: false, records: 0
                    });
                });


        })
        .catch(e => {
            log.error(e);
            return Promise.resolve({
                message: "Budget items update failed :: " + e.message,
                records: 0,
                success: false
            });
        });

}

export async function pageViewChanged(isOnline: boolean): Promise<DataUpdateStatus> {
    if (isOnline) {
        log.info("initiating background sync");
        const postBudgetItems = doSyncBudgetItems();
        const postBudgets = doSyncBudgets();
        // const postConnections = doSyncBudgetMembers();
        const budgetItemsPromise = doFetchBudgetItems();
        const categoriesPromise = doFetchCategories();
        // const connectionsPromise = doFetchUserConnections();
        const budgetPromise = doFetchBudgets();
        const deletedBudgetItems = doDeleteBudgetItems();

        const promises = Promise.all([postBudgets, postBudgetItems, budgetPromise,
            budgetItemsPromise, categoriesPromise
        ]);
        return deletedBudgetItems
            .then(deleted => {
                log.info(deleted);
                return promises;
            })
            .catch(reject => {
                return promises;
            })
            .then(response => {
                let records = response[1].records || response[2].records;
                let status = records > 0;
                return Promise.resolve({success: status, message: "", records: records});
            })
            .catch(e => {
                log.error(e);
                return Promise.resolve({success: true, message: "", records: 0});
            });
    }
    return Promise.resolve({success: true, message: "", records: 0});
}

