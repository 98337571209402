import {fetchBudgets} from "../db/service";
import React from "react";
import {Budget} from "../db/models";
import MatLinker from "../components/MatLinker";
import {ROUTES} from "../ROUTES";
import EditIcon from "@mui/icons-material/Edit";
import {DeleteButton} from "../components/DeleteButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {AppTable} from "./AppTable";
import {useAppSelector} from "../state/hooks";
import {BudgetShare} from "./BudgetShare";

export function BudgetList(props: {
    reload?: boolean, onItemChanged?: () => void,
    isSelected?: boolean,
    hideOptions?: boolean,
    selectable?: boolean,
    onItemChecked?: (item: Budget[]) => void,
}) {
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const doDelete = (data: Budget) => {
        return Promise.resolve();
    };

    return <AppTable {...props} dataLoader={() => fetchBudgets(user.email)} getValue={item => item.code}
                     rowOptions={(item: Budget) => [
                         <MatLinker to={`${ROUTES.EDIT_BUDGET_ITEM.replace(":id", item.id + "")}`}
                                    startIcon={<EditIcon fontSize="small"/>}>
                             Edit
                         </MatLinker>,
                         <BudgetShare data={item}/>,
                         <DeleteButton
                             ButtonProps={{
                                 size: "small",
                                 variant: "text",
                                 startIcon: <DeleteIcon/>
                             }}
                             deleteCallback={(e) => doDelete(item)}>
                             Delete
                         </DeleteButton>
                     ]}/>;
}