import {TextFieldProps} from "@mui/material/TextField/TextField";
import {
    AppBar, Avatar, Box, Button, ButtonProps, capitalize, Chip, DialogActions, DialogContent, FormControl, Grid,
    IconButton,
    InputAdornment, Snackbar, Stack, TextField, Toolbar, Typography, useTheme
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useFormik} from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import * as yup from "yup";
import {AppDialog} from "../budget/AppDialog";
import CloseIcon from "@mui/icons-material/Close";
import log from "loglevel";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {ALL} from "../db/models";

export interface DataPickerProps<T> {
    dataLoader: () => Promise<T[]>,
    dataSaver?: (data: string) => Promise<T | void>,
    getOptLabel?: (item: T) => string,
    getOptValue?: (item: T) => string,
    addNew?: boolean,
    pickerTitle?: string
    reset?: boolean,
    emptyOpt?: string,
    AddButtonProps?: ButtonProps
}

interface AddDataPickerItemProps<T> {
    dataSaver?: (data: string) => Promise<T | void>,
    name?: React.ReactNode,
    label?: React.ReactNode,
    onClose: (dataSaved: boolean) => void,
    ButtonProps?: ButtonProps
}

const newItemValidationSchema = yup.object({
    id: yup.string().required("Name is required")
});

function AddDataPickerItem<T>(props: AddDataPickerItemProps<T>) {
    const {
        onClose,
        label,
        name,
        dataSaver,
        ButtonProps,
        ...rest
    } = props;
    let defaultLabel = `Create a new ${name}`;
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    let btnProps = ButtonProps;
    if (!btnProps) {
        btnProps = {variant: "outlined"};
    }
    const [open, setOpen] = useState<boolean>(false);
    const doClose = (dataSaved: boolean) => {
        setOpen(false);
        onClose(dataSaved);
    };

    const createNewItem = (name: string) => {
        if (dataSaver) {
            return dataSaver(name).then(cat => {
                doClose(true);
            });
        }
        return Promise.resolve();
    };
    const formik = useFormik({
        initialValues: {
            id: ""
        },
        validationSchema: newItemValidationSchema,
        onSubmit: values => {
            createNewItem(values.id)
                .then(data => {
                    formik.resetForm();
                    setShowSnackBar(true);
                    doClose(true);
                })
                .catch(e => {
                    log.error("Failed to create item" + e);
                });
        }
    });
    const onOpen = (e: any) => {
        setOpen(true);
    };

    return <>
        <Button startIcon={<AddCircleIcon/>} size={"small"} onClick={onOpen} {...btnProps}>
            {label ?? defaultLabel}
        </Button>
        <AppDialog open={open} onClose={doClose}>
            <AppBar sx={{position: "relative"}}>
                <Toolbar>
                    {label ?? defaultLabel}
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={(e: any) => doClose(false)}
                        aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl component="fieldset" size={"small"} fullWidth>
                        <Stack direction={"row"}>
                            <TextField variant={"filled"} size={"small"}
                                       autoFocus
                                       fullWidth
                                       style={{marginTop: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                                       id="id"
                                       name="id"
                                       label={label ?? defaultLabel}
                                       type="text"
                                       value={formik.values.id}
                                       onChange={(e) => {
                                           formik.setFieldValue("id", e.target.value);
                                       }
                                       }
                                       error={formik.touched.id && Boolean(formik.errors.id)}
                                       helperText={formik.touched.id && formik.errors.id}/>
                            <Button type="submit" size={"small"} endIcon={<SaveIcon/>} variant={"contained"}
                                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}>
                                Save
                            </Button>
                        </Stack>
                    </FormControl>

                    <Snackbar
                        open={showSnackBar}
                        autoHideDuration={1000}
                        message="Category has been created"/>
                </form>
            </DialogContent>
        </AppDialog>
    </>;

}

export function DataPicker<T>(props: DataPickerProps<T> & TextFieldProps) {
    const {
        onClick,
        value,
        emptyOpt,
        dataLoader,
        getOptLabel,
        getOptValue,
        addNew,
        onChange,
        dataSaver,
        pickerTitle,
        AddButtonProps,
        reset,
        ...rest
    } = props;

    const theme = useTheme();
    let letter = "";
    const [open, setOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<React.ChangeEvent<HTMLInputElement>>();
    const [textValue, setTextValue] = useState<any>(value || "");
    const [selectedValue, setSelectedValue] = useState<any>(value || "");
    const [categories, setCategories] = useState<T[]>([]);
    const onTextClick = (e: any) => {
        if (onClick) {
            onClick(e);
        }
        setOpen(true);
    };

    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
        setTextValue(e.target.value);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onAddClose = (dataSaved: boolean) => {
        if (dataSaved) {
            loadData();
        }
    };

    const onItemPicked = () => {
        setTextValue(selectedValue);
        setOpen(false);
        if (onChange && event) {
            if (props.id) {
                event.target.id = props.id;
            }
            if (props.name) {
                event.target.name = props.name;
            }
            onChange(event);
        }
    };

    function getLabel(option: T) {
        if (getOptLabel) {
            return getOptLabel(option);
        }
        return `${option}`;
    }


    const loadData = () => {
        dataLoader().then(cats => {
            cats = cats.sort((a, b) => getLabel(a).localeCompare(getLabel(b)));
            setCategories(cats);
        });
    };

    const onItemSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue((event.target as HTMLInputElement).value);
        setEvent(event);
    };


    useEffect(() => {
        setTextValue(props.value || "");
    });

    useEffect(() => {
        loadData();
    }, []);


    function getValue(option: T) {
        if (getOptValue) {
            return getOptValue(option);
        }
        return `${option}`;
    }

    return <>
        <TextField {...rest} onClick={onTextClick} value={textValue} onChange={onTextChange}
                   InputProps={{
                       endAdornment: <InputAdornment position="end">
                           <IconButton
                               aria-label="show options"
                               onClick={onTextClick}
                               onMouseDown={onTextClick}
                               edge="end">
                               <ArrowDropDownIcon/>
                           </IconButton>
                       </InputAdornment>
                   }}/>
        <AppDialog open={open} onClose={onClose}>
            <AppBar sx={{position: "relative"}}>
                <Toolbar>
                    {pickerTitle ?? props.label}
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent dividers style={{padding: theme.spacing(1)}}>
                {addNew && (<Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <AddDataPickerItem onClose={onAddClose} name={props.label} dataSaver={props.dataSaver}
                                       ButtonProps={AddButtonProps}/>
                </Box>)}
                <RadioGroup value={selectedValue} onChange={onItemSelected} sx={{
                    minHeight: "20vh",
                    maxHeight:"60vh"
                }}
                            aria-label="options" name="options">

                    {Boolean(reset) && (<FormControlLabel
                        value={ALL}
                        control={<Radio/>}
                        label={ALL}/>)}
                    <Grid container>

                        {
                            categories.map((option: T, index: number) => {
                                const label = capitalize(getLabel(option));
                                const l = label.substring(0, 1);
                                let header = (<></>)
                                if (l !== letter) {
                                    letter = l;
                                    header = (<Grid container item>
                                        <Chip label={l}/>
                                    </Grid>);
                                }
                                return (
                                    <>
                                        {header}

                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value={getValue(option)}
                                                key={index}
                                                control={<Radio/>}
                                                label={label}/>
                                        </Grid>
                                    </>
                                );
                            })
                        }
                    </Grid>
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color={"secondary"} variant={"outlined"}>
                    Close
                </Button>
                <Button onClick={onItemPicked} color={"primary"} variant={"outlined"}>Pick</Button>
            </DialogActions>
        </AppDialog>
    </>;
}