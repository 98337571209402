import {
    AppBar,
    Box,
    ButtonGroup,
    DialogContent,
    IconButton,
    InputAdornment,
    Stack,
    TextField, ToggleButton, ToggleButtonGroup,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import { LazyLaunchDialog } from "./AppDialog";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AddBudgetItem } from "./item/AddBudgetItem";
import React, { useEffect, useState } from "react";
import { BudgetItem, User } from "../db/models";
import { BudgetItemFilter } from "../state/slices/budgetItemSlice";
import { useAppSelector } from "../state/hooks";
import log from "loglevel";
import { searchBudgetItem } from "../db/service";
import CloseIcon from "@mui/icons-material/Close";
import { PeriodFilter } from "./PeriodFilter";
import NotFound from "../components/NotFound";
import { BudgetItemRow } from "./item/BudgetItemRow";
import CategoryIcon from '@mui/icons-material/Category';
import ListIcon from '@mui/icons-material/List';

function SearchBudgetItem(props: {
    onClose?: (dateSaved: boolean) => void,
    categoryCode?: string,
    title?: string
}) {
    const { onClose, categoryCode } = props;
    const [budgets, setBudgets] = useState<BudgetItem[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    let filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    let loggedInUser: User = useAppSelector(state => state.sharedSliceReducer.user);

    const onTextClick = (e: any) => {
        setSearchTerm("");
    };
    useEffect(() => {
        log.info("Searching budget item %s", searchTerm);
        if (searchTerm.trim().length > 0) {
            searchBudgetItem(loggedInUser.email, searchTerm, filter, categoryCode)
                .then(items => {
                    log.info("Found items [%s]", items.length);
                    setBudgets(items);
                });
        } else {
            setBudgets([]);
        }
    }, [searchTerm]);

    const doClose = (dataSaved: boolean) => {

        if (onClose) {
            onClose(dataSaved);
        }
    };
    return <>
        <AppBar sx={{ position: "relative" }}>
            <Toolbar>
                <Typography variant={"subtitle2"}>
                    {`Search ${filter.budgetItemType}`}
                    {categoryCode && (<> in category <strong> {categoryCode}</strong></>)}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => doClose(false)}
                    aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Box mb={1}>
                <PeriodFilter hideDateFilter hideHeader />
            </Box>

            <Box mt={1} flexDirection={"row"} display={"flex"} justifyContent={"end"}>
                <TextField value={searchTerm}
                    id="search" size={"small"}
                    name="search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchTerm(e.target.value);
                    }
                    }
                    label={"Search"}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton sx={{ cursor: "pointer" }}
                                aria-label="show options"
                                onClick={onTextClick}
                                onMouseDown={onTextClick}
                                edge="end">
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }} />
            </Box>

            {budgets.map((it, idx) => {
                return <BudgetItemRow key={idx} budgetItem={it} position={idx} total={budgets.length} />;
            })}

            {budgets.length === 0 && (<NotFound>
                <Typography variant={"caption"}>
                    {searchTerm && (<>No Budget Item begining with <strong> {searchTerm}</strong> found </>)}
                    {!searchTerm && (`Search budget item`)}
                    {categoryCode && (<> in category <strong> {categoryCode}</strong></>)}
                </Typography>
            </NotFound>)}
        </DialogContent>
    </>;
}

export function TabContextToolbar(props: {
    onClose?: (dateSaved: boolean) => void,
    categoryCode?: string,
    enableViewTypeSelector?: boolean,
    onViewTypeChange?: (change: string) => void
}) {
    const theme = useTheme();
    const [viewType, setViewType] = useState<string>("list")

    const doClose = (status: boolean) => {
        if (props.onClose) {
            props.onClose(status);
        }
    };

    const onViewTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null) => {

        if (newAlignment) {
            setViewType(newAlignment);
            if (props.onViewTypeChange) {
                props.onViewTypeChange(newAlignment ?? "list");

            }
        }
    }
    return <Stack
        mt={theme.spacing(1)}
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={0.5}>
            
        {props.enableViewTypeSelector !== false &&
            (<ToggleButtonGroup value={viewType} onChange={onViewTypeChange} size={"small"} exclusive
                aria-label="outlined primary button group">
                <ToggleButton size="small" value="category">
                    <CategoryIcon fontSize="small" />
                </ToggleButton>

                <ToggleButton size="small" value="list">
                    <ListIcon fontSize="small" />
                </ToggleButton>
            </ToggleButtonGroup>)}

        <LazyLaunchDialog buttonTitle={"Search item"} buttonProps={{ variant: "outlined", startIcon: <SearchIcon /> }}
            content={closable => {
                return <SearchBudgetItem
                    onClose={status => {
                        doClose(status);
                        closable.onClose();
                    }
                    }
                    categoryCode={props.categoryCode} />;
            }
            } />
        <LazyLaunchDialog buttonTitle={"Add item"}
            buttonProps={{ variant: "outlined", startIcon: <AddCircleIcon /> }}
            content={closable => {
                return <AddBudgetItem onClose={status => {
                    doClose(status);
                    closable.onClose();
                }
                } categoryCode={props.categoryCode} />;
            }
            } />
    </Stack>;
}