import {configureStore} from "@reduxjs/toolkit";
import {sharedSliceReducer} from "./slices/sharedSlice";
import {budgetItemSliceReducer} from "./slices/budgetItemSlice";

export const store = configureStore({
    reducer: {
        budgetItemSliceReducer,
        sharedSliceReducer
    }
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch