import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ALL, BudgetItem} from "../../db/models";
import {endOfMonth, getUnixTime, startOfMonth} from "date-fns";

export interface BudgetItemFilter {
    startDate: number;
    endDate: number;
    reload: boolean;
    // category: string;
    budgetCode: string;
    user: string;
    budgetItemType: string;
}

// Define a type for the slice state
interface BudgetItemSlice {
    openDialog: boolean,
    editBudgetItem: BudgetItem | null
    filter: BudgetItemFilter
}

const now = new Date();
const defaultFilter = {
    startDate: getUnixTime(startOfMonth(now)),
    endDate: getUnixTime(endOfMonth(now)),
    reload: false,
    category: ALL,
    user: ALL,
    budgetCode: ALL,
    budgetItemType: "expense"
};

// Define the initial state using that type
const initialState = {
    openDialog: false,
    editBudgetItem: null,
    filter: defaultFilter
} as BudgetItemSlice;

export const budgetItemSlice = createSlice({
    name: "budgetItem",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        openAddDialog: (state) => {
            state.openDialog = true;
        },
        openEditDialog: (state, action: PayloadAction<BudgetItem>) => {
            state.openDialog = false;
            state.editBudgetItem = action.payload;
        },
        closeAddDialog: (state) => {
            state.openDialog = false;
            state.editBudgetItem = null;
        },
        doBudgetItemFilter: (state, action: PayloadAction<BudgetItemFilter>) => {
            state.filter = {...defaultFilter, ...action.payload};
        }
    }
});

export const {openAddDialog, closeAddDialog, openEditDialog, doBudgetItemFilter} = budgetItemSlice.actions;

export const budgetItemSliceReducer = budgetItemSlice.reducer;