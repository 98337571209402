import {
    AppBar, Avatar, Button, Divider, Drawer, Grid, IconButton, Link as MatLink, List, ListItemButton, ListItemIcon,
    ListItemText, Paper, Toolbar, Typography
} from "@mui/material";
import {Link as RouterLink, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, {useEffect} from "react";
import {loginUser, logoutUser, setOnlineStatus} from "../state/slices/sharedSlice";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {checkIsOnline, loadUserInfo, logout} from "../db/service";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import log from "loglevel";
import {BudgetItemFilter, doBudgetItemFilter} from "../state/slices/budgetItemSlice";
import {Httpclient} from "../api/httpclient";
import {ROUTES} from "../ROUTES";
import MenuIcon from "@mui/icons-material/Menu";
import {styled} from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {pageViewChanged} from "../api/Syncher";

const SideBarContentRoot = styled("div")(({theme}) => ({
    display: "flex",
    // justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
        justifyContent: "center"
    }
}));

function SideBarContent(props: { onMenuSelected?: () => void }) {
    const dispatch = useAppDispatch();
    const {user} = useAppSelector(state => state.sharedSliceReducer);

    function doClose() {
        if (props.onMenuSelected) {
            props.onMenuSelected();
        }
    }

    const onLogout = (e: any) => {
        dispatch(logoutUser());
        logout().then(v => {
            doClose();
        });
    };

    return <SideBarContentRoot>
        <Grid container sm={8} md={6} lg={4} xs={12} item={true} justifyContent={"flex-end"}>
            <Paper elevation={1}>
                {user && (
                    <Typography display={"block"} variant={"body2"} sx={{p: 2}}>
                        Hello, {user?.first_name}
                    </Typography>
                )}
                <List>
                    <MatLink to={ROUTES.BUDGET_ITEMs} onClick={doClose}
                             underline={"none"}
                             component={RouterLink}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Home"}/>
                        </ListItemButton>
                    </MatLink>
                    <MatLink to={ROUTES.USERS} onClick={doClose}
                             underline={"none"}
                             component={RouterLink}>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"My Connections"}/>
                        </ListItemButton>
                    </MatLink>
                    <MatLink to={ROUTES.ACCOUNT} onClick={doClose}
                             underline={"none"}
                             component={RouterLink}>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"My Account"}/>
                        </ListItemButton>
                    </MatLink>

                    <MatLink to={ROUTES.BUDGETs} onClick={doClose}
                             underline={"none"}
                             component={RouterLink}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AccountBalanceWalletIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"My Budgets"}/>
                        </ListItemButton>
                    </MatLink>
                    <MatLink to={ROUTES.CHAMA} onClick={doClose}
                             underline={"none"}
                             component={RouterLink}>
                        <ListItemButton>
                            <ListItemIcon>
                                <MonetizationOnIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"My Chama"}/>
                        </ListItemButton>
                    </MatLink>
                </List>
                <List>
                    <Divider/>
                    <ListItemButton onClick={onLogout}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Logout"}/>
                    </ListItemButton>
                </List>
            </Paper>
        </Grid>
    </SideBarContentRoot>;
}

function MainMenu(props: { onClose?: () => void }) {
    const {onClose} = props;
    const {authenticated} = useAppSelector(state => state.sharedSliceReducer);
    const [openSideBar, setOpenSideBar] = React.useState(false);
    const doClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const onMenuSelected = (open: boolean) => {
        setOpenSideBar(open);
    };
    if (!authenticated) {
        return <></>;
    }
    return <>
        <IconButton edge="start" color="inherit" aria-label="menu"
                    onClick={(e: any) => onMenuSelected(true)}>
            <MenuIcon/>
        </IconButton>
        <Drawer sx={{
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {boxSizing: "border-box"}
        }}
                anchor={"top"}
                open={openSideBar}
                onClose={doClose}
                PaperProps={{sx: {height: "inherit", backgroundColor: "transparent"}, elevation: 0}}>
            <SideBarContent onMenuSelected={() => onMenuSelected(false)}/>
        </Drawer>
    </>;
}

export function TopBar(props: {}) {
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    const dispatch = useAppDispatch();

    const {authenticated, user} = useAppSelector(state => state.sharedSliceReducer);
    const {isOnline} = useAppSelector(state => state.sharedSliceReducer);
    const [locationPathName, setLocationPathName] = React.useState<string>("");
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [anchorLeftEl, setAnchorLeftEl] = React.useState(null);
    const history = useHistory();
    const location = useLocation();
    let {path} = useRouteMatch();

    const initiateBackgroundSync = () => {
        checkIsOnline()
            .then(isOnline => {
                log.info("Server is reachable", isOnline);
                if (isOnline) {
                    // return Httpclient.doRefresh().then(hasToken => {
                    //     if (!hasToken) {
                            // onLogout();
                            // return Promise.reject("Could not refresh token");
                        // }
                        return Promise.resolve(true);
                    // });
                }
                return Promise.reject("Server cannot be reached");
            })
            .then(hasToken => {
                log.info("Has valid token", hasToken);
                if (hasToken) {
                    return pageViewChanged(hasToken);
                } else {
                    return Promise.reject("No valid token found");
                }
            })
            .then(updatestate => {
                if (updatestate.success) {
                    log.info("Background sync complete");
                    dispatch(doBudgetItemFilter({...filter, reload: true}));
                }
            }).catch(e => log.error(e));
    };

    useEffect(() => {
        if (locationPathName !== history.location.pathname &&
            history.location.pathname.includes(ROUTES.BUDGET_ITEMs) &&
            isOnline) {
            setLocationPathName(history.location.pathname);
            initiateBackgroundSync();
        }
    });

    useEffect(() => {
        if (isOnline) {
            initiateBackgroundSync();
        }
    }, [isOnline]);

    const handleClick = (event: any) => {
        // setAnchorEl(event.currentTarget);
    };

    function onLogout() {
        // dispatch(logoutUser());
        // logout().then(v => {
        //     log.info("LOGGED OUT");
        // });
    }

    function setStatus() {
        log.info("Internet state change, IsOnline => ", navigator.onLine);
        dispatch(setOnlineStatus(navigator.onLine));
    }

    useEffect(() => {
        setStatus();
        loadUserInfo().then(user => {
            if (user) {
                dispatch(loginUser(user));
                log.info("Logged in user ", user.name);
                let from = location.state ? (location.state as any).from.pathname || ROUTES.BUDGET_ITEMs : ROUTES.BUDGET_ITEMs;
                history.replace(from);
            }
        });
    }, []);

    useEffect(() => {
        window.addEventListener("online", () => setStatus());
        window.addEventListener("offline", () => setStatus());
        return () => {
            window.removeEventListener("online", () => setStatus());
            window.removeEventListener("offline", () => setStatus());
        };
    });

    return <> {user && (
        <AppBar position="static">
            <Toolbar>
                {authenticated && (<Button color="inherit" onClick={handleClick}>
                    {
                        user?.photo_url ? <Avatar src={user?.photo_url}/> : <AccountCircleIcon/>
                    }
                </Button>)
                }
                <Typography variant={"subtitle2"} flexGrow={1}>
                    <MatLink to={`${path}`} underline={"none"} component={RouterLink} color={"inherit"}>
                        Budget Tracker
                    </MatLink>
                </Typography>

                <span>
                    <Button size={"small"} color={"inherit"} startIcon={isOnline ? <WifiIcon/> : <WifiOffIcon/>}>
                         <Typography variant={"caption"}>
                             {process.env.REACT_APP_VERSION}
                         </Typography>
                    </Button>
                </span>
                <MainMenu/>
            </Toolbar>
        </AppBar>
    )}
    </>;
}