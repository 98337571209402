import {ALL, BasicStatistics, BudgetItem} from "../../db/models";
import React, {useEffect, useState} from "react";
import {getBudgetItemByCategory, isPaymentSource, sumBudgetItemPaidAmount} from "../../db/service";
import {Box, Paper, Typography, useTheme} from "@mui/material";
import capitalize from "@mui/material/utils/capitalize";
import {BudgetItemFilter} from "../../state/slices/budgetItemSlice";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {setLoading} from "../../state/slices/sharedSlice";
import {differenceInCalendarMonths, fromUnixTime} from "date-fns";
import {currency, getMonthName} from "../../Helpers";
import {StatSection} from "../basicstats/BasicStats";
import Stack from "@mui/material/Stack";
import NotFound from "../../components/NotFound";
import {BudgetItemRow} from "./BudgetItemRow";
import PaymentSourceUsageDialog from "./PaymentSourceUsageDialog";
import {BudgetItemRowActions} from "./BudgetItemRowActions";
import {BudgetItemsSelectedOptions} from "./BudgetItemsSelectedOptions";
import {BudgetItemsInfiniteLoader} from "./BudgetItemsInfiniteLoader";

const DEFAULT_BUDGETITEMS_PAGESIZE = 20;

type BudgetItemsListProps = {
    category?: string,
    showCarriedForward?: boolean,
    loadData?: boolean,
    onLoadingDone?: () => void
};

export function BudgetItemsList(props: BudgetItemsListProps) {
    const {category, showCarriedForward, loadData, onLoadingDone} = props;
    const [stat, setStat] = useState<BasicStatistics>({
        cfDebtPaid: 0,
        cfExpensePaid: 0,
        cfLendingPaid: 0,
        debtsClearedTotal: 0,
        expensesClearedTotal: 0,
        expensesTotal: 0,
        lendingsClearedTotal: 0,
        lendingsTotal: 0,
        debtsTotal: 0
    } as BasicStatistics);
    const theme = useTheme();
    const [seenHeaders, setSeenHeaders] = useState<string[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [dataList, setDataList] = useState<BudgetItem[]>([]);
    const [checkedItems, setCheckedItems] = useState<Map<number, BudgetItem>>(new Map<number, BudgetItem>());
    const [isLoadingInProgress, setIsLoadingInProgress] = useState<boolean>(false);
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const dispatch = useAppDispatch();


    const onItemChecked = (item: BudgetItem, isChecked: boolean) => {

        if (item && item.id) {

            if (isChecked) {
                if (!checkedItems.has(item.id)) {
                    checkedItems.set(item.id, item);
                }
            } else if (checkedItems.has(item.id)) {
                checkedItems.delete(item.id);
            }
        }
        setCheckedItems(new Map<number, BudgetItem>(checkedItems.entries()));
    };

    const reloadData = (dataSaved: boolean) => {
        if (dataSaved) {
            setCheckedItems(new Map<number, BudgetItem>());
            doReloadData();
        }
    };

    const doReloadData = () => {
        setDataList([]);
        setItemCount(0);
        loadMoreItems(0, DEFAULT_BUDGETITEMS_PAGESIZE)
            .then(()=>{
                if (onLoadingDone) {
                    onLoadingDone();
                }
            });
    };

    const loadMoreItems = (startIndex: number, stopIndex: number): Promise<void> => {
        let tmpItems = dataList;
        if(startIndex==0){
            tmpItems=[];
        }

        if (stopIndex > 0 && !isLoadingInProgress) {
            setIsLoadingInProgress(true);
            dispatch(setLoading(true))

            getBudgetItemByCategory(user.email, category ?? ALL, filter, startIndex, DEFAULT_BUDGETITEMS_PAGESIZE)
                .then(items => {
                    const duplicate: number[] = [];
                    const isMonthsView = differenceInCalendarMonths(fromUnixTime(filter.endDate),
                        fromUnixTime(filter.startDate)) > 1;
                    items.forEach((item, index, list) => {
                        if (duplicate.includes(item.id || -1)) {
                            delete list[index];
                        }
                        duplicate.push(item.id || -1);
                        if (isMonthsView) {
                            const name = getMonthName(item.date);
                            if (!seenHeaders.includes(name)) {
                                seenHeaders.push(name);
                                setSeenHeaders(seenHeaders);
                            }
                        }
                        stat.expensesTotal += item.amount;
                        stat.expensesClearedTotal += sumBudgetItemPaidAmount(item);
                    });
                    setStat({...stat});
                    items = items.sort((a, b) => a.date - b.date);
                    setItemCount(items.length);
                    setDataList([...tmpItems].concat(items));
                })
                .finally(() => {
                    setIsLoadingInProgress(false);
                    dispatch(setLoading(false));
                });
        }
        return Promise.resolve();
    };

    useEffect(() => {
        setDataList([]);
        loadMoreItems(0, DEFAULT_BUDGETITEMS_PAGESIZE);
    }, []);
    useEffect(() => {
        if (loadData) {
            doReloadData();
        }
    }, [loadData]);

    useEffect(() => {
        doReloadData();
    }, [filter]);


    return <>
        {category && (<StatSection BoxProps={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: theme.spacing(.5),
            marginBottom: theme.spacing(.5)
        }}>
            <>
                <Typography variant="caption" display={"block"}>
                    <strong>Total:</strong> {currency(stat.expensesTotal, false)}
                </Typography>
                <Typography variant="caption" display={"block"}>
                    <strong>Paid:</strong> {currency(stat.expensesClearedTotal, false)}
                </Typography>
                <Typography variant="caption" display={"block"}>
                    <strong>Debt:</strong> {currency(stat.expensesTotal - stat.expensesClearedTotal, false)}
                </Typography>
            </>
        </StatSection>)}
        {/* {showCarriedForward && (<CarriedForwardData/>)} */}
        {checkedItems.size > 0 && (<Paper elevation={3} sx={{
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(6)
        }}>
            <Stack direction="row">
                <Typography display={"block"} variant={"caption"} sx={{
                    paddingTop: theme.spacing(1),
                    paddingLeft: theme.spacing(1)
                }}>
                    Selected items <Typography variant={"caption"}>({checkedItems.size})</Typography>
                </Typography>
                <Box flexGrow={1}/>
                <BudgetItemsSelectedOptions data={Array.from(checkedItems.values())} onClose={reloadData}/>
            </Stack>
        </Paper>)}
        {dataList.length <= 0 && (<NotFound>
            <Typography variant={"caption"}>
                No <strong>{capitalize(filter.budgetItemType)}</strong>s in current period.
            </Typography>
        </NotFound>)}
        {dataList.length > 0 && (<>

            <Typography variant={"body2"} margin={theme.spacing(1)}>
                <strong>{category ? capitalize(category) : ""}</strong> {capitalize(filter.budgetItemType)}s in Current
                period
            </Typography>

            <Paper sx={{m: .5, p: .5}} elevation={3}>
                <BudgetItemsInfiniteLoader dataList={dataList}
                                           loadMoreItems={loadMoreItems}
                                           hasMoreItems={itemCount > 0}>

                    {(budgetItem: BudgetItem, index: number) => (<BudgetItemRow budgetItem={budgetItem}
                                                                                position={index}
                                                                                selectable
                                                                                categoryCode={category}
                                                                                isSelected={checkedItems.has(
                                                                                    budgetItem && budgetItem.id ? budgetItem.id : -1)}
                                                                                total={dataList.length}
                                                                                onItemChecked={onItemChecked}
                                                                                actions={budgetItem => {
                                                                                    return <Box>
                                                                                        {isPaymentSource(filter.budgetItemType) && (
                                                                                            <PaymentSourceUsageDialog
                                                                                                budgetItem={budgetItem}/>
                                                                                        )}
                                                                                        <BudgetItemRowActions
                                                                                            data={budgetItem}
                                                                                            onItemSelected={reloadData}
                                                                                            onClose={reloadData}
                                                                                            categoryCode={category}/>
                                                                                    </Box>;
                                                                                }
                                                                                }/>)}
                </BudgetItemsInfiniteLoader>
            </Paper>
        </>)
        }    </>;
}

