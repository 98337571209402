export const STORES = {
    YEAR: "year",
    MONTH: "month",
    BUDGET_ITEM: "budget_item"
};
export const BUDGETYPE = {
    DEBT: "debt",
    EXPENSE: "expense",
    LEND: "lending",
    FUNDS: "funds"
};
export const SyncableItem = {
    BUDGET: "BUDGET",
    BUDGET_ITEM: "BUDGET_ITEM",
    CATEGORIES: "CATEGORIES",
    USER_CONNECTIONS: "USER_CONNECTIONS"
};

export const SYNC_DONE_STATUS = 1;
export const SYNC_PENDING_STATUS = 0;

export const IS_DELETED = 1;
export const IS_NOT_DELETED = 0;

export const UNCATEGORIZED = "UNCATEGORIZED";
export const ALL = "all";

export interface CategoryStats {
    paid: number;
    amount: number;
    paymentStatusCode: string;
    itemsPendingSync: number;
    itemCount: number;
    name: string;
}

export interface Trackable {
    id?: number;
    updatedOn?: string;
    createdOn?: string;
    members?: string[];
}

export interface PaymentStatus {
    name: string,
    code: string,
    id: number
}

export interface BudgetItemType extends Trackable {
    name: string;
    code: string,
}

export interface DebtItem extends Trackable {
    budgetItemCode: string,
    debtCode: string
}


export interface SyncTracker extends Trackable {
    lastSyncTime: string;
    recordType: string;
}

export interface Category extends Trackable {
    name: string,
    code: string,
    serverId: number,
}

export interface UserConnection {
    id: number,
    mainUserEmail: string,
    userEmail: string,
    categoryCode: string
}

export interface YearData {
    yearName: string;
    stats: Budget[];
}

export interface BasicStatistics {
    cfDebtPaid: number;
    cfLendingPaid: number;
    cfExpensePaid: number;
    lendingsClearedTotal: number;
    debtsClearedTotal: number;
    debtsTotal: number;
    lendingsTotal: number;
    expensesTotal: number;
    expensesClearedTotal: number;
}

export interface BudgetItem extends Trackable {
    code: string;
    deleted?: number;
    date: number;
    name: string;
    assignedTo: string;
    amount: number;
    paymentStatusCode: string;
    itemTypeCode: string;
    ownerCode: string;
    paid: number;
    itemTypeId?: number;
    statusId?: number;
    budgetCode: string;
    ownerId?: number;
    dateObj?: Date;
    isSynced?: number;
    serverId?: string;
    tag?: string;
    payments?: BudgetItemPayment[];
}


export interface BudgetItemPayment extends Trackable {
    serverId?: string;
    budgetItemCode: string;
    amount: number;
    datePaid: number;
    paidByEmail: string;
    paymentSourceCode?: string;
    paymentSource?: BudgetItem;
    budgetItem?: BudgetItem;
    isSynced: number;
}

export interface Budget extends Trackable {
    name: string;
    code: string;
    synced: number;
    ownerEmail: string;
    serverId?: number;
}

export interface BudgetMember extends Trackable {
    userEmail: string;
    budgetCode: string;
    synced?: number;
    serverId?: number;
}


export interface Chama extends Trackable {
    name: string;
    code: string;
}

export interface ChamaContribution extends Trackable {
    chamaId: number;
    date: number,
    budgetItemId: number
}

export interface RequestLog {
    id?: number,
    error: string,
    init: string,
    date: string
}

export interface User extends Trackable {
    name: string,
    email: string,
    imageUrl: string,
    userCode?: string;
    provider: string;
    id?: number;
    first_name: string;
    last_name: string;
    photo_url: string;
    email_verified: boolean;
    refresh_token: string;
    access_token: string;
    access_token_exp_at?: number;
    // access_token_exp: number;
    // access_token_lifetime: number;
    is_logged_in: number;
}

export interface DataUpdateStatus {
    success: boolean;
    records: number;
    message: string;
}