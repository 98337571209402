import {BudgetItem} from "../../db/models";
import React from "react";
import {deleteBudgetItem} from "../../db/service";
import {Button, Divider, Menu} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {SyncBudgetItems} from "./SyncBudgetItems";
import {LazyLaunchDialog} from "../AppDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {CopyBudgetItemsToBudget} from "./CopyBudgetItemsToBudget";
import {DeleteButton} from "../../components/DeleteButton";
import DeleteIcon from "@mui/icons-material/Delete";

type BudgetItemsSelectedOptionsProps = { data: BudgetItem[], onClose?: (dataSaved: boolean) => void };

export function BudgetItemsSelectedOptions(props: BudgetItemsSelectedOptionsProps) {
    const {data, onClose} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const doDelete = (data: BudgetItem[]) => {
        return Promise.all(data.map(item => deleteBudgetItem(item)))
            .then(it => {
                handleClose(true);
            });
    };
    const handleClose = (saved: boolean) => {
        setAnchorEl(null);
        if (onClose) {
            onClose(saved);
        }
    };


    return <>
        <Button variant={"text"} onClick={handleClick}>
            <MoreVertIcon/>
        </Button>
        <Menu anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
              }}>
            <SyncBudgetItems data={data} onDone={handleClose}/>
            <Divider/>
            <LazyLaunchDialog buttonTitle={"Copy"}
                              buttonProps={{variant: "text", startIcon: <ContentCopyIcon/>}}
                              content={closable => {
                                  return <CopyBudgetItemsToBudget data={data} onDone={saved => {
                                      handleClose(saved);
                                      closable.onClose();
                                  }}/>;
                              }}/>
            <Divider/>
            <DeleteButton
                ButtonProps={{
                    size: "small",
                    variant: "text",
                    endIcon: <DeleteIcon/>
                }}
                deleteCallback={(e) => doDelete(data)}>
                Delete
            </DeleteButton>
        </Menu>
    </>;
}