import {BudgetItemFilter, doBudgetItemFilter} from "../state/slices/budgetItemSlice";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {
    differenceInDays, differenceInMonths, differenceInYears, endOfDay, endOfMonth, endOfYear, format, fromUnixTime,
    getUnixTime, isThisMonth, isThisYear, startOfDay, startOfMonth, startOfYear, subMonths, subYears
} from "date-fns";
import Stack from "@mui/material/Stack";
import {FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography, useTheme} from "@mui/material";
import {AdvancedBudgetItemFilter} from "./AdvancedBudgetItemFilter";
import {DISPLAY_DATE_FORMAT} from "../Helpers";
import {DataPicker} from "../components/DataPicker";
import {fetchBudgets, fetchUsers} from "../db/service";
import log from "loglevel";
import {ChartPage} from "./charts/ChartPage";
import {ALL, Budget} from "../db/models";
import {LazyLaunchDialog} from "./AppDialog";
import BarChartIcon from "@mui/icons-material/BarChart";


function WrapHeader(props: { children: ReactNode, block?: boolean }) {
    if (Boolean(props.block)) {
        return <div>
            {props.children}
        </div>;
    }
    return <>
        {props.children}
    </>;
}

export function PeriodHeader(props: { block?: boolean }) {
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);

    return <Typography display={"block"} variant={"caption"} textAlign={"center"}>
        <WrapHeader {...props}>
            <strong>Current Period </strong>
            {format(fromUnixTime(filter.startDate), DISPLAY_DATE_FORMAT)}
        </WrapHeader>
        <WrapHeader {...props}>
            <strong> To </strong>{format(fromUnixTime(filter.endDate), DISPLAY_DATE_FORMAT)}
        </WrapHeader>
    </Typography>;
}


export function PeriodFilter(props: { showChart?: boolean, hideHeader?: boolean, hideDateFilter?: boolean }) {
    const theme = useTheme();
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    // const [startDate, setStartDate] = useState<Date>(fromUnixTime(filter.startDate));
    // const [endDate, setEndDate] = useState<Date>(fromUnixTime(filter.endDate));
    // const [users, setUsers] = useState<User[]>([]);
    const [activePeriod, setActivePeriod] = useState<string>("1-m");
    const [activeUser, setActiveUser] = useState<string>(ALL);
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const [activeBudget, setActiveBidget] = useState<string>(filter.budgetCode);

    const dispatch = useAppDispatch();
    const handleClick = (e: any) => {
        let startDateTime: Date, endDateTime: Date;
        const now = new Date();
        const [interval, unit, range] = e.target.value.split("-");
        switch (unit) {
            case "d": {
                startDateTime = startOfDay(now);
                endDateTime = endOfDay(now);
                break;
            }
            case "m": {
                endDateTime = endOfMonth(now);
                startDateTime = startOfMonth(subMonths(endDateTime, interval));
                if (range !== "r") {
                    endDateTime = endOfMonth(startDateTime);
                }
                break;
            }
            case "y": {
                endDateTime = endOfYear(now);
                startDateTime = startOfYear(subYears(endDateTime, interval));
                if (range !== "r") {
                    endDateTime = endOfYear(startDateTime);
                }
                break;
            }
            default: {
                startDateTime = startOfMonth(now);
                endDateTime = endOfMonth(now);
                break;
            }
        }
        log.info(`Filter period by ${startDateTime} to ${endDateTime}`);

        const startDate = getUnixTime(startDateTime);
        const endDate = getUnixTime(endDateTime);
        dispatch(doBudgetItemFilter({...filter, startDate, endDate}));
    };


    const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
        setActiveUser(event.target.value as string);
        dispatch(doBudgetItemFilter({...filter, user: `${event.target.value}`.toLowerCase()}));
    };

    const onBudgetChange = (e: ChangeEvent<HTMLInputElement>) => {
        setActiveBidget(e.target.value);
        dispatch(doBudgetItemFilter({...filter, budgetCode: `${e.target.value}`.toLowerCase()}));
    };

    useEffect(() => {
        let period = "";
        const startDate = fromUnixTime(filter.startDate);
        const endDate = fromUnixTime(filter.endDate);
        const isCurrentYear = isThisYear(startDate);
        const isCurrentMonth = isThisMonth(startDate);
        const days = differenceInDays(endDate, startDate);
        const months = differenceInMonths(endDate, startDate);
        const years = differenceInYears(endDate, startDate);
        if (days <= 1) {
            period = "0-d";
        } else if ([1, 3, 6].includes(months)) {
            period = `${months}-m-r`;
        } else if ([1].includes(years)) {
            period = `${years}-y`;
        } else if (months > 6 && months <= 12) {
            period = `${isCurrentYear ? 0 : 1}-y`;
        } else {
            period = `${isCurrentMonth ? 0 : 1}-m`;
        }
        ;
        // setStartDate(startDate);
        // setEndDate(endDate);
        setActivePeriod(period);
    }, [filter]);
    return <>
        <Grid container item columnGap={.5} flexWrap={"nowrap"} marginTop={theme.spacing(1)}>
            <Grid item flexGrow={1}>
                <DataPicker dataLoader={() => fetchBudgets(user.email)} id={"budget"} name={"budget"}
                            onChange={onBudgetChange}
                            value={activeBudget}
                            label={"Filter by Budget"} reset
                            getOptValue={(opt: Budget) => opt.code}
                            getOptLabel={opt => opt.name}
                            size={"small"}/>
            </Grid>
            <Grid item>
                <DataPicker dataLoader={() => fetchUsers(true)} id={"payer"} name={"payer"}
                            onChange={handleUserChange} reset
                            value={activeUser}
                            label={"Filter by Payer"}
                            getOptValue={opt => opt.email}
                            getOptLabel={opt => opt.email}
                            size={"small"}/>
            </Grid>
        </Grid>
        {!props.hideDateFilter && (<Paper elevation={3}>
            <Stack direction="row" spacing={1} marginTop={theme.spacing(1)} justifyContent="space-between"
                   padding={theme.spacing(.2)}>
                <AdvancedBudgetItemFilter/>
                <FormControl size={"small"}>
                    <InputLabel id="period-label">Period</InputLabel>
                    <Select size={"small"}
                            labelId="period-label"
                            id="period"
                            value={activePeriod}
                            label="Period"
                            onChange={handleClick}>
                        <MenuItem value={"0-d"}>Today</MenuItem>
                        <MenuItem value={"0-m"}>This Month</MenuItem>
                        <MenuItem value={"1-m"}>Last Month</MenuItem>
                        <MenuItem value={"3-m-r"}>Last 3 Month</MenuItem>
                        <MenuItem value={"6-m-r"}>Last 6 Month</MenuItem>
                        <MenuItem value={"0-y"}>This Year</MenuItem>
                        <MenuItem value={"1-y"}>Last Year</MenuItem>
                    </Select>
                </FormControl>
                {Boolean(props.showChart) &&
                    <LazyLaunchDialog buttonTitle={"Charts"} buttonProps={{
                        variant: "outlined",
                        endIcon: <BarChartIcon/>
                    }} content={closable => {
                        return <ChartPage onClose={closable.onClose}/>;
                    }}/>}
            </Stack>
        </Paper>)}
        {!props.hideHeader && (<PeriodHeader/>)}
    </>;
}