import {Box, BoxProps, Paper, PaperProps, Typography} from "@mui/material";
import React, {ReactNode, ReactNodeArray, useEffect, useState} from "react";
import {BudgetItemFilter} from "../../state/slices/budgetItemSlice";
import {useAppSelector} from "../../state/hooks";
import {BasicStatistics} from "../../db/models";
import {getBasicStats} from "../../db/service";
import {currency} from "../../Helpers";
import {styled} from "@mui/material/styles";

const StatWrapper = styled(Paper)(({theme}) => ({
    display: "flex",
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    flexWrap: "wrap",
    gap: theme.spacing(1),
    flexDirection: "column"
}));

export function StatSection(props: {
    title?: string,
    children: ReactNodeArray | ReactNode,
    BoxProps?: BoxProps
    PaperProps?: PaperProps
}) {
    return <StatWrapper id="expense" {...props.PaperProps}>
        {props.title && (<Typography variant={"caption"}> <strong>{props.title}</strong></Typography>)}
        <Box {...props.BoxProps}>
            {props.children}
        </Box>
    </StatWrapper>;
}

export function BasicStats(props: {}) {
    const filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const [stats, setStats] = useState<BasicStatistics>({} as BasicStatistics);

    useEffect(() => {
        getBasicStats(user.email, filter).then(res => {
            setStats(res);
        });
    }, [filter]);

    return <StatWrapper sx={{m: .5, p: 0, gap: .1}} elevation={3}>
        <Box justifyContent={"space-between"} m={0} padding={0} gap={.1} flexDirection={"row"} display={"flex"}>
            <Box flexGrow={1}>
                <StatSection title={"Expenses"} PaperProps={{elevation: 1, square: true}}>
                    <Typography variant="caption" display={"block"}>
                        <span>Total:</span> {currency(stats.expensesTotal, false)}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                        <span>Paid:</span> {currency(stats.expensesClearedTotal, false)}
                    </Typography>
                    <Typography variant="caption" display={"block"} mr={1}>
                        <span>Debt:</span> {currency(stats.expensesTotal - stats.expensesClearedTotal, false)}
                    </Typography>
                </StatSection>
            </Box>
            <Box flexGrow={1}>
                <StatSection title={"Loan"} PaperProps={{elevation: 1, square: true}}>
                    <Typography variant="caption" display={"block"}>
                        <span>New:</span> {currency(stats.debtsTotal, false)}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                        <span>Repaid:</span> {currency(stats.debtsClearedTotal, false)}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                        <strong style={{visibility: "hidden"}}>Unpaid:</strong>
                    </Typography>

                </StatSection>
            </Box>
            <Box flexGrow={1}>
                <StatSection title={"Lends"} PaperProps={{elevation: 1, square: true}}>

                    <Typography variant="caption" display={"block"}>
                        <span>New:</span> {currency(stats.lendingsTotal, false)}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                        <span>Repaid:</span> {currency(stats.lendingsClearedTotal, false)}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                        <strong style={{visibility: "hidden"}}>Unpaid:</strong>
                    </Typography>

                </StatSection>
            </Box>
        </Box>
        <Box justifyContent={"end"} padding={0} gap={.2} flexDirection={"row"} display={"flex"}>
            <Box pr={1}>
                <Typography variant="caption" display={"block"}>
                    <strong>Total Spent:</strong> {currency(stats.expensesClearedTotal + stats.debtsClearedTotal,
                    false)}
                </Typography>
            </Box>
        </Box>
    </StatWrapper>;
}