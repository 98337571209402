import React, {useEffect, useState} from "react";
import {Paper, Typography} from "@mui/material";
import {ErrorView} from "./errorview/ErrorView";
import {doServerlogin, login} from "../db/service";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {loginUser} from "../state/slices/sharedSlice";
import {useHistory, useLocation} from "react-router-dom";
import log from "loglevel";
import {User} from "../db/models";
import Logo from "../logo.png";
import {styled} from "@mui/material/styles";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const Root = styled("div")(({theme}) => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    gap: theme.spacing(2),
    paddingTop: 30,
    justifyContent: "center",
    alignItems: "center"
}));

const ContentWrapper = styled("div")(({theme}) => (
        {
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            margin: theme.spacing(2),
            gap: theme.spacing(2),
            alignItems: "center",
            minWidth: "30vh",
            minHeight: "40vh"

        }
    )
);

export function Login() {
    const dispatch = useAppDispatch();
    const authenticated = useAppSelector(state => state.sharedSliceReducer.authenticated);
    const [error, setError] = useState("");
    let history = useHistory();
    let location = useLocation();
    ;


    function onSuccess(res: CredentialResponse) {
        if (!res.credential) {
            onFailure();
            return;
        }
        const decoded: { [key: string]: any } = jwt_decode(res.credential);
        // const user: User = {
        //     profile: {
        //         imageUrl: decoded.picture,
        //         name: decoded.name,
        //         email: decoded.email,
        //         familyName: decoded.family_name,
        //         givenName: decoded.given_name
        //     } as UserProfile,
        //     auth: {
        //         accessToken: response.credential,
        //         expiresAt: decoded.exp
        //     }
        // }
        const user: User = {
            access_token: res.credential ?? "",
            email: decoded.email,
            email_verified: true,
            first_name: decoded.given_name,
            id: 0,
            imageUrl: decoded.picture,
            is_logged_in: 0,
            last_name: decoded.family_name,
            name: decoded.name,
            photo_url: decoded.picture,
            provider: "google",
            refresh_token: ""

        };
        doServerlogin(user)
            .then(response => response.json())
            .then((user: User) => {
                if (user.access_token) {
                    return login(user);
                }
                return Promise.reject("Could not find access_token");
            })
            .then(user => {
                dispatch(loginUser(user));
            })
            .catch(reason => log.error(`Login Error:`, reason));
    }

    useEffect(() => {
        if (authenticated) {
            let from = (location.state as any).from.pathname || "/";
            history.replace(from);
        }
    }, [authenticated]);
    const onFailure = () => {
        log.error(`Login Error:`);
        setError("Login failed");
    };
    return (
        <Root>
            <Paper elevation={3}>
                <ContentWrapper>

                    <div>
                        <Typography variant={"subtitle2"} style={{marginTop: 24}}>
                            <img src={Logo} alt="logo" width={25} height={25}/> {process.env.REACT_APP_NAME}
                        </Typography>
                    </div>
                    <div style={{marginTop: 24, marginBottom: 24}}>
                        <Typography variant={"body2"}>
                            Hi, Welcome Back
                        </Typography>
                    </div>
                    <ErrorView error={error}/>
                    <GoogleLogin
                        onSuccess={onSuccess}
                        onError={onFailure}
                        useOneTap/>
                </ContentWrapper>
            </Paper>
        </Root>
    );
}