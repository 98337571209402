import {BudgetItem, BudgetItemPayment, SYNC_PENDING_STATUS} from "../db/models";
import {Box, Button, capitalize, Divider, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AppTable} from "../budget/AppTable";
import {fetchAllPaymentSources, getBudgetItemByType, postBudgetItem, postBudgetItemPayment} from "../db/service";
import {currency, DISPLAY_DATE_FORMAT} from "../Helpers";
import {format, fromUnixTime} from "date-fns";

export function AssociateToDebts(props: {
    onClose?: (dataSaved: boolean) => void,
    budgetType: string,
    payment: BudgetItemPayment,
    budgetItem: BudgetItem
}) {
    const {onClose, payment, budgetItem} = props;
    const [budgetType, setBudgetType] = useState<string>("none");
    const [reload, setReload] = useState<boolean>(false);
    const [checked, setChecked] = useState<string | undefined>(payment?.paymentSourceCode);

    const onItemChecked = (checked: BudgetItem[]) => {
        if (checked.length > 0) {
            setChecked(checked[0].code);
        } else {
            setChecked("");
        }
    };
    const doClose = (dataSaved: boolean) => {
        if (onClose) {
            onClose(dataSaved);
        }
    };
    const doAttachDebt = () => {
        payment.paymentSourceCode = checked;
        budgetItem.isSynced = SYNC_PENDING_STATUS;
        Promise.all([postBudgetItemPayment(payment), postBudgetItem(budgetItem)])
               .then(resp => {
                   doClose(true);
               });
    };

    useEffect(() => {
        setReload(props.budgetType !== budgetType);
        setBudgetType(props.budgetType);
    }, [props.budgetType]);
    return (
        <>
            <AppTable hideOptions
                      reload={reload}
                      onDataLoaded={() => setReload(false)}
                      dataLoader={() => getBudgetItemByType(budgetType)}
                      getChecked={() => fetchAllPaymentSources(payment.paymentSourceCode || "none")}
                      onItemChecked={onItemChecked}
                      selectable
                      headerRow={() => {
                          return <Grid container>
                              <Grid item xs={3}>
                                  <Typography variant={"caption"} display={"block"}>Item</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                  <Typography variant={"caption"} display={"block"}>Amount</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                  <Typography variant={"caption"} display={"block"}>Date</Typography>
                              </Grid>
                          </Grid>;
                      }}
                      dataRow={(budgetItem, index) => {
                          return <Grid container>
                              <Grid item xs={3}>
                                  <Typography variant={"caption"}>
                                      {capitalize(budgetItem?.name || "")}
                                  </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                  <Typography variant={"caption"}>
                                      {currency(budgetItem.amount, false)}
                                  </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                  <Typography variant={"caption"}>
                                      {format(fromUnixTime(budgetItem.date), DISPLAY_DATE_FORMAT)}
                                  </Typography>
                              </Grid>
                          </Grid>;
                      }}
                      getValue={(it: BudgetItem) => it.code}/>
            <Divider/>
            <Box display={"flex"} flexDirection={"row"} sx={{pt: 1, pr: 1}}>
                <Box flexGrow={1}/>
                <Button variant={"outlined"} color={"secondary"} sx={{mr: 2}}
                        onClick={() => doClose(false)}> Close </Button>
                <Button type="submit" color="primary" variant={"outlined"} onClick={doAttachDebt}>
                    Update
                </Button>
            </Box>
        </>
    );
}