import React, {useState} from "react";
import {getChamas} from "../db/service";
// import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {AppTable} from "../budget/AppTable";
import {useAppSelector} from "../state/hooks";
import {AddChama} from "./AddChama";

export function ChamasPage() {
    const {user} = useAppSelector(state => state.sharedSliceReducer);
    const [reloadData, setReloadData] = useState<boolean>(false);
    const onChamaAddClosed = (dataSaved: boolean) => {
        setReloadData(dataSaved);
    };
    return <>
        <AddChama title={"New Chama"} ButtonProps={{variant: "outlined"}} onClose={onChamaAddClosed}/>
        <AppTable reload={reloadData} dataLoader={() => getChamas(user.email)} getValue={it => it.code}/>
    </>;
}