import database from "../db/database";
import {User} from "../db/models";
import log from "loglevel";
import {saveUser} from "../db/service";
import {fromUnixTime, isAfter} from "date-fns";

import md5 from "crypto-js/md5";
import {readVBase64} from "../Helpers";

async function getLoggedInUser(): Promise<User | undefined> {
    return database.user.where("is_logged_in").equals(1).first();
}

function logToDb(url: any, e: any) {
    // database.requestLog.add({
    //     init: typeof url === "string" ? url : JSON.stringify(url),
    //     error: typeof e === "string" ? e : JSON.stringify(e),
    //     date: formatISO(new Date())
    // });
}

export class Httpclient {
    static SERVER_URL = `${process.env.REACT_APP_API_URI}`;
    static REFRESHTOKENURL = `${Httpclient.SERVER_URL}/auth/refresh`;
    static EXPENSES_DELETE_URL = `${Httpclient.SERVER_URL}/expenses`;
    static EXPENSES_URL = `${Httpclient.SERVER_URL}/expenses`;
    static TAGS_URL = `${Httpclient.SERVER_URL}/tags`;
    static USER_CONNECTIONS_URL = `${Httpclient.SERVER_URL}/connections`;
    static STATUS_URL = `${Httpclient.SERVER_URL}/status`;
    static LOGIN_URL = `${Httpclient.SERVER_URL}/auth/glogin`;
    static BUDGET_MEMBER_URL = `${Httpclient.SERVER_URL}/budgetmembers/`;
    static BUDGET_URL = `${Httpclient.SERVER_URL}/budget`;
    static ABORT_ERROR = "AbortError";
    private static instance: Httpclient;
    private controller: AbortController;
    private static requests: Array<string>;

    constructor() {
        this.controller = new AbortController();

    }

    static getInstance(): Httpclient {
        if (!Httpclient.instance) {
            Httpclient.instance = new Httpclient();
            Httpclient.requests = new Array<string>();
        }
        return Httpclient.instance;
    }

    static async getDefaultHeaders(init: RequestInit | undefined, addToken: boolean): Promise<ResponseInit> {
        const user = await getLoggedInUser();
        if (addToken && !Httpclient.isTokenValid(user)) {
            return Promise.reject("No valid token found");
        }
        let headers: HeadersInit = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        if (addToken) {
            headers = {"Authorization": `Bearer ${user?.access_token}`, ...headers};
        }
        let data = {};
        if (init) {
            data = {...init};
        }
        if (!init?.headers) {
            headers = {...init?.headers, ...headers};
        }
        return new Promise((resolve, reject) => {
            resolve({...data, headers: {...headers}});
        });
    }

    static get(input: RequestInfo, init?: RequestInit): Promise<Response> {
        return Httpclient.getDefaultHeaders(init, true)
                         .catch(e => log.error(e))
                         .then(defaultheaders => {
                             return Httpclient.getInstance().fetchRetryable(input, {method: "get", ...defaultheaders});
                         });
    }

    static post(input: RequestInfo, init?: RequestInit): Promise<Response> {
        log.trace("Do a Post request to server");
        return Httpclient.getDefaultHeaders(init, true)
                         .catch(e => log.error(e))
                         .then(defaultHeaders => {
                             return Httpclient.getInstance().fetchRetryable(input, {method: "post", ...defaultHeaders});
                         });
    }

    static delete(input: RequestInfo, init?: RequestInit): Promise<Response> {
        log.trace("Do a Post request to server");
        return Httpclient.getDefaultHeaders(init, true)
                         .catch(e => log.error(e))
                         .then(defaultHeaders => {
                             return Httpclient.getInstance()
                                              .fetchRetryable(input, {method: "delete", ...defaultHeaders});
                         });
    }

    static postUnAuthenticated(input: RequestInfo, init?: RequestInit): Promise<Response> {
        log.trace("Do a Post request to server");
        return Httpclient.getDefaultHeaders(init, false)
                         .catch(e => log.error(e))
                         .then(defaultHeaders => {
                             return Httpclient.getInstance().fetchRetryable(input, {method: "post", ...defaultHeaders});
                         });
    }

    static async checkServerStatus(): Promise<boolean> {
        const url = Httpclient.STATUS_URL;
        return fetch(
            url,
            {
                method: "get",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            }
        ).then(resp => {
            if (!resp.ok) {
                console.log("RESTA", {
                    status: resp.status,
                    statusText: resp.statusText,
                    body: resp.json()
                });
                logToDb(url, {
                    status: resp.status,
                    statusText: resp.statusText,
                    body: resp.json()
                });
            }
            return Promise.resolve(resp.ok);
        }, error => {
            logToDb(url, {
                body: error.message, statusText: error.statustext,
                status: error.status
            });
            return Promise.resolve(false);
        })
         .catch(reason => {
             logToDb(url, reason);
             return Promise.resolve(false);
         });
    }

    static isTokenValid(user: User | undefined): boolean {
        // if (!user || !user.refresh_token) {
        //     return false;
        // }
        if (user?.access_token_exp_at) {
            const expireTime = fromUnixTime(user.access_token_exp_at);
            if (isAfter(expireTime, new Date())) {
                return true;
            }
        }
        return false;

    }

    static async doRefresh(): Promise<boolean> {
        const user: User | undefined = await getLoggedInUser();

        if (!user || !user.refresh_token) {
            return Promise.resolve(false);
        }
        // Httpclient.getInstance().cancelAll();

        return fetch(this.REFRESHTOKENURL, {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({refresh_token: user?.refresh_token})
        })
            .then(response => response.json())
            .then((data: { access_token: string, access_token_lifetime: number, access_token_exp: number }) => {
                user.access_token = data.access_token;
                let readValue = readVBase64(data.access_token, "exp");
                user.access_token_exp_at = readValue ? parseInt(readValue) : 0;
                return saveUser(user)
                    .then(s => {
                        return true;
                    })
                    .catch(reason => {
                        log.error("Token refresh failed ::", reason);
                        return false;
                    });
            }).catch(reason => {
                logToDb(this.REFRESHTOKENURL, reason);
                return Promise.resolve(false);
            });
    }

    async fetchRetryable(input: RequestInfo, init?: RequestInit): Promise<Response> {
        const signal = this.controller.signal;
        let msg = `${JSON.stringify(input)}.${JSON.stringify(init?.method)}}`;
        const hash = md5(msg).toString();

        if (Httpclient.requests.includes(hash)) {
            return Promise.reject(`Request canceled, Request in progress [${init?.method?.toUpperCase()}] ${input}`);
        }
        Httpclient.requests.push(hash);
        return fetch(input, input.toString().includes("login") ? init : {...init, signal})
            .then(response => {
                if (response.status === 401) {
                    log.error("########################################### 401 ", response);
                    return Httpclient.doRefresh().then(isRefreshed => {
                        log.info(">>>>>>>>>>>>token refreshed", isRefreshed);
                        if (isRefreshed) {
                            // delay for a second
                            return new Promise(resolve => setTimeout(resolve, 1000))
                                .then(x => {
                                    return Httpclient.getDefaultHeaders(init, true)
                                                     .then(defaultHeaders => {
                                                         return Httpclient.getInstance().fetchRetryable(input,
                                                             {...init, ...defaultHeaders});
                                                     });
                                });
                        }
                        return response;
                    });
                }
                return response;
            }, e => {
                log.info(`Connecting to ${init} Failed because ${JSON.stringify(e)}`);
                throw e;
            }).catch(e => {
                logToDb(init, e);
                throw e;
            })
            .finally(() => {
                Httpclient.requests.splice(Httpclient.requests.indexOf(hash), 1);
            });
    }

    private cancelAll() {
        if (this.controller) {
            log.error("CANCEL ALL REQUESTS");
            this.controller.abort();
        }
    }
}