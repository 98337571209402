import React, {ChangeEvent, ReactNode, ReactNodeArray, useEffect, useState} from "react";
import {Divider, Grid, IconButton, Menu, MenuItem, Paper, PaperProps, Typography} from "@mui/material";
import log from "loglevel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import capitalize from "@mui/material/utils/capitalize";
import {styled} from "@mui/material/styles";
import NotFound from "../components/NotFound";
import {StyledCheckbox} from "./StyledComponents";

export function AppTableOptions<T>(props: {
    data: T,
    rowOptions?: (data: T) => ReactNodeArray
}) {
    const {rowOptions} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>, item: string) => {
        log.info(`Opening ${item}`);

    };
    const handleVertClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (item: string) => {
        setAnchorEl(null);

    };

    return <>
        <IconButton
            aria-controls="budget-item-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleVertClick}>
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id="budget-item-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "budget-item-button"
            }}>
            {rowOptions && rowOptions(props.data)?.map((item, index) => (<div key={index}>
                <MenuItem style={{minHeight: "inherit"}} onClick={e => handleClick(e, "associate")}>
                    {item}
                </MenuItem>
                <Divider sx={{my: 0.5}}/>
            </div>))}
        </Menu>
    </>;
}

export interface AppTableRowProps<T> {
    data: T,
    index: number,
    onItemChecked?: (item: T, isChecked: boolean) => void,
    isSelected?: boolean,
    hideOptions?: boolean,
    selectable?: boolean,
    selectedItems?: string[],
    multiSelectable?: boolean,
    getValue: (data: T) => string,
    rowOptions?: (data: T) => ReactNodeArray,
    dataRow?: (data: T, index: number) => ReactNode
}

export interface AppTableProps<T> {
    reload?: boolean,
    dataLoader: () => Promise<T[]>,
    getChecked?: () => Promise<T[]>,
    onDataLoaded?: () => void,
    onItemChecked?: (item: T[]) => void,
    isSelected?: boolean,
    hideOptions?: boolean,
    selectable?: boolean,
    getValue: (data: T) => string,
    multiSelectable?: boolean,
    rowOptions?: (data: T) => ReactNodeArray,
    PaperProps?: PaperProps,
    tableHeader?: ReactNode,
    dataRow?: (data: T, index: number) => ReactNode
    headerRow?: () => ReactNode
}

const Root = styled(Paper)(({theme}) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(1)
}));

export function AppTable<T>(props: AppTableProps<T>) {
    const {
        reload,
        onDataLoaded,
        onItemChecked,
        multiSelectable,
        dataLoader,
        getValue,
        getChecked,
        tableHeader,
        PaperProps,
        ...rest
    } = props;
    const [budgets, setBudgets] = useState<T[]>([]);
    const [checkedItems, setCheckedItems] = useState<Map<string, T>>(new Map<string, T>());
    const onItemCheckChange = (budget: T, isChecked: boolean) => {
        if (!multiSelectable) {
            checkedItems.clear();
        }
        if (isChecked) {
            if (!checkedItems.has(getValue(budget))) {
                checkedItems.set((getValue(budget)), budget);
            }
        } else {
            checkedItems.delete(getValue(budget));
        }
        setCheckedItems(new Map<string, T>(checkedItems.entries()));
        if (onItemChecked) {
            onItemChecked(Array.from(checkedItems.values()));
        }
    };
    useEffect(() => {
        doLoad();
        if (getChecked) {
            getChecked().then(items => {
                setCheckedItems(new Map<string, T>(items.map(it => {
                    return [getValue(it), it];
                })));
            });
        }
    }, []);
    useEffect(() => {
        if (reload) {
            doLoad();
        }
    }, [reload]);

    const doLoad = () => {
        dataLoader().then(data => {
            setBudgets(data);
            if (onDataLoaded) {
                onDataLoaded();
            }
        });
    };
    return <Root elevation={3} {...PaperProps}>

        {tableHeader && (<>
            {tableHeader}
            <Divider/>
        </>)}

        {budgets.length > 0 && props.headerRow && (
            <>
                <Grid container>
                    {props.selectable && (<Grid item>
                        <StyledCheckbox size={"small"} style={{"visibility": "hidden"}}/>
                    </Grid>)}
                    <Grid item flexGrow={1}>
                        {props.headerRow()}
                    </Grid>
                </Grid>
                <Divider/>
            </>
        )}
        {budgets.map((budget, index) => <div key={index}>
            <AppTableRow data={budget}
                         getValue={getValue}
                         index={index}
                         selectedItems={Array.from(checkedItems.keys())}
                         multiSelectable={multiSelectable}
                         onItemChecked={onItemCheckChange}
                         {...rest}/>
            {(index + 1) !== budgets.length && <Divider/>}
        </div>)}
        {budgets.length < 1 && (<NotFound>
            No data found
        </NotFound>)}
    </Root>;
}

export function AppTableRow<T>(props: AppTableRowProps<T>) {
    const {
        selectable,
        isSelected,
        data,
        onItemChecked,
        hideOptions,
        getValue,
        rowOptions,
        selectedItems,
        dataRow,
        index
    } = props;
    const [isChecked, setIsChecked] = useState<boolean>(isSelected || false);
    useEffect(() => {
        setIsChecked(Boolean(isSelected));
    }, [isSelected]);
    const onItemSelected = (event: ChangeEvent<HTMLInputElement>) => {
        if (onItemChecked) {
            onItemChecked(data, event.target.checked);
        }
        setIsChecked(event.target.checked);
    };
    return <Grid container>
        {selectable && (<Grid item>
            <StyledCheckbox size={"small"} checked={selectedItems?.includes(getValue(data))} onChange={onItemSelected}/>
        </Grid>)}

        {/*{selectable && !multiSelectable && (<Grid item>*/}
        {/*    <StyledRadiobox size={"small"} checked={selectedItems?.includes(getValue(data))} onChange={onItemSelected}/>*/}
        {/*</Grid>)}*/}
        <Grid item flexGrow={1}>
            {dataRow ? dataRow(data, index) : <Typography variant={"body2"}>{capitalize(getValue(data))}</Typography>}
        </Grid>
        {!hideOptions && (<Grid>
            <AppTableOptions data={data} rowOptions={rowOptions}/>
        </Grid>)}
    </Grid>;
}