import React, {useState} from "react";
import {Typography} from "@mui/material";
import {BudgetList} from "./BudgetList";
import {AddBudget} from "./AddBudget";

export function BudgetPage() {
    const [reload, setReload] = useState<boolean>(false);
    ;
    return <>
        <Typography variant={"h6"} marginLeft={"8px"}>
            My Budgets
        </Typography>
        <AddBudget onSave={() => setReload(true)}/>
        <BudgetList reload={reload} onItemChanged={() => setReload(false)}/>
    </>;
}