import React from "react";
import {Bar} from "react-chartjs-2";
import {Typography} from "@mui/material";


export type VerticalChartData = {
    paid: number[];
    amount: number[];
    labels: string[];
}
const VerticalBar = (props: { data: VerticalChartData, title: string }) => {
    const {data, title} = props;
    const rest = {
        borderWidth: 1
    };
    return <>
        <div className="header">
            <Typography variant={"body2"}>{title}</Typography>
        </div>
        <Bar
            width={300}
            height={500}
            data={{
                labels: data.labels,
                datasets: [
                    {
                        label: "Total",
                        data: data.amount,
                        backgroundColor: "rgb(75, 192, 192)",
                        ...rest
                    },
                    {
                        label: "Paid",
                        data: data.paid,
                        backgroundColor: "rgb(54, 162, 235)",
                        ...rest
                    }
                ]
            }}
            options={{
                indexAxis: "y"
            }}/>
    </>;
};

export default VerticalBar;