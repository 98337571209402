import {IconButton, Tooltip} from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import React from "react";

export function SyncPending(props: { synced: boolean }) {
    if (!props.synced) {
        return <></>;
    }
    return <Tooltip title="Sync pending">
        <IconButton aria-label="sync pending" size={"small"}>
            <SyncProblemIcon fontSize={"small"} color={"warning"}/>
        </IconButton>
    </Tooltip>;
}