import {BudgetItem} from "../../db/models";
import React from "react";
import {deleteBudgetItem} from "../../db/service";
import {Divider, Drawer, IconButton, List, ListItem, ListItemText} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import capitalize from "@mui/material/utils/capitalize";
import {ClosableProps, LazyLaunchDialog} from "../AppDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {AddBudgetItem} from "./AddBudgetItem";
import {PaymentsDialog} from "./PaymentsDialog";
import {DeleteButton} from "../../components/DeleteButton";
import DeleteIcon from "@mui/icons-material/Delete";

type BudgetItemRowActionsProps = {
    data: BudgetItem,
    onItemSelected?: (dataChanged: boolean) => void,
    categoryCode?: string,
    onClose?: (dataSaved: boolean) => void
};

export function BudgetItemRowActions(props: BudgetItemRowActionsProps) {
    const {categoryCode, data} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const handleVertClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const doClose = (dataSaved?: boolean) => {
        setAnchorEl(null);
        if (props.onItemSelected) {
            props.onItemSelected(dataSaved || false);
        }
        if (props.onClose) {
            props.onClose(Boolean(dataSaved));
        }
    };


    const doDelete = (data: BudgetItem) => {
        return deleteBudgetItem(data).then(v => {
            // history.
            doClose(true);
        });
    };


    return <>
        <IconButton
            aria-controls="budget-item-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleVertClick}>
            <MoreVertIcon/>
        </IconButton>
        <Drawer anchor={"bottom"} open={open}>
            <List>
                <ListItem
                    secondaryAction={<IconButton onClick={(e: any) => doClose(false)}>
                        <CloseIcon/>
                    </IconButton>}>
                    <ListItemText primary={`${capitalize(data.itemTypeCode)} options`}/>
                </ListItem>
                <Divider sx={{my: 0.5}}/>
                <ListItem>
                    <LazyLaunchDialog buttonTitle={`Edit Item`}
                                      buttonProps={{
                                          fullWidth: true,
                                          sx: {p: 0, justifyContent: "flex-start"},
                                          startIcon: <AddCircleIcon/>
                                      }}
                                      content={(closable: ClosableProps) => {
                                          return <AddBudgetItem budgetCode={props.data.code}
                                                                onClose={status => {
                                                                    doClose(status);
                                                                    closable.onClose();
                                                                }
                                                                }
                                                                categoryCode={categoryCode}/>;
                                      }
                                      }
                    />
                </ListItem>
                <Divider sx={{my: 0.5}}/>
                {props.data.amount > 0 && (
                    <>
                        <ListItem>
                            <PaymentsDialog data={props.data} onClose={doClose} repay
                                            ButtonProps={{
                                                fullWidth: true,
                                                sx: {p: 0, justifyContent: "flex-start"}
                                            }}/>
                        </ListItem>
                        <Divider sx={{my: 0.5}}/>
                    </>)}
                {props.data.amount > 0 && (
                    <>
                        <ListItem>
                            <PaymentsDialog data={props.data} onClose={doClose}
                                            ButtonProps={{
                                                fullWidth: true,
                                                sx: {p: 0, justifyContent: "flex-start"}
                                            }}/>
                        </ListItem>
                        <Divider sx={{my: 0.5}}/>
                    </>)}
                <ListItem>
                    <DeleteButton
                        ButtonProps={{
                            color: "warning",
                            fullWidth: true,
                            size: "small",
                            variant: "text",
                            sx: {p: 0, justifyContent: "flex-start"},
                            startIcon: <DeleteIcon fontSize="small"/>
                        }}
                        deleteCallback={(e) => doDelete(props.data)}>
                        Delete
                    </DeleteButton>
                </ListItem>
            </List>
        </Drawer>
    </>;
}