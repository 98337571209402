import {AppBar, Box, Button, DialogActions, DialogContent, Grid, IconButton, TextField, Toolbar} from "@mui/material";
import React, {useState} from "react";
import DatePicker from "@mui/lab/DatePicker";
import {BudgetItemFilter, doBudgetItemFilter} from "../state/slices/budgetItemSlice";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {
    endOfMonth, endOfYear, fromUnixTime, getUnixTime, startOfMonth, startOfYear, subMonths, subYears
} from "date-fns";
import FilterListIcon from "@mui/icons-material/FilterList";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {AppDialog} from "./AppDialog";
import CloseIcon from "@mui/icons-material/Close";

export function AdvancedBudgetItemFilter(props: {}) {
    const budgetFilter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    const dispatch = useAppDispatch();
    const defaultDate = fromUnixTime(budgetFilter.startDate);
    const endDate = fromUnixTime(budgetFilter.endDate);

    const [activeStartDate, setActiveStartDate] = useState(defaultDate);
    const [activeEndDate, setActiveEndDate] = useState<Date>(endDate);
    const [datesLinked, setDatesLinked] = useState<boolean>(true);

    const [openFilter, setOpenFilter] = useState<boolean>(false);

    function openAdvancedFilter() {
        setOpenFilter(true);
    }

    const onStartDateChange = (val: any) => {
        setActiveStartDate(val);
        if (datesLinked) {
            setActiveEndDate(endOfMonth(val as Date));
        }
    };
    const onEndDateChange = (val: any) => {
        setActiveEndDate(val);
    };

    const doFilter = () => {
        dispatch(doBudgetItemFilter({
            ...budgetFilter,
            startDate: getUnixTime(activeStartDate),
            endDate: getUnixTime(activeEndDate)
        } as BudgetItemFilter));
        doClose();
    };
    const doClose = () => {
        setOpenFilter(false);
    };


    return <>
        <Button variant="outlined" size={"small"} startIcon={<FilterListIcon/>} onClick={openAdvancedFilter}>
            Filters
        </Button>
        <AppDialog open={openFilter}>
            <AppBar sx={{position: "relative"}}>
                <Toolbar>
                    Advance Filter
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={doClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Stack direction="row" spacing={1}>*/}
                    {/*        <Chip label="3 months" clickable variant="outlined" onClick={handleClick}/>*/}
                    {/*        <Chip label="6 months" clickable onClick={handleClick}/>*/}
                    {/*        <Chip label="last year" clickable variant="outlined" onClick={handleClick}/>*/}
                    {/*    </Stack>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <DatePicker
                            label="Start Date"
                            inputFormat={"dd/MMM/yyyy"}
                            value={activeStartDate}
                            onChange={onStartDateChange}
                            renderInput={(params) => <TextField fullWidth {...params} helperText={null}/>}/>
                        <div>
                            <IconButton onClick={(e: any) => {
                                setDatesLinked(!datesLinked);
                            }
                            }>
                                {datesLinked ? <LinkIcon/> : <LinkOffIcon/>}
                            </IconButton>
                        </div>

                        <DatePicker
                            label="End Date"
                            inputFormat={"dd/MMM/yyyy"}
                            value={activeEndDate}
                            onChange={onEndDateChange}
                            renderInput={(params) => <TextField fullWidth {...params} helperText={null}/>}/>

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={doFilter}>
                    Filter
                </Button>
                <Button autoFocus onClick={doClose} variant="text" color="error">
                    Close
                </Button>
            </DialogActions>
        </AppDialog>
    </>;
}