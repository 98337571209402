import {BudgetItem, BudgetItemPayment, SYNC_PENDING_STATUS, User} from "../../db/models";
import {Button, ButtonProps, DialogActions, DialogContent, FormControl, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useFormik} from "formik";
import {postBudgetItem, postBudgetItemPayment} from "../../db/service";
import * as yup from "yup";
import {AppDialog} from "../AppDialog";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import PaymentIcon from "@mui/icons-material/Payment";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "@mui/lab/DatePicker";
import {fromUnixTime, getUnixTime} from "date-fns";
import {useAppSelector} from "../../state/hooks";

const addAmountValidationSchema = yup.object({
    amount: yup.string().required("amount is required")
});

export function PaymentForm(props: {
    onClose: (dataSaved: boolean) => void,
    data: BudgetItem,
    ButtonProps?: ButtonProps,
    repay?: boolean
}) {
    let loggedInUser: User = useAppSelector(state => state.sharedSliceReducer.user);
    const {onClose, ButtonProps, repay} = props;

    const [open, setOpen] = useState<boolean>(false);

    const doClose = (saved: boolean) => {
        setOpen(false);
        if (onClose) {
            onClose(true);
        }
        formik.resetForm();
    };
    const initdata: { amount: number, date: number, source: string } = {
        amount: 0,
        source: "n/a",
        date: getUnixTime(new Date())
    };

    const onOpen = (e: any) => {
        setOpen(true);
    };
    const formik = useFormik({
        initialValues: initdata,
        validationSchema: addAmountValidationSchema,
        onSubmit: values => {
            props.data.isSynced = SYNC_PENDING_STATUS;
            props.data.paid = 0;

            if (!repay) {
                props.data.amount = Number(props.data.amount) + Number(values.amount);
                postBudgetItem(props.data)
                    .then(res => {
                        formik.resetForm();
                        doClose(true);
                    });
            } else {
                Promise.all([
                    postBudgetItem(props.data),
                    postBudgetItemPayment(
                        {
                            paidByEmail: loggedInUser.email,
                            budgetItemCode: props.data.code,
                            amount: Number(values.amount),
                            datePaid: values.date,
                            isSynced: SYNC_PENDING_STATUS
                        } as BudgetItemPayment)])
                       .then(res => {
                           formik.resetForm();
                           doClose(true);
                       });
            }
        }
    });

    return <>
        <Button variant={"outlined"} size={"small"}
                startIcon={repay ? <PaymentIcon/> : <PlusOneIcon fontSize="small"/>} {...ButtonProps}
                onClick={onOpen}>
            {
                repay ? `Add a Payment` : `Add`
            }
        </Button>
        <AppDialog aria-labelledby="form-dialog-title" open={open}
                   onClose={(event, reason) => {
                       if (reason !== "backdropClick") {
                           doClose(false);
                       }
                   }}
                   maxWidth={"xs"}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <DialogContent>
                    <Typography variant={"body2"} sx={{mb: 1}}>
                        {repay ? "Add payment amount" : `Add amount ${props.data.itemTypeCode}`}
                    </Typography>
                    <FormControl component="fieldset" size={"small"} fullWidth>
                        <TextField variant={"outlined"} size={"small"}
                                   autoComplete={"off"}
                                   autoFocus fullWidth
                                   id="amount"
                                   name="amount"
                                   placeholder="Add Amount"
                                   label="Add Amount"
                                   value={formik.values.amount}
                                   onChange={formik.handleChange}
                                   error={formik.touched.amount && Boolean(formik.errors.amount)}
                                   helperText={formik.touched.amount && formik.errors.amount}/>
                    </FormControl>
                    {repay && (<>
                        <FormControl component="fieldset" size={"small"} fullWidth>
                            <DatePicker value={fromUnixTime(formik.values.date)}
                                        label={"Paid on"}
                                        inputFormat="dd/MMM/yyyy"
                                        renderInput={(params) => (
                                            <TextField fullWidth size={"small"} margin="dense" {...params} />)}
                                        onChange={(e) => {
                                            formik.setFieldValue("date", getUnixTime(e as Date));
                                        }}/>
                        </FormControl>
                    </>)}
                    {/*<FormControl component="fieldset" size={"small"} fullWidth>*/}
                    {/*    <PaymentSourcePicker variant={"outlined"} addNew={false}*/}
                    {/*                         getOptLabel={(user: User) => user.email}*/}
                    {/*                         getOptValue={(user: User) => user.email}*/}
                    {/*                         dataLoader={() => fetchUsers()}*/}
                    {/*                         margin="dense"*/}
                    {/*                         id="source"*/}
                    {/*                         fullWidth*/}
                    {/*                         name="source"*/}
                    {/*                         label={"Source of money"}*/}
                    {/*                         value={formik.values.source}*/}
                    {/*                         onChange={formik.handleChange}*/}
                    {/*                         error={formik.touched.source && Boolean(formik.errors.source)}*/}
                    {/*                         helperText={formik.touched.source && formik.errors.source}/>*/}
                    {/*</FormControl>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => doClose(false)} color="secondary">
                        Close
                    </Button>
                    <Button type="submit" sx={{mt: 1}} endIcon={repay ? <PaymentIcon/> : <SaveIcon/>}
                            variant={"contained"}>
                        {repay ? "Add a Payment" : `Add to ${props.data.itemTypeCode}`}
                    </Button>
                </DialogActions>
            </form>
        </AppDialog>
    </>;
}

