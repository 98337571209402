import {
    AppBar,
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    TextField,
    Toolbar
} from "@mui/material";
import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    addCategory,
    fetchBudgetItem,
    fetchBudgets,
    fetchUsers,
    getBudgetTypes,
    getCategories,
    postBudgetItem,
    postBudgetItemPayment,
    saveBudget,
    sumBudgetItemPaidAmount
} from "../../db/service";
import {
    ALL,
    BudgetItem,
    BudgetItemPayment,
    BudgetItemType,
    BUDGETYPE,
    Category,
    SYNC_PENDING_STATUS,
    User
} from "../../db/models";
import {useAppSelector} from "../../state/hooks";
import {EMPTY_OPT, getBudgetItem} from "../../Helpers";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {DataPicker} from "../../components/DataPicker";
import DatePicker from "@mui/lab/DatePicker";
import {fromUnixTime, getUnixTime} from "date-fns";
import {BudgetItemFilter} from "../../state/slices/budgetItemSlice";
import CloseIcon from "@mui/icons-material/Close";


const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    amount: yup.string().required("amount is required"),
    budgetCode: yup.string().required("budget is required")
});


export function AddBudgetItem(props: {
    onClose?: (dateSaved: boolean) => void,
    budgetCode?: string,
    categoryCode?: string,
}) {
    const {onClose, budgetCode, categoryCode} = props;
    let filter: BudgetItemFilter = useAppSelector(state => state.budgetItemSliceReducer.filter);
    let loggedInUser: User = useAppSelector(state => state.sharedSliceReducer.user);
    let item = getBudgetItem(loggedInUser);
    item.tag = categoryCode;
    item.assignedTo = loggedInUser?.email ?? "";
    if (filter.budgetItemType !== ALL) {
        item.itemTypeCode = filter.budgetItemType;
    }
    const [initData, setInitData] = useState<BudgetItem>(item);
    const [isCleared, setIsCleared] = useState<number>(1);
    // const [budgetItemTypes, setBudgetItemTypes] = useState<BudgetItemType[]>([]);
    useEffect(() => {
        if (budgetCode) {
            fetchBudgetItem(budgetCode).then(item => {
                if (item) {
                    setIsCleared(item.amount === sumBudgetItemPaidAmount(item) ? 1 : 0);
                    item.dateObj = fromUnixTime(item.date);
                    setInitData(item);
                }
            });
        } else {
            setInitData(item);
        }
        // getBudgetTypes().then(data => {
        //     setBudgetItemTypes(data);
        // });
    }, []);

    const doClose = (dataSaved: boolean) => {

        if (onClose) {
            onClose(dataSaved);
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initData,
        validationSchema: validationSchema,
        onSubmit: values => {
            if (values.tag === EMPTY_OPT) {
                values.tag = "";
            }
            const paid = Number(isCleared ? values.amount : values.paid);
            values.paid = 0;
            values.isSynced = SYNC_PENDING_STATUS;
            values.amount = Number(values.amount);

            const promises: any[] = [
                postBudgetItem(values)
            ];
            const paymentSum = initData.payments?.map(it => it.amount)
                .reduce((sum, current) => {
                    return Number(sum) + Number(current);
                }, 0);
            if (paid > 0 && (!paymentSum || paymentSum <= 0)) {
                promises.push(postBudgetItemPayment({
                    isSynced: SYNC_PENDING_STATUS,
                    paidByEmail: loggedInUser.email,
                    budgetItemCode: values.code,
                    amount: Number(paid),
                    datePaid: values.date
                } as BudgetItemPayment));
            }
            Promise.all(promises).then(res => {
                formik.resetForm();
                doClose(true);
            });
        }
    });

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        formik.setFieldValue("itemTypeCode", value);
    };
    return <>
        <AppBar sx={{position: "relative"}}>
            <Toolbar>
                {budgetCode ? "Edit" : "Add"} new item
                <Box sx={{flexGrow: 1}}/>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => doClose(false)}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
        <form onSubmit={formik.handleSubmit} autoComplete="false">

            <DialogContent>
                <Grid container spacing={.5}>
                    <Grid item xs={12}>
                        <DataPicker dataLoader={() => getBudgetTypes()}
                                    id={"itemtype"}
                                    variant={"outlined"}
                                    name={"itemtype"}
                                    getOptLabel={(cat: BudgetItemType) => cat.code}
                                    getOptValue={(cat: BudgetItemType) => cat.code}
                                    pickerTitle={"Pick a Type"}
                                    margin="dense"
                                    label={"Item type"}
                                    fullWidth
                                    value={formik.values.itemTypeCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.itemTypeCode && Boolean(formik.errors.itemTypeCode)}
                                    helperText={formik.touched.itemTypeCode && formik.errors.itemTypeCode}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant={"outlined"}
                                   autoFocus
                                   fullWidth
                                   autoComplete="false"
                                   margin="dense"
                                   id="name"
                                   name="name"
                                   label={formik.values.itemTypeCode === "expense" ? "Expense item name" : formik.values.itemTypeCode === "debt" ? "Borrowed from" : formik.values.itemTypeCode === "income" ? "Income amount" : "Lent to"}
                                   type="text"
                                   value={formik.values.name}
                                   onChange={formik.handleChange}
                                   error={formik.touched.name && Boolean(formik.errors.name)}
                                   helperText={formik.touched.name && formik.errors.name}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant={"outlined"}
                                   fullWidth
                                   margin="dense"
                                   id={`Amount`}
                                   name="amount"
                                   label={formik.values.itemTypeCode === "expense" ? `Expense amount` : formik.values.itemTypeCode === "debt" ? "Borrowed Amount" : "Lent Amount"}
                                   value={formik.values.amount}
                                   onChange={formik.handleChange}
                                   error={formik.touched.amount && Boolean(formik.errors.amount)}
                                   helperText={formik.touched.amount && formik.errors.amount}/>
                    </Grid>
                    {!budgetCode && formik.values.itemTypeCode !== BUDGETYPE.FUNDS && (<>
                        <Grid item xs={6}>
                            <FormControl component="fieldset" size={"small"}>
                                <FormLabel component="legend">
                                    Is fully {formik.values.itemTypeCode === "expense" ? "Paid" : "Repaid"}
                                </FormLabel>
                                <RadioGroup aria-label="full payment?" name="row-radio-buttons-group" row
                                            value={isCleared}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setIsCleared(Number((event.target as HTMLInputElement).value));
                                            }}>
                                    <FormControlLabel value={1} control={<Radio size={"small"}/>} label="Yes"/>
                                    <FormControlLabel value={0} control={<Radio size={"small"}/>} label="No"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant={"outlined"}
                                       disabled={isCleared === 1}
                                       margin="dense"
                                       id="paid" fullWidth
                                       name="paid"
                                       label={formik.values.itemTypeCode === "expense" ? "Amount paid" : "Repaid Amount"}
                                       value={formik.values.paid}
                                       onChange={formik.handleChange}
                                       error={formik.touched.paid && Boolean(formik.errors.paid)}
                                       helperText={formik.touched.paid && formik.errors.paid}/>

                        </Grid>
                    </>)}
                    {formik.values.itemTypeCode === BUDGETYPE.EXPENSE && (<Grid item xs={6}>
                        <DataPicker variant={"outlined"} addNew={true}
                                    dataLoader={() => getCategories()}
                                    dataSaver={addCategory}
                                    getOptLabel={(cat: Category) => cat.code}
                                    getOptValue={(cat: Category) => cat.code}
                                    pickerTitle={"Pick a category"}
                                    margin="dense"
                                    id="tag" fullWidth
                                    name="tag"
                                    label="Category"
                                    value={formik.values.tag}
                                    onChange={formik.handleChange}
                                    error={formik.touched.tag && Boolean(formik.errors.tag)}
                                    helperText={formik.touched.tag && formik.errors.tag}/>
                    </Grid>)}
                    <Grid item xs={6}>
                        <DataPicker variant={"outlined"} addNew={false}
                                    getOptLabel={(user: User) => user.email}
                                    getOptValue={(user: User) => user.email}
                                    dataLoader={() => fetchUsers()}
                                    margin="dense"
                                    id="assignedTo" fullWidth
                                    name="assignedTo"
                                    label={formik.values.itemTypeCode === "debt" ? "Borrowed by" : formik.values.itemTypeCode === "expense" ? "Payed by" : "Lent by"}
                                    value={formik.values.assignedTo}
                                    onChange={formik.handleChange}
                                    error={formik.touched.assignedTo && Boolean(formik.errors.assignedTo)}
                                    helperText={formik.touched.assignedTo && formik.errors.assignedTo}/>
                    </Grid>
                    <Grid item xs={6}>
                        <DataPicker dataLoader={() => fetchBudgets(loggedInUser.email)}
                                    dataSaver={(name => saveBudget(loggedInUser.email,
                                        {
                                            code: name,
                                            name: name,
                                            ownerEmail: loggedInUser.email,
                                            synced: 0
                                        }))}
                                    name={"budgetCode"} fullWidth
                                    id={"budgetCode"}
                                    value={formik.values.budgetCode || "home"}
                                    getOptValue={opt => opt.code}
                                    getOptLabel={opt => opt.code}
                                    label={"Budget"}
                                    margin={"dense"}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.budgetCode)}
                                    helperText={formik.errors.budgetCode}/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl component="fieldset" variant="outlined" fullWidth>
                            <DatePicker value={formik.values.dateObj}
                                        label={"Budget Month"}
                                        inputFormat="dd/MMM/yyyy"
                                        renderInput={(params) => (<TextField margin="dense" {...params} />)}
                                        onChange={(e) => {
                                            formik.setFieldValue("date", getUnixTime(e as Date));
                                            formik.setFieldValue("dateObj", e);
                                        }}/>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} color={"secondary"}
                        onClick={() => doClose(false)}> Close </Button>
                < Button type="submit" color="primary" variant={"outlined"}>
                    {initData.id ? "Update" : "Save"}
                </Button>
            </DialogActions>
        </form>
    </>;
}