import {BudgetItem, BudgetItemPayment, UNCATEGORIZED} from "../../db/models";
import React, {useEffect, useState} from "react";
import {fetchAllItemsPaidBySource} from "../../db/service";
import {format, fromUnixTime} from "date-fns";
import {currency, DISPLAY_DATE_FORMAT} from "../../Helpers";
import {AppBar, Box, capitalize, DialogContent, IconButton, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {AppTable} from "../AppTable";

export function PaymentSourceUsage(props: { budgetItem: BudgetItem, onClose?: (dataSaved: boolean) => void }) {
    const {onClose, budgetItem} = props;
    const [totalUsed, setTotalUsed] = useState<number>();
    const doClose = (datasaved: boolean) => {
        if (onClose) {
            onClose(datasaved);
        }
    };
    useEffect(() => {
        fetchAllItemsPaidBySource(budgetItem.code).then(itsm => {
            setTotalUsed(itsm.map(it => it.amount)
                             .reduce((previousValue, currentValue) => previousValue + currentValue, 0));
        });
    }, []);

    return <>
        <AppBar sx={{position: "relative"}}>
            <Toolbar>
                <strong>[ {capitalize(budgetItem.name)} ]</strong> Usage breakdown.
                <Box sx={{flexGrow: 1}}/>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => doClose(false)}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent sx={{p: .1}}>
            <Box sx={{display: "flex", flexDirection: "column", m: 1}}>
                <Typography variant={"body2"}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                flexGrow: 1,
                                mr: 1
                            }}>
                    <strong>Total Amount</strong>
                    {currency(budgetItem.amount, false)}
                </Typography>

                <Typography variant={"body2"}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                flexGrow: 1,
                                mr: 1
                            }}>
                    <strong>Total Used</strong>
                    {currency(totalUsed || 0, false)}
                </Typography>

                <Typography variant={"body2"}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                flexGrow: 1,
                                mr: 1
                            }}>
                    <strong>Balance</strong>
                    {currency(budgetItem.amount - (totalUsed || 0), false)}
                </Typography>
            </Box>
            <AppTable dataLoader={() => fetchAllItemsPaidBySource(budgetItem.code)}
                      hideOptions
                      getValue={(it: BudgetItemPayment) => `${it.id}`}
                      dataRow={((data: BudgetItemPayment, index: number) => {
                          return <Box>
                              <Box sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  justifyContent: "space-between",
                                  flex: "1 0 100%"
                              }}>

                                  <Box sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 1,
                                      justifyContent: "space-between",
                                      flex: "1 0 100%"
                                  }}>
                                      <Typography variant={"caption"}>
                                          {capitalize(data.budgetItem?.tag || UNCATEGORIZED)}
                                      </Typography>
                                      <Typography variant={"caption"}>
                                          {capitalize(data.budgetItem?.name || "")}
                                      </Typography>
                                  </Box>

                                  <Box sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 1,
                                      justifyContent: "space-between",
                                      flex: "1 0 100%"
                                  }}>
                                      <Typography variant={"caption"}>
                                          <small>{format(fromUnixTime(data.datePaid), DISPLAY_DATE_FORMAT)}</small>
                                      </Typography>
                                      <Typography variant={"caption"}>
                                          {currency(data.amount, false)}
                                      </Typography>
                                  </Box>
                              </Box>
                          </Box>;
                      })}/>
        </DialogContent>
    </>;
}