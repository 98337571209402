import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "../../db/models";
import log from "loglevel";

// Define a type for the slice state
interface SharedSlice {
    authenticated: boolean
    isOnline: boolean
    loading: boolean
    user: User,
    serviceWorkerInstalled: boolean,
    serviceWorkerUpdated: boolean,
    serviceWorkerData: ServiceWorkerRegistration | null
}

function getAnnonUser() {
    return {email: "annon", name: "Annonymous"} as User;
}

// Define the initial state using that type
const initialState = {
    authenticated: false,
    isOnline: false,
    loading: false,
    user: getAnnonUser(),
    serviceWorkerInstalled: false,
    serviceWorkerUpdated: false,
    serviceWorkerData: null
} as SharedSlice;

export const sharedSlice = createSlice({
    name: "shared",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        loginUser: (state, action: PayloadAction<User>) => {
            log.info("Dispatch user change")
            state.authenticated = true;
            state.user = action.payload;

        },
        serviceWorkerUpdate: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
            state.serviceWorkerUpdated = true;
            state.serviceWorkerInstalled = false;
            state.serviceWorkerData = action.payload;
        },
        serviceWorkerInstall: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
            state.serviceWorkerUpdated = false;
            state.serviceWorkerInstalled = true;
            state.serviceWorkerData = action.payload;
        },
        logoutUser: (state) => {
            state.authenticated = false;
            state.user = getAnnonUser();
        },
        setOnlineStatus: (state, action: PayloadAction<boolean>) => {
            state.isOnline = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const {
    loginUser,
    logoutUser,
    serviceWorkerUpdate,
    serviceWorkerInstall,
    setOnlineStatus,
    setLoading
} = sharedSlice.actions;


export const sharedSliceReducer = sharedSlice.reducer;