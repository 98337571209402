import React, {useState} from "react";
import {User} from "../db/models";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, TextField, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {fetchUsers, saveUser} from "../db/service";
import AddIcon from "@mui/icons-material/Add";
import {CommonProps} from "@mui/material/OverridableComponent";
import {useFormik} from "formik";
import * as yup from "yup";
import {AppTable} from "../budget/AppTable";

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("email is required")
});


function InviteUserForm(props: { invite: boolean, onClose: () => void, user?: User } & CommonProps) {
    // const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {invite, onClose, user} = props;

    let initdata = {name: "", email: ""} as User;
    if (user) {
        initdata = {...user};

    }
    const formik = useFormik({
        initialValues: initdata,
        validationSchema: validationSchema,
        onSubmit: values => {
            saveUser(values).then(user => onClose());
        }
    });
    return (
        <>
            <Dialog aria-labelledby="form-dialog-title" open={invite} onClose={onClose} fullWidth={fullScreen}
                    maxWidth={"xs"}>
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle id="form-dialog-title">Add Budget Item</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        {/*    Add budget item*/}
                        {/*</DialogContentText>*/}

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField variant={"outlined"}
                                           autoFocus
                                           style={{width: "100%"}}
                                           margin="normal"
                                           id="name"
                                           name="name"
                                           label="Name"
                                           value={formik.values.name}
                                           onChange={formik.handleChange}
                                           error={formik.touched.name && Boolean(formik.errors.name)}
                                           helperText={formik.touched.name && formik.errors.name}/>

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField variant={"outlined"}
                                           autoFocus
                                           margin="normal"
                                           id="email" style={{width: "100%"}}
                                           name="email"
                                           label="Email"
                                           value={formik.values.email}
                                           onChange={formik.handleChange}
                                           error={formik.touched.email && Boolean(formik.errors.email)}
                                           helperText={formik.touched.email && formik.errors.email}/>

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">
                            Close
                        </Button>
                        <Button type="submit" color="primary" variant={"outlined"}>
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );

}


export function UserHome() {
    const [invitUser, setInviteUser] = useState(false);
    const [reloadUsers, setReloadUsers] = useState(false);

    function onInviteFormClose() {
        setInviteUser(false);
        setReloadUsers(true);
    }

    function inviteUser() {
        setInviteUser(true);
    }

    return <>
        <Grid container>
            <Grid item flexGrow={1}>
                <Typography variant={"h6"}>
                    Connections
                </Typography>
            </Grid>
            <Grid>
                <Fab color="primary" aria-label="add" size={"small"} onClick={inviteUser}>
                    <AddIcon/>
                </Fab>
            </Grid>
        </Grid>
        <UserList reload={reloadUsers} onItemsChanged={() => setReloadUsers(false)}/>
        {invitUser ? <InviteUserForm invite={invitUser} onClose={onInviteFormClose}/> : <></>}
    </>;
}

export function UserList(props: {
    reload?: boolean, onItemsChanged?: () => void,
    getChecked?: () => Promise<User[]>,
    multiSelectable?: boolean,
    onItemChecked?: (item: User[]) => void,
    onItemSelected?: (data: User, isChecked: boolean) => void, selectable?: boolean
}) {
    return <AppTable dataLoader={fetchUsers} getValue={user => user.email} {...props}/>;
}