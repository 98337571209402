import {Redirect, Route, RouteProps} from "react-router-dom";
import React, {ReactNode} from "react";
import {useAppSelector} from "../state/hooks";
import log from "loglevel";

export interface PrivateRouteProps extends RouteProps {
    children: ReactNode;
}

export function PrivateRoute(props: PrivateRouteProps) {
    const {authenticated, user} = useAppSelector(state => state.sharedSliceReducer);
    const {children, ...rest} = props;
    log.info("is authenticated", authenticated, user);
    if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(reg => {
            log.info("serviceWorker", reg);
        });
    }
    return (
        <Route {...rest}
               render={({location}) =>
                   authenticated ? (children) : (<Redirect to={{pathname: "/login", state: {from: location}}}/>)
               }/>
    );
}