import React from "react";
import {BudgetItem} from "../../db/models";
import {LazyLaunchDialog} from "../AppDialog";
import {PaymentSourceUsage} from "./PaymentSourceUsage";
import {ButtonProps} from "@mui/material";

export default function PaymentSourceUsageDialog(props: { budgetItem: BudgetItem, title?: string, ButtonProps?: ButtonProps }) {
    const buttonProps = props.ButtonProps || {variant: "outlined"};
    return <LazyLaunchDialog buttonTitle={props.title || "Usage"}
                             buttonProps={buttonProps}
                             dialogProps={{
                                 open: false,
                                 sx: {p: 1}
                             }}
                             content={closable => <PaymentSourceUsage {...props} onClose={dataSaved => {
                                 closable.onClose();
                             }}/>}/>;
}