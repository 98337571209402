import {ReactNode} from "react";
import {CommonProps} from "@mui/material/OverridableComponent";
import {Box} from "@mui/material";

type NotFoundProps = {
    message?: string
    children?: ReactNode
}
const NotFound = (props: NotFoundProps & CommonProps) => {
    const {message, children, ...rest} = props;
    return (
        <Box sx={{p: 2, m: 2}} {...rest}>
            {message}
            {children}
        </Box>
    );
};

export default NotFound;