import {format, fromUnixTime, getUnixTime} from "date-fns";
import {BudgetItem, User} from "./db/models";

export const SW_INIT = "SW_INIT";
export const SW_UPDATE = "SW_UPDATE";

export const DISPLAY_DATE_TIME_FORMAT = "dd-MMM-yyyy hh:mm a";
export const DISPLAY_DATE_FORMAT = "dd-MMM-yyyy";
export const DISPLAY_MONTH_YEAR_FORMAT = "MMMM,yyyy";
export const DISPLAY_MONTH_YEAR_FORMAT_SHORT = "MMM,yyyy";

export function groupBy<T>(list: T[], keyGetter: (item: T) => string): Map<string, T[]> {
    const map = new Map();
    list.forEach((item: T) => {
        if (item) {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        }
    });
    return map;
}


export function getMonthName(date: Date | number) {
    if (typeof date === "number") {
        date = fromUnixTime(date);
    }
    return format(date, "MMM");
}

export function currency(value: number, currencyCode: boolean) {
    let formater = new Intl.NumberFormat();
    if (currencyCode) {
        formater = new Intl.NumberFormat("en-KE", {style: "currency", currency: "KES"});
    }
    return formater.format(value);
}

export function firstLetterUppercase(data: string) {
    return `${data.charAt(0).toUpperCase()}${data.substr(1)}`;
}

export const EMPTY_OPT = "----------------";

export function getBudgetItem(user: User | undefined): BudgetItem {
    return {
        name: "",
        date: getUnixTime(new Date()),
        dateObj: new Date(),
        amount: 0,
        paid: 0,
        itemTypeCode: "expense",
        isSynced: 0,
        ownerId: user?.id,
        tag: ""
    } as BudgetItem;
}

export function readVBase64(base64: string, key: string): string | null {
    if (base64) {
        const decodedString = atob(base64.split(".")[1]);
        const map = new Map(Object.entries(JSON.parse(decodedString)));
        return map.get(key) + "";
    }
    return null;
}