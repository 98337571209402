import {BudgetItem} from "../../db/models";
import React, {useEffect, useState} from "react";
import {fetchBudgetItem} from "../../db/service";
import {
    AppBar, Box, Button, ButtonProps, capitalize, Card, CardContent, DialogActions, DialogContent, IconButton, Stack,
    Toolbar
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import {AppDialog} from "../AppDialog";
import CloseIcon from "@mui/icons-material/Close";
import {BudgetItemRow} from "./BudgetItemRow";
import {PaymentsTable} from "./PaymentsTable";
import {PaymentForm} from "./PaymentForm";

export function PaymentsDialog(props: {
    onClose?: (dataSaved: boolean) => void,
    data: BudgetItem,
    openDialog?: boolean,
    repay?: boolean,
    ButtonProps?: ButtonProps,
}) {
    const {onClose, ButtonProps, data, repay, openDialog} = props;

    const [reloadPayments, setReloadPayments] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(openDialog !== undefined);
    const [budgetItem, setBudgetItem] = useState<BudgetItem>(data);

    useEffect(() => {
        if (reloadPayments) {
            fetchBudgetItem(data.code).then(it => {
                if (it) {
                    setBudgetItem(it);
                }
            });
        }
    }, [reloadPayments]);

    const onOpen = (e: any) => {
        setOpen(true);
    };
    const onAmountAdded = (dataSaved: boolean) => {
        if (dataSaved && repay) {
            setReloadPayments(true);
        }
        setReloadPayments(dataSaved);
    };
    const doClose = (dataSaved: boolean) => {
        setOpen(false);
        if (onClose) {
            onClose(true);
        }
    };

    return <>
        {openDialog === undefined && <Button variant={"text"} size={"small"}
                                             startIcon={repay ? <PaymentIcon/> :
                                                 <PlusOneIcon fontSize="small"/>} {...ButtonProps}
                                             onClick={onOpen}>
            {
                repay ? `Add a Payment` : `Adjust price`
            }
        </Button>}
        <AppDialog aria-labelledby="form-dialog-title" open={open}
                   onClose={(event, reason) => {
                       if (reason !== "backdropClick") {
                           doClose(false);
                       }
                   }}
                   maxWidth={"xs"}>
            <AppBar sx={{position: "relative"}}>
                <Toolbar>
                    {repay ? `Pay ${capitalize(data.itemTypeCode)}` : `Add/Deduct ${capitalize(data.itemTypeCode)}`}
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => doClose(false)}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <DialogContent dividers sx={{p: 1}}>
                <Card sx={{mt: 1, mb: 1}}>
                    <CardContent>
                        <BudgetItemRow budgetItem={budgetItem} position={0} total={0}/>
                    </CardContent>
                </Card>
                <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
                    <PaymentForm onClose={onAmountAdded} data={data} repay={repay}/>
                </Stack>

                {repay && (<PaymentsTable
                    budgetItem={props.data}
                    onClose={onAmountAdded}
                    reload={reloadPayments}/>)}

            </DialogContent>
            <DialogActions>
                <Button onClick={() => doClose(false)} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </AppDialog>
    </>;
}