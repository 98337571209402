import * as yup from "yup";
import {Budget, User} from "../db/models";
import {useAppSelector} from "../state/hooks";
import React, {useState} from "react";
import {useFormik} from "formik";
import {saveBudget} from "../db/service";
import {Button, Dialog, DialogActions, DialogContent, FormControl, FormLabel, Stack, TextField} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import {styled} from "@mui/material/styles";

const validationSchema = yup.object({
    name: yup.string().required("name is required")
});
const AddBudgetRoot = styled("div")(({theme}) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));

export function AddBudget(props: { onSave?: () => void }) {
    let loggedInUser: User = useAppSelector(state => state.sharedSliceReducer.user);
    const initData: Budget = {
        name: "",
        ownerEmail: "",
        synced: 0,
        code: ""
    };
    const [open, setOpen] = useState<boolean>(false);
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: initData,
        validationSchema: validationSchema,
        onSubmit: values => {
            values.synced = 0;
            saveBudget(loggedInUser.email, values).then(res => {
                formik.resetForm();
                goHome();
            });
        }
    });
    const onOpen = () => {
        setOpen(true);
    };
    const doClose = () => {
        setOpen(false);
        goHome();
    };
    const goHome = () => {
        if (props.onSave) {
            props.onSave();
        }
    };

    return <AddBudgetRoot>
        <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "row"}}>
            <Button endIcon={<AddCircleIcon/>} variant={"outlined"} onClick={onOpen}>
                add
            </Button>
        </div>
        <Dialog open={open} onClose={doClose}>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl component="fieldset" size={"small"}>
                        <FormLabel component="legend">add new Budget</FormLabel>
                        <Stack direction={"row"}>
                            <TextField variant={"filled"} size={"small"}
                                       autoFocus fullWidth
                                       style={{marginTop: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                                       id="name"
                                       name="name"
                                       label="Name"
                                       value={formik.values.name}
                                       onChange={formik.handleChange}
                                       error={formik.touched.name && Boolean(formik.errors.name)}
                                       helperText={formik.touched.name && formik.errors.name}/>

                            <Button type="submit" size={"small"} endIcon={<SaveIcon/>} variant={"contained"}
                                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}>
                                Save
                            </Button>
                        </Stack>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={doClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </AddBudgetRoot>;
}